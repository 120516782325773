import React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import Select from 'react-select';
import Button from "@mui/material/Button";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";

// ICONS
import CustomDate from "../../general-components/CustomDate.component";
import Accordion from "../../general-components/AccodionTwo";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomNumber from "../../general-components/CustomNumber.component";
import CustomUpload from "../../general-components/CustomUpload.component";
import CustomImageUpload from "../../general-components/CustomImageUpload.component";
import CustomInputNumber from "../../general-components/CustomInputNumber.component"
import CustomCheckBox3 from '../../general-components/CustomCheckBox3.component';
import CustomButton2 from "../../general-components/CustomButton2.component";
import CustomSelectMulti from "../../general-components/CustomSelectMulti.component";
import CustomPassword from "../../general-components/CustomPassword.component";

import { FiPlus } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../assets/css/selectbar.css';
import CustomImageUploadEmpAdd from "../../general-components/CustomImageUploadEmpAdd.component";
import CustomPinCodeNumber from "../../general-components/CustomPinCodeNumber.component";
import CustomUploadEmpAdd from "../../general-components/CustomUploadEmpAdd.component";

export default function EmployeeProfileAdd({ onCancel }) {
    const [statusEmployeeAdd, setStatusEmployeeAdd] = useState(true);
    let [checkboxWebUserEmployeeAdd, setCheckboxWebUserEmployeeAdd] = useState(true);
    let [checkboxMobileUserEmployeeAdd, setCheckboxMobileUserEmployeeAdd] = useState(true);
    let [isSameAsPermanent, setIsSameAsPermanent] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassWord] = useState("");
    const [dob, setDob] = useState("");
    const [doj, setDoj] = useState("");
    const [compNo, setCompNo] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [pincode, setPincode] = useState("");
    const [pincodeTwo, setPincodeTow] = useState("");
    const [emgName, setEmgName] = useState("");
    const [emgNo, setEmgNo] = useState("");
    const [emgRel, setEmgRel] = useState("");
    const [adharNo, setAdharNo] = useState("");
    const [panNo, setPanNo] = useState("");
    const [otherProof, setOtherProof] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [reportingEmployees, setReportingEmployees] = useState([]);
    const [selectedReportingEmployees, setSelectedReportingEmployees] = useState("");

    const [selectedRegion, setSelectedRegion] = useState("");
    const [genderData, setGenderData] = useState([]);
    const [selectedGender, setSelectedGender] = useState("");
    const [deptData, setDeptData] = useState([]);
    const [selecteDept, setSelectedDept] = useState("");
    const [roleData, setRoleData] = useState([]);
    const [selecteRole, setSelectedRole] = useState("");
    const [companyData, setCompanyData] = useState([]);
    const [selecteCompany, setSelectedCompany] = useState("");
    const [branchData, setBranchData] = useState([]);
    const [selecteBranch, setSelectedBranch] = useState([]);
    const [branchError, setBranchError] = useState(""); // State for branch error
    const [mrgStatusData, setMrgStatusData] = useState([]);
    const [selecteMrgStatus, setSelectedMrgStatus] = useState("");
    const [bGroupData, setBGroupData] = useState([]);
    const [selecteBGroup, setSelectedBGroup] = useState("");

    const [allTerData, setAllTerData] = useState([]);
    const [allTerDataTemp, setAllTerDataTemp] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);

    const [selectedCountryTemp, setSelectedCountryTemp] = useState(null);
    const [selectedStateTemp, setSelectedStateTemp] = useState(null);
    const [selectedDistrictTemp, setSelectedDistrictTemp] = useState(null);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const datacomp = { searchText: "", companyId: 0, isActive: true };
    const userInfo = JSON.parse(localStorage.getItem('user'));

    const [pastCompanyName, setPast_Comp] = useState("");
    const [totalExp, setTotalExp] = useState("");
    const [totalExpErr, setTotalExpErr] = useState("");
    const [remark, setRemark] = useState("");
    const [entries, setEntries] = useState([]);

    const handlePastCompChange = (e) => setPast_Comp(e.target.value);
    const handleTotalExpChange = (e) => setTotalExp(e.target.value);
    const handleRemarkChange = (e) => setRemark(e.target.value);

    const validateDigitsOnly = (value) => /^\d+$/.test(value);
    const handleAddRow = () => {
        if (!validateDigitsOnly(totalExp)) {
            setTotalExpErr("Only Allow Numbers");
            return;
        }
        setTotalExpErr("");
        setEntries([...entries, { pastCompanyName, totalExp, remark }]);
        setPast_Comp("");
        setTotalExp("");
        setRemark("");
    };

    const handleDeleteRow = (indexToDelete) => {
        setEntries(entries.filter((_, index) => index !== indexToDelete));
    };

    const transformEntriesToUserDetailsList = () => {
        return entries.map((entry, index) => ({
            id: index,
            employeeId: 0,  // Assuming employeeId is 0 for all entries; adjust as necessary
            pastCompanyName: entry.pastCompanyName,
            totalExp: entry.totalExp,
            remark: entry.remark
        }));
    };

    useEffect(() => {
        console.log("transformed_data-->", transformEntriesToUserDetailsList());
    }, [entries]);

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, datacomp, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetGenderList`, setGenderData, "Error fetching Gender Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetMaritalStatusList`, setMrgStatusData, "Error fetching Marital Status Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetBloodGroupList`, setBGroupData, "Error fetching Blood Group Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/Profile/GetDepartmentList`, setDeptData, "Error fetching Department Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/Branch/GetBranchList`, setBranchData, "Error fetching Branch Data");

        fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetTerritoriesList`, setAllTerData, "Error fetching Territories Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetTerritoriesList`, setAllTerDataTemp, "Error fetching Territories Data");

        if (userInfo.userId === 1) {
            fetchData(`${process.env.REACT_APP_BASE_URL}/Company/GetCompanyList`, setCompanyData, "Error fetching Company Data");
        } else {
            setSelectedCompany(userInfo.companyId);
        }
    }, []);

    const handleRoleChange = async (selectedOption) => {
        if (selectedOption) {
            const roleId = selectedOption.value;
            setSelectedRole(roleId);

            try {
                // Fetch reporting to employee list for the selected role
                const reportingToResponse = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/Profile/GetReportingToEmpListForSelectList`,
                    { roleId, countryId: 0, isActive: true },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (reportingToResponse.data.isSuccess && Array.isArray(reportingToResponse.data.data)) {
                    setReportingEmployees(reportingToResponse.data.data);
                } else {
                    console.log("Invalid response format for reporting employees");
                }
            } catch (error) {
                toast.error("Error fetching reporting employees");
                console.error("Error fetching reporting employees:", error);
            }
        } else {
            setSelectedRole(null);
            setReportingEmployees([]); // Clear reporting employees if no role is selected
        }
    };

    const handleDeptChange = async (selectedOption) => {
        if (selectedOption) {
            const deptId = selectedOption.value;
            setSelectedDept(deptId);

            // Fetch roles based on the selected department
            try {
                const roleResponse = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/Profile/GetRoleList`,
                    { departmentId: deptId },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (roleResponse.data.isSuccess && Array.isArray(roleResponse.data.data)) {
                    setRoleData(roleResponse.data.data);
                } else {
                    console.log("Invalid response format for roles");
                }
            } catch (error) {
                toast.error("Error fetching roles for the selected department");
                console.error("Error fetching roles:", error);
            }
        } else {
            setSelectedDept(null);
            setRoleData([]);
        }
    };

    const createChangeHandler = (setter) => (selectedOption) => {
        if (selectedOption) {
            setter(selectedOption.value);
        } else {
            setter(null);
        }
    };

    const handleGenderChange = createChangeHandler(setSelectedGender);
    const handleReportingToChange = createChangeHandler(setSelectedReportingEmployees);
    const handleCompanyChange = createChangeHandler(setSelectedCompany);
    const handleMrgStatusChange = createChangeHandler(setSelectedMrgStatus);
    const handleBGroupChange = createChangeHandler(setSelectedBGroup);

    const handleBranchChange = (selectedOption) => {
        setSelectedBranch(selectedOption);
        if (selectedOption.length > 0) {
            setBranchError(""); // Clear error if a branch is selected
        }
    }

    const handleSelectionChange = (setFunc, value, resetFuncs = []) => {
        setFunc(value);
        resetFuncs.forEach(reset => reset(null));
    };

    const handleCountryChange = (selectedOption) => {
        handleSelectionChange(setSelectedCountry, selectedOption?.value, [setSelectedState, setSelectedDistrict]);
    };

    const handleStateChange = (selectedOption) => {
        handleSelectionChange(setSelectedState, selectedOption?.value, [setSelectedDistrict]);
    };

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrict(selectedOption?.value || null);
    };

    const handleCountryChangeTemp = (selectedOption) => {
        handleSelectionChange(setSelectedCountryTemp, selectedOption?.value, [setSelectedStateTemp, setSelectedDistrictTemp]);
    };

    const handleStateChangeTemp = (selectedOption) => {
        handleSelectionChange(setSelectedStateTemp, selectedOption?.value, [setSelectedDistrictTemp]);
    };

    const handleDistrictChangeTemp = (selectedOption) => {
        setSelectedDistrictTemp(selectedOption?.value || null);
    };

    useEffect(() => {
        if (isSameAsPermanent) {
            setAddress2(address1);
            setSelectedCountryTemp(selectedCountry);
            setSelectedStateTemp(selectedState);
            setSelectedDistrictTemp(selectedDistrict);
            setPincodeTow(pincode);
        } else {
            setAddress2("");
            setSelectedCountryTemp(null);
            setSelectedStateTemp(null);
            setSelectedDistrictTemp(null);
            setPincodeTow("");
        }
    }, [isSameAsPermanent, address1, selectedRegion, selectedState, selectedDistrict, pincode]);

    const [mobile, setMobile] = useState('');
    const [uname, setUname] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [cNameError, setCNameError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [emailError, setEmailError] = useState('');

    const validateMobile = (value) => {
        if (!value) {
            setMobileError('Mobile number is required');
            return false;
        } else if (value.length !== 10) {
            setMobileError('Please enter a 10-digit number');
            return false;
        } else {
            setMobileError('');
            return true;
        }
    };

    const validateCName = (value) => {
        if (!value) {
            setCNameError('Employee Name is required');
            return false;
        } else {
            setCNameError('');
            return true;
        }
    };

    const validateEmail = (value) => {
        if (!value) {
            setEmailError('Email is required');
            return false;
        } else if (!/\S+@\S+\.\S+/.test(value)) {
            setEmailError('Email is invalid');
            return false;
        } else {
            setEmailError('');
            return true;
        }
    };

    const handleChangeMobile = (value) => {
        // Remove non-numeric characters and limit length to 10
        const numericValue = value.replace(/\D/g, '').slice(0, 10);
        setMobile(numericValue);
        validateMobile(numericValue);
        checkFormValidity(uname, email, numericValue);
    };

    const handleChangeUname = (value) => {
        setUname(value);
        validateCName(value);
        checkFormValidity(value, email, mobile);
    };

    const handleChangeEmail = (value) => {
        setEmail(value);
        validateEmail(value);
        checkFormValidity(uname, value, mobile);
    };

    const validateBranch = () => {
        if (selecteBranch.length === 0) {
            setBranchError("Please select at least one branch.");
            return false;
        }
        return true;
    };

    const checkFormValidity = (uname, email, mobile) => {
        setIsFormValid(validateCName(uname) && validateEmail(email) && validateMobile(mobile));
    };

    const handleEmployeeSubmit = async (e, userDetailsList) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setIsLoading(true);

        const isMobileValid = validateMobile(mobile);
        const isNameValid = validateCName(uname);
        const isEmailValid = validateEmail(email);
        const isBranchValid = validateBranch();

        if (!isNameValid || !isEmailValid || !isMobileValid || !isBranchValid) {
            setIsLoading(false);
            return;
        }

        try {
            const selectedBranchIds = selecteBranch.map(branch => branch.value);

            // Construct the request body dynamically
            const requestBody = {
                companyId: selecteCompany || userInfo.companyId || 0,
                departmentId: selecteDept || 0,
                userName: uname || "",
                userType: "EMP",
                mobileNumber: mobile || "",
                emailId: email || "",
                password: password || "",
                roleId: selecteRole || 0,
                genderId: selectedGender || 0,
                maritalStatusId: selecteMrgStatus || 0,
                companyNumber: compNo || "",
                addressLine: address1 || "",
                countryId: selectedCountry || 0,
                stateId: selectedState || 0,
                districtId: selectedDistrict || 0,
                pincode: pincode || "",
                isSameAsPermanent: isSameAsPermanent || false,
                temporaryAddress: address2 || "",
                temporary_CountryId: selectedCountryTemp || 0,
                temporary_StateId: selectedStateTemp || 0,
                temporary_DistrictId: selectedDistrictTemp || 0,
                temporary_Pincode: pincodeTwo || "",
                dateOfBirth: dob || "2024-07-06T05:40:43.228Z",
                dateOfJoining: doj || "2024-07-06T05:40:43.228Z",
                emergencyName: emgName || "",
                emergencyRelation: emgRel || "",
                emergencyContactNumber: emgNo || "",
                bloodGroupId: selecteBGroup || 0,
                mobileUniqId: '',
                aadharNumber: adharNo.inputValue || "",
                aadharImage: '',
                aadharImage_Base64: adharNo.fileBase64 || "",
                aadharOriginalFileName: adharNo.fileName || "",
                panNumber: panNo.inputValue || "",
                panCardImage: '',
                panCardImage_Base64: panNo.fileBase64 || "",
                panCardOriginalFileName: panNo.fileName || "",
                otherProof: otherProof.inputValue || "",
                otherProofImage: "",
                otherProofImage_Base64: otherProof.fileBase64 || "",
                otherProofOriginalFileName: otherProof.fileName || "",
                profileImage: '',
                profileImage_Base64: profileImage.base64 || "",
                profileOriginalFileName: profileImage.name || "",
                isMobileUser: checkboxMobileUserEmployeeAdd,
                isWebUser: checkboxWebUserEmployeeAdd,
                isActive: statusEmployeeAdd,
                branchList: selectedBranchIds.map(id => ({ branchId: id })),
                userOtherDetailsList: userDetailsList
            };

            // Conditionally add the reportingTo field
            if (selectedReportingEmployees) {
                requestBody.reportingTo = selectedReportingEmployees;
            }

            console.log("api-responsee--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/User/SaveUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                if (responseData.message === "Record is already exists") {
                    toast.error("Duplicate entry is not permissable");
                    resetFormFields();
                } else {
                    toast.success("Record Inserted successfully");
                    resetFormFields();
                }

                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 1500);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Employee Profile operation:", error);
            toast.error("Error performing Employee Profile operation");
        } finally {
            setIsLoading(false);
        }
    };


    // This transformation function should be defined appropriately based on your application's needs
    const userDetailsList = transformEntriesToUserDetailsList(entries);

    // This function is typically used in response to an event (like form submission)
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        handleEmployeeSubmit(e, userDetailsList);
    };

    const resetFormFields = () => {
        setEmail("");
        setPassWord("");
        setDob("");
        setDoj("");
        setCompNo("");
        setAddress1("");
        setAddress2("");
        setPincode("");
        setPincodeTow("");
        setEmgName("");
        setEmgNo("");
        setEmgRel("");
        setAdharNo("");
        setPanNo("");
        setOtherProof("");
        setProfileImage("");
        setReportingEmployees([]);
        setSelectedReportingEmployees("");
        setSelectedRegion("");
        setGenderData([]);
        setSelectedGender("");
        setDeptData([]);
        setSelectedDept("");
        setRoleData([]);
        setSelectedRole("");
        setCompanyData([]);
        setSelectedCompany("");
        setBranchData([]);
        setSelectedBranch([]);
        setMrgStatusData([]);
        setSelectedMrgStatus("");
        setBGroupData([]);
        setSelectedBGroup("");
        setAllTerData([]);
        setAllTerDataTemp([]);
        setSelectedCountry(null);
        setSelectedState(null);
        setSelectedDistrict(null);
        setSelectedCountryTemp(null);
        setSelectedStateTemp(null);
        setSelectedDistrictTemp(null);
        setCheckboxWebUserEmployeeAdd(true);
        setCheckboxMobileUserEmployeeAdd(true);
        setIsSameAsPermanent(false);
        setStatusEmployeeAdd(true);
    };

    const uniqueBy = (arr, key) => {
        return [...new Map(arr.map(item => [item[key], item])).values()];
    };

    const getCountries = () => {
        return uniqueBy(allTerData, 'countryId').map(data => ({
            value: data.countryId,
            label: data.countryName
        }));
    };

    const getStatesForCountry = () => {
        return uniqueBy(allTerData.filter(data => data.countryId === selectedCountry), 'stateId').map(data => ({
            value: data.stateId,
            label: data.stateName
        }));
    };

    const getDistrictsForState = () => {
        return uniqueBy(allTerData.filter(data => data.stateId === selectedState), 'districtId').map(data => ({
            value: data.districtId,
            label: data.districtName
        }));
    };


    const getCountriesTemp = () => {
        return uniqueBy(allTerDataTemp, 'countryId').map(data => ({
            value: data.countryId,
            label: data.countryName
        }));
    };

    const getStatesForCountryTemp = () => {
        return uniqueBy(allTerDataTemp.filter(data => data.countryId === selectedCountryTemp), 'stateId').map(data => ({
            value: data.stateId,
            label: data.stateName
        }));
    };

    const getDistrictsForStateTemp = () => {
        return uniqueBy(allTerDataTemp.filter(data => data.stateId === selectedStateTemp), 'districtId').map(data => ({
            value: data.districtId,
            label: data.districtName
        }));
    };

    return (
        <>
            <div className="bg-white min-w-[350px] pb-5 rounded-lg overflow-visible">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-l font-weight-[300]">Add Employee</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="p-4 gap-4 max-h-[70vh] overflow-y-auto max-w-full w-[1100px]">
                        <Accordion title={"Employee Details"}>
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="uname" className="text-xs font-[400]">
                                            Employee Name<span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            value={uname}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${cNameError ? 'border-red-500' : ''}`}
                                            id="uname"
                                            placeholder="Enter"
                                            onChange={(e) => handleChangeUname(e.target.value)}
                                            onBlur={(e) => validateCName(e.target.value)}
                                        />
                                        {cNameError && <span className="text-red-500 text-xs">{cNameError}</span>}
                                    </div>
                                </div>

                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="email" className="text-xs font-[400]">
                                            Email Id
                                            <span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            value={email}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${emailError ? 'border-red-500' : ''}`}
                                            id="email"
                                            placeholder="Enter"
                                            onChange={(e) => handleChangeEmail(e.target.value)}
                                            onBlur={(e) => validateEmail(e.target.value)}
                                        />
                                        {emailError && <span className="text-red-500 text-xs">{emailError}</span>}
                                    </div>
                                </div>

                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="mobile" className="text-xs font-[400]">
                                            Mobile Number<span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="tel"
                                            value={mobile}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${mobileError ? 'border-red-500' : ''}`}
                                            id="mobile"
                                            placeholder="Enter"
                                            onChange={(e) => handleChangeMobile(e.target.value)}
                                            onBlur={(e) => validateMobile(e.target.value)}
                                        />
                                        {mobileError && <span className="text-red-500 text-xs">{mobileError}</span>}
                                    </div>
                                </div>


                                <div>
                                    {userInfo.userId === 1 ? (
                                        <CustomSelect1
                                            label={"Company"}
                                            placeholder={"Enter"}
                                            isRequired={false}
                                            options={companyData.map(data => ({
                                                value: data.id,
                                                label: data.companyName
                                            }))}
                                            onChange={handleCompanyChange}
                                        />
                                    ) : (

                                        <CustomTextField2
                                            label={"Company"}
                                            placeholder={"ENTER"}
                                            isRequired={false}
                                            value={userInfo.companyName}
                                            // onChange={setDepHead}
                                            readOnly={true}
                                        ></CustomTextField2>
                                    )}
                                </div>
                                <div>
                                    <CustomSelectMulti label="Branch" isRequired={true} placeholder="Enter"
                                        options={branchData.map(data => ({
                                            value: data.id,
                                            label: data.branchName
                                        }))}
                                        onChange={handleBranchChange}
                                    />
                                    {branchError && (
                                        <span className="text-red-500 text-xs">{branchError}</span>
                                    )}
                                </div>
                                <CustomSelect1 label="Department" isRequired={false} placeholder="Enter"
                                    options={deptData.map(data => ({
                                        value: data.id,
                                        label: data.departmentName
                                    }))}
                                    onChange={handleDeptChange}
                                />
                                <CustomSelect1 label="Role"
                                    isRequired={false}
                                    options={roleData.map(data => ({
                                        value: data.id,
                                        label: data.roleName
                                    }))}
                                    onChange={handleRoleChange}
                                />
                                <CustomSelect1
                                    label="Reporting To"
                                    isRequired={false}
                                    options={reportingEmployees.map((employee) => ({
                                        value: employee.value,
                                        label: employee.text,
                                    }))}
                                    onChange={handleReportingToChange}
                                />
                                <CustomDate label="Date of Birth"
                                    value={dob}
                                    onChange={setDob}
                                    isRequired={false}

                                />
                                <CustomDate label="Date of Joining"
                                    value={doj}
                                    onChange={setDoj}
                                    isRequired={false}
                                />
                                <CustomSelect1 label="Blood Group"
                                    isRequired={false}
                                    options={bGroupData.map(data => ({
                                        value: data.id,
                                        label: data.bloodGroup
                                    }))}
                                    onChange={handleBGroupChange}
                                />
                                <CustomSelect1 label="Gender"
                                    isRequired={false}
                                    options={genderData.map(data => ({
                                        value: data.id,
                                        label: data.genderName
                                    }))}
                                    onChange={handleGenderChange}
                                />
                                <CustomSelect1 label="Marital Status"
                                    isRequired={false}
                                    options={mrgStatusData.map(data => ({
                                        value: data.id,
                                        label: data.maritalStatus
                                    }))}
                                    onChange={handleMrgStatusChange}
                                />
                                <CustomNumber label="Company Number" placeholder="Enter"
                                    value={compNo}
                                    onChange={setCompNo}
                                    isRequired={false}
                                />
                                <CustomPassword label="Password" placeholder="Enter"
                                    value={password}
                                    onChange={setPassWord}
                                    isRequired={false}
                                />
                            </div>
                        </Accordion>

                        <Accordion title="Permanent Address">
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <CustomTextArea label="Address" placeholder="Enter"
                                    value={address1}
                                    onChange={setAddress1}
                                    isRequired={false}
                                />
                                <CustomSelect1 label={"Country"} placeholder={"ENTER"}
                                    isRequired={false}
                                    options={getCountries()}
                                    onChange={handleCountryChange}
                                />
                                <CustomSelect1 label={"State"} placeholder={"ENTER"}
                                    isRequired={false}
                                    options={selectedCountry ? getStatesForCountry() : []}
                                    onChange={handleStateChange}
                                    isDisabled={!selectedCountry}
                                />
                                <CustomSelect1 label={"District"} placeholder={"ENTER"}
                                    isRequired={false}
                                    options={selectedState ? getDistrictsForState() : []}
                                    onChange={handleDistrictChange}
                                    isDisabled={!selectedState}
                                />
                                <CustomPinCodeNumber label="Pin Code" placeholder="Enter"
                                    value={pincode}
                                    onChange={setPincode}
                                    isRequired={false}
                                />
                            </div>
                        </Accordion>

                        <Accordion title="Temporary Address">
                            <div className="flex justify-end">
                                <CustomCheckBox3
                                    label={"is Same As Permanent ?"}
                                    state={isSameAsPermanent}
                                    setState={setIsSameAsPermanent}
                                />
                            </div>
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <CustomTextArea label="Address" placeholder="Enter"
                                    value={address2}
                                    onChange={setAddress2}
                                    isRequired={false}
                                />
                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="idp1" className="text-xs font-400 ">
                                            Country
                                        </label>
                                        <Select
                                            options={getCountriesTemp()}
                                            onChange={handleCountryChangeTemp}
                                            value={getCountriesTemp().find(option => option.value === selectedCountryTemp) || null}
                                            className="text-black text-xs select-bar uppercase"
                                            id="idp1"
                                            isSearchable
                                            isClearable
                                            menuPosition={'fixed'}
                                            closeMenuOnSelect={true}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#e5e7eb',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="idp2" className="text-xs font-400 ">
                                            State
                                        </label>
                                        <Select
                                            options={selectedCountryTemp ? getStatesForCountryTemp() : []}
                                            onChange={handleStateChangeTemp}
                                            value={getStatesForCountryTemp().find(option => option.value === selectedStateTemp) || null}
                                            className="text-black text-xs select-bar uppercase"
                                            id="idp2"
                                            isSearchable
                                            isClearable
                                            menuPosition={'fixed'}
                                            isDisabled={!selectedCountryTemp}
                                            closeMenuOnSelect={true}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#e5e7eb',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="idp3" className="text-xs font-400 ">
                                            District
                                        </label>
                                        <Select
                                            options={selectedStateTemp ? getDistrictsForStateTemp() : []}
                                            onChange={handleDistrictChangeTemp}
                                            value={getDistrictsForStateTemp().find(option => option.value === selectedDistrictTemp) || null}
                                            className="text-black text-xs select-bar uppercase"
                                            id="idp3"
                                            isSearchable
                                            isClearable
                                            menuPosition={'fixed'}
                                            isDisabled={!selectedStateTemp}
                                            closeMenuOnSelect={true}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#e5e7eb',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <CustomPinCodeNumber label="Pin Code" placeholder="Enter"
                                    value={pincodeTwo}
                                    onChange={setPincodeTow}
                                    isRequired={false}
                                />
                            </div>
                        </Accordion>

                        <Accordion title="Emergency ">
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <CustomTextField2 label="Emergency Name" placeholder="Enter"
                                    value={emgName}
                                    onChange={setEmgName}
                                    isRequired={false}
                                />
                                <CustomNumber label="Emergency Contact" placeholder="Enter"
                                    value={emgNo}
                                    onChange={setEmgNo}
                                    isRequired={false}
                                />
                                <CustomTextField2 label="Emergency Relation" placeholder="Enter"
                                    value={emgRel}
                                    onChange={setEmgRel}
                                    isRequired={false}
                                />
                            </div>
                        </Accordion>

                        <Accordion title="Other Details">
                            {/* <OtherDetail /> */}
                            <div className="flex justify-end">
                                <Button
                                    variant={"contained"}
                                    size={"small"}
                                    type="button"
                                    className={"flex gap-1 capitalize justify-center items-center relative"}
                                    onClick={handleAddRow}
                                    style={{ backgroundColor: "#643c94" }}
                                >
                                    <FiPlus />
                                    <span className="whitespace-nowrap text-xs font-[400] font-Mitr">
                                        Add Row
                                    </span>
                                </Button>
                            </div>
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <div className="flex flex-col gap-1 relative">
                                    <label htmlFor="id" className="text-xs font-[400] uppercase">
                                        Past Company Name
                                    </label>
                                    <input
                                        type="text"
                                        value={pastCompanyName}
                                        className="p-2   border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                        id="id"
                                        placeholder="Enter"
                                        onChange={handlePastCompChange}
                                    />
                                </div>
                                <div className="flex flex-col gap-1 relative">
                                    <label htmlFor="id" className="text-xs font-[400] uppercase">
                                        Total Exp
                                    </label>
                                    <input
                                        type="text"
                                        value={totalExp}
                                        className="p-2  border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                        id="id"
                                        placeholder="Enter"
                                        onChange={handleTotalExpChange}
                                    />
                                    {totalExpErr && <p className="absolute -bottom-4 text-xs font-normal" style={{ color: 'red' }}>{totalExpErr}</p>}
                                </div>
                                <div className="flex flex-col gap-1 relative">
                                    <label htmlFor="id" className="text-xs font-[400] uppercase">
                                        Remark
                                    </label>
                                    <input
                                        type="text"
                                        value={remark}
                                        className="p-2  border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                        id="id"
                                        placeholder="Enter"
                                        onChange={handleRemarkChange}
                                    />
                                </div>
                            </div>

                            <div className="w-full mb-4 mt-4 overflow-hidden rounded">
                                <div className="w-full overflow-x-auto">
                                    <table className="w-full">
                                        <thead className="text-center ">
                                            <tr className="text-center text-md  text-gray-900 bg-gray-100 uppercase border border-gray-200 border-collapse">
                                                <td className="text-xs py-2 border border-gray-200"> S. No </td>
                                                <td className="text-xs py-2 border border-gray-200"> Past Company Name </td>
                                                <td className="text-xs py-2 border border-gray-200"> Total Exp </td>
                                                <td className="text-xs py-2 border border-gray-200"> Remark </td>
                                                <td className="text-xs py-2 border border-gray-200"> Action </td>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {entries.map((entry, index) => (
                                                <tr key={index} className="text-xs text-center border border-gray-200 border-collapse text-gray-600">
                                                    <td className="text-xs border border-gray-200">{index + 1}</td>
                                                    <td className="text-xs border border-gray-200">{entry.pastCompanyName}</td>
                                                    <td className="text-xs border border-gray-200">{entry.totalExp}</td>
                                                    <td className="text-xs border border-gray-200">{entry.remark}</td>
                                                    <td className="flex items-center justify-center">
                                                        <button className="bg-prp-color text-white p-2 rounded" onClick={() => handleDeleteRow(index)}>
                                                            <RiDeleteBinLine />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <CustomUploadEmpAdd id="addhar-number-add" isRequired={false} label="Addhar Number"
                                    value={adharNo}
                                    onChange={setAdharNo}
                                />
                                <CustomUploadEmpAdd id="pan-upload-add" isRequired={false} label="PAN No."
                                    value={panNo}
                                    onChange={setPanNo}
                                />
                                <CustomUploadEmpAdd id="other-proof-upload-add" isRequired={false} label="Other Proof"
                                    value={otherProof}
                                    onChange={setOtherProof}
                                />
                                <CustomImageUploadEmpAdd id="image-upload-add" isRequired={false} label="Image Upload"
                                    value={profileImage}
                                    onChange={setProfileImage}
                                />
                                <CustomCheckBox3
                                    label={"Is Web User"}
                                    state={checkboxWebUserEmployeeAdd}
                                    setState={setCheckboxWebUserEmployeeAdd}
                                >
                                </CustomCheckBox3>
                                <CustomCheckBox3
                                    label={"Is Mobile User"}
                                    state={checkboxMobileUserEmployeeAdd}
                                    setState={setCheckboxMobileUserEmployeeAdd}
                                >
                                </CustomCheckBox3>
                                <CustomCheckBox3
                                    label={"Is Active"}
                                    state={statusEmployeeAdd}
                                    setState={setStatusEmployeeAdd}
                                >
                                </CustomCheckBox3>
                            </div>
                        </Accordion>
                    </div>

                    <div className="flex justify-center gap-5 mt-5">
                        <div>
                            <CustomButton1 label={isLoading ? "Loading" : "Submit"} className="text-white bg-first" type="submit" />
                        </div>
                        <div onClick={onCancel}>
                            <CustomButton2 label={"Cancel"} className="text-white bg-first" />
                        </div>
                    </div>
                </form>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
