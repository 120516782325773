import { useEffect, useRef } from "react";

function ToolTipY(props) {
    let toolTipRef = useRef(null);
    let containerRef = useRef(null);

    useEffect(function () {
        closeToolTip();

        if (props.direction === "down") {
            toolTipRef.current.style.top = "120%";
        } else if (props.direction === "up") {
            toolTipRef.current.style.bottom = "120%";
        }

        if (props.position === "left") {
            containerRef.current.style.justifyContent = "flex-start";
        } else if (props.position === "right") {
            containerRef.current.style.justifyContent = "flex-end";
        } else if (props.position === "center") {
            containerRef.current.style.justifyContent = "center";
        }
    }, [props.direction, props.position]); // Dependencies for useEffect

    function openToolTip() {
        toolTipRef.current.style.height = props.height;
    }

    function closeToolTip() {
        toolTipRef.current.style.height = "0px";
    }

    return (
        <div
            className="relative flex items-center uppercase"
            ref={containerRef}
            onMouseEnter={openToolTip}
            onMouseLeave={closeToolTip}
        >
            <div>{props.title}</div>
            <div
                className="absolute z-10 h-0 overflow-hidden transition-all bg-white rounded w-fit custom-shadow hide-scrollbar"
                ref={toolTipRef}
            >
                <div>{props.content}</div>
            </div>
        </div>
    );
}

export default ToolTipY;
