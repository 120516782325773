// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from "js-cookie"; // Import js-cookie for handling cookies
import Select from 'react-select';

import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomNumber from "../../general-components/CustomNumber.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomInputNumber from "../../general-components/CustomInputNumber.component";

export default function ClientDetails({
    next,
    onCancel,
    cName,
    setCName,
    mobile,
    setMobile,
    parentCustomer,
    landLineNumber,
    setLandLineNumber,
    setCustomerId,
    setCountryId,
    countryData,
}) {
    const [custType, setCustType] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [mobileError, setMobileError] = useState('');
    const [cNameError, setCNameError] = useState('');
    const dataActive = { searchText: "", isActive: true };
    const token = localStorage.getItem('DIYAN_IE_authToken');

    const [selectedParentCustomer, setSelectedParentCustomer] = useState(null);
    const [selectedCustomerType, setSelectedCustomerType] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const validateCName = (value) => {
        if (!value) {
            setCNameError('Customer Name is required');
            return false;
        } else {
            setCNameError('');
            return true;
        }
    };

    const validateMobile = (value) => {
        if (value && !/^\d+$/.test(value)) { // Validate only if value exists
            setMobileError('Mobile number must contain only numbers');
            return false;
        } else {
            setMobileError('');
            return true;
        }
    };

    const handleMobileChange = (e) => {
        const { value } = e.target;
        const formattedValue = value.replace(/\D/g, ''); // Remove any non-digit characters
        // if (formattedValue.length <= 10) {
        setMobile(formattedValue); // Set the value only if it's 10 digits or less
        // }
    };

    const handleNextClick = () => {
        const isNameValid = validateCName(cName);
        const isMobileValid = validateMobile(mobile);

        if (isNameValid && isMobileValid) {
            next();
        }
    };

    const fetchCustTypeApiData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCustomerTypeList`, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data;
                setCustType(sortedData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Customer Type Data");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCustTypeApiData();
    }, [])

    useEffect(() => {
        // Load data from cookies
        const savedData = Cookies.get("customerFormDataParent");
        if (savedData) {
            const parsedData = JSON.parse(savedData);

            // Set Customer Type
            const matchedCustomerType = custType.find((c) => c.id === parsedData.customerTypeId);
            if (matchedCustomerType) {
                setSelectedCustomerType({
                    value: matchedCustomerType.id,
                    label: matchedCustomerType.customerType, // Ensure this matches the label in `options`
                });
            }

            // Set Country
            const matchedCountry = countryData.find((c) => c.id === parsedData.countryId);
            if (matchedCountry) {
                setSelectedCountry({
                    value: matchedCountry.id,
                    label: matchedCountry.countryName, // Ensure this matches the label in `options`
                });
            }
        }
    }, [custType, countryData]);

    const handlePaperTypeChange = (selectedOption) => {
        if (selectedOption) {            
            setSelectedCustomerType(selectedOption);
            setCustomerId(selectedOption.value);
        } else {
            setSelectedCustomerType(null)
            setCustomerId(null); // Reset to null when cleared
        }
    };

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        if (selectedOption) {
            setCountryId(selectedOption.value);
        } else {
            setSelectedCountry(null);
            setCountryId(null); // Reset to null when cleared
        }
    };

    return (
        <>
            <div>
                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[900px]">
                        <tbody>
                            <tr>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="cName" className="text-xs font-[400]">
                                            Customer Name
                                            <span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            value={cName}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${cNameError ? 'border-red-500' : ''}`}
                                            id="cName"
                                            placeholder="Enter"
                                            onChange={(e) => setCName(e.target.value)}
                                            onBlur={(e) => validateCName(e.target.value)}
                                        />
                                        {cNameError && <span className="text-red-500 text-xs">{cNameError}</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="mobile" className="text-xs font-[400]">
                                            Phone Number
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            value={mobile}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${mobileError ? 'border-red-500' : ''}`}
                                            id="mobile"
                                            placeholder="Enter"
                                            onChange={handleMobileChange}
                                            onBlur={(e) => validateMobile(e.target.value)}
                                        />
                                        {mobileError && <span className="text-red-500 text-xs">{mobileError}</span>}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField2
                                        label={"Parent Customer"}
                                        placeholder={"ENTER"}
                                        value={parentCustomer}
                                        readOnly={true}
                                    ></CustomTextField2>
                                </td>
                                <td>
                                    <CustomInputNumber
                                        label={"Landline Number"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        value={landLineNumber}
                                        onChange={setLandLineNumber}
                                    ></CustomInputNumber>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="id" className="text-xs font-400 ">
                                            Customer Type
                                        </label>

                                        <Select
                                            options={custType.map(cData => ({
                                                value: cData.id,
                                                label: cData.customerType
                                            }))}
                                            onChange={handlePaperTypeChange}
                                            value={selectedCustomerType}
                                            // className="px-1.5 py-2 border rounded grow min-w-[14rem] text-gray-400 text-xs outline-none bg-white"
                                            className="text-black text-xs select-bar uppercase"
                                            id="id"
                                            isSearchable
                                            isClearable
                                            menuPosition={'fixed'} // Set menuPosition to fixed
                                            closeMenuOnSelect={true}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#e5e7eb',
                                                },
                                            })}
                                        />

                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="id" className="text-xs font-400 ">
                                            country
                                        </label>

                                        <Select
                                            options={countryData.map(cData => ({
                                                value: cData.id,
                                                label: cData.countryName
                                            }))}
                                            onChange={handleCountryChange}
                                            value={selectedCountry}
                                            // className="px-1.5 py-2 border rounded grow min-w-[14rem] text-gray-400 text-xs outline-none bg-white"
                                            className="text-black text-xs select-bar uppercase"
                                            id="id"
                                            isSearchable
                                            isClearable
                                            menuPosition={'fixed'} // Set menuPosition to fixed
                                            closeMenuOnSelect={true}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#e5e7eb',
                                                },
                                            })}
                                        />

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={handleNextClick}>
                        <CustomButton1 label={"Save and Continue"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
