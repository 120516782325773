// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { Tooltip } from "@mui/material";

// COMPONENTS
import CustomButton1 from "../components/general-components/CustomButton1.component";
import Title from "../components/general-components/Title.component";
import PopUp from "../components/general-components/PopUp.componenet";
import CustomButton4 from "../components/general-components/CustomButton4.component";
import Pagination from "../components/general-components/Pagination.component";

// ICONS
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";

import ManageCompanyDetailAdd from "../components/forms-components/ManageCompanyDetail/ManageCompanyDetailAdd";
import ManageCompanyDetailEdit from "../components/forms-components/ManageCompanyDetail/ManageCompanyDetailEdit";
import ManageCompanyDetailView from "../components/forms-components/ManageCompanyDetail/ManageCompanyDetailView";

import { getSinglePermission } from "../../Helpers/Constants";
import { useAuth } from "../context";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ManageCompanyDetails() {
    const { user } = useAuth()
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "ADMIN COMPANY"))
        }
    }, [user])

    let [popupCompanyDetailsAdd, setPopupCompanyDetailsAdd] = useState(false);
    let [popupCompanyNameEdit, setPopupCompanyNameEdit] = useState(false);
    let [popupCompanyNameView, setPopupCompanyNameView] = useState(false);

    const [companyDetails, setCompanyDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCompDetail, setSelectedCompDetail] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const [searchText, setSearchText] = useState("");

    const fetchCompanyDetail = async (searchQuery) => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('DIYAN_IE_authToken');
            const userInfo = JSON.parse(localStorage.getItem('user'));

            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Company/GetCompanyList`,
                { searchText: searchQuery, companyId: 0 }, // Include searchText in the request body
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                }
            );

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                let filteredData = response.data.data;

                // Apply company name filter only if roleId is not 1
                if (Number(userInfo.roleId) !== 1) {
                    filteredData = filteredData.filter(company => company.companyName === userInfo.companyName);
                }

                // Sort the data by isActive status first, then by createdDate
                filteredData = filteredData.sort((a, b) => {
                    // Sort by isActive: true first, then by createdDate (descending)
                    if (a.isActive === b.isActive) {
                        return new Date(b.createdDate) - new Date(a.createdDate);
                    }
                    return a.isActive ? -1 : 1; // Active companies first
                });

                setCompanyDetails(filteredData);
                setTotalPages(Math.ceil(filteredData.length / rowsPerPage));
                setCurrentPage(1);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Company Details Group");
        } finally {
            setIsLoading(false);
        }
    };

    const handleViewButton = (compId) => {
        fetchCompDetailById(compId)
        setPopupCompanyNameView(true);
    }

    const handleEditButton = (compId) => {
        fetchCompDetailById(compId)
        setPopupCompanyNameEdit(true);
    }

    const fetchCompDetailById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Company/GetCompanyById?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && response.data.data) {
                const cData = response.data.data
                setSelectedCompDetail(cData);                
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Admin Company Detail details");
        } finally {
            setIsLoading(false);
        }
    };

    const paginatedcompDetails = companyDetails.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(() => {
        fetchCompanyDetail(searchText);
    }, [searchText, rowsPerPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(companyDetails.length / rowsPerPage));
    }, [companyDetails, rowsPerPage]);

    const clearSearch = () => {
        setSearchText(""); // Clear the search input
    };

    return (
        <>
            <div className="flex flex-col h-full  p-2 uppercase">
                <div className="p-4 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                    <Title title1={"Admin Company"} title2={"ADMINISTRATOR"} />

                    <div className="px-2 lg:px-4 mt-5">
                        <div className="flex flex-row flex-wrap justify-start gap-2  md:flex-nowrap  minbox">
                            {Permission?.add &&
                                <CustomButton1
                                    label={"Add"}
                                    icon={<IoMdAdd />}
                                    className="custmbtn text-fourth shrink grow md:grow-0 max-w-[50%]"
                                    onClick={() => setPopupCompanyDetailsAdd(true)}
                                />
                            }

                            <div className="flex items-center justify-between gap-1 px-2  ml-auto text-[rgba(0, 0, 0, 0.50)] boreder rounded bg-white shrink grow md:grow-0">
                                <input
                                    type="text"
                                    className="w-[210px] py-1 grow test-sm uppercase bg-transparent placeholder:text-sm"
                                    placeholder="SEARCH"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                {searchText ? (
                                    <IoMdCloseCircle
                                        className="min-w-[20px] cursor-pointer txt-prp-color"
                                        onClick={clearSearch}
                                    />
                                ) : (
                                    <AiOutlineSearch className="min-w-[20px]" />
                                )}
                            </div>
                        </div>

                        {Permission?.view &&
                            <>
                                {/* TABLE */}
                                <div className="mt-7 max-w-[100vw] hide-scrollbar overflow-auto table-container">
                                    <table className="w-full custom-table" border={1}>
                                        <thead>
                                            <tr className="table-heading">
                                                <td className="">S.No</td>
                                                {(Permission?.view === true || Permission?.edit === true) &&
                                                    <td>Action</td>}
                                                <td>Status</td>
                                                <td>Company Name</td>
                                                <td>Company Type</td>
                                                <td>Registration Number</td>
                                                <td>Contact Number</td>
                                                <td>Email</td>
                                                <td>Website</td>
                                                <td>Country</td>
                                                <td>State</td>
                                                <td>District / Province</td>
                                                <td>Pincode</td>
                                                <td>Gst Number</td>
                                                <td>Pan Number</td>
                                                <td>Created Date </td>
                                                <td>Created By </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <tr>
                                                    <td colSpan="17" className="text-start">Loading...</td>
                                                </tr>
                                            ) : (paginatedcompDetails.length > 0 ? (
                                                paginatedcompDetails.map((compData, index) => (
                                                    <tr key={compData.id}>
                                                        <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                                        {(Permission?.view || Permission?.edit) && (
                                                            <td>
                                                                <div className="gap-2">
                                                                    {Permission?.edit && (
                                                                        <Tooltip title="EDIT" arrow placement="left">
                                                                            <span>
                                                                                <CustomButton1
                                                                                    className="bg-sixt text-white grow max-w-[50px]"
                                                                                    icon={<BiEdit />}
                                                                                    onClick={() => handleEditButton(compData.id)}
                                                                                />
                                                                            </span>
                                                                        </Tooltip>
                                                                    )}
                                                                    {Permission?.view && (
                                                                        <Tooltip title="VIEW" arrow placement="right">
                                                                            <span>
                                                                                <CustomButton4
                                                                                    className="bg-eye text-white grow max-w-[50px]"
                                                                                    icon={<BsEyeFill />}
                                                                                    onClick={() => handleViewButton(compData.id)}
                                                                                />
                                                                            </span>
                                                                        </Tooltip>

                                                                    )}
                                                                </div>
                                                            </td>
                                                        )}

                                                        <td>
                                                            <div>
                                                                <h2 style={{ color: compData.isActive ? 'green' : 'red' }}>{compData.isActive ? 'Active' : 'Inactive'}</h2>
                                                            </div>
                                                        </td>
                                                        <td>{compData.companyName}</td>
                                                        <td>{compData.companyType}</td>
                                                        <td>{compData.registrationNumber}</td>
                                                        <td>{compData.contactNumber}</td>
                                                        <td>{compData.email}</td>
                                                        <td>{compData.website}</td>
                                                        <td>{compData.countryName}</td>
                                                        <td>{compData.stateName}</td>
                                                        <td>{compData.districtName}</td>
                                                        <td>{compData.pincode}</td>
                                                        <td>{compData.gstNumber}</td>
                                                        <td>{compData.panNumber}</td>
                                                        <td>{new Date(compData.createdDate).toLocaleDateString('en-GB')}</td>
                                                        <td>{compData.creatorName}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                searchText?.trim() ? (
                                                    <tr>
                                                        <td colSpan="17" className="text-start py-4">
                                                            No results found for "{searchText}".
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td colSpan="17" className="text-start py-4">
                                                            No data available.
                                                        </td>
                                                    </tr>
                                                )
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <Pagination
                                    currentPage={currentPage}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                    totalPages={totalPages}
                                    setCurrentPage={setCurrentPage}
                                    data={companyDetails}
                                />
                            </>
                        }
                    </div>

                    {/* POPUP 1 : ADD */}
                    {popupCompanyDetailsAdd && (
                        <PopUp>
                            <ManageCompanyDetailAdd
                                onCancel={() => {
                                    setPopupCompanyDetailsAdd(false);
                                    fetchCompanyDetail();
                                }}
                            />
                        </PopUp>
                    )}

                    {/* POPUP 2 : EDIT */}
                    {popupCompanyNameEdit && (
                        <PopUp>
                            <ManageCompanyDetailEdit
                                selectedCompDetail={selectedCompDetail}
                                onCancel={() => {
                                    setPopupCompanyNameEdit(false);
                                    fetchCompanyDetail();
                                }}
                            />
                        </PopUp>
                    )}

                    {/* POPUP 2 : View */}
                    {popupCompanyNameView && (
                        <PopUp>
                            <ManageCompanyDetailView
                                compData={selectedCompDetail}
                                onCancel={() => setPopupCompanyNameView(false)}
                            />
                        </PopUp>
                    )}
                </div>
            </div>
            <ToastContainer autoClose={2000} />
        </>
    );
}

export default ManageCompanyDetails;
