// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-modal';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import Accordion from "../../general-components/Accodion";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ViewPaymentLCReceived from "../ManagePaymentReceived_LCReceived/ViewPaymentLCReceived";
import ViewOrderAccepted from "../ManageOrderAccepted/ViewOrderAccepted";
import ViewOrderUnderProcess from "../ManageOrderUnderProcess/ViewOrderUnderProcess";
import ViewBookingIssue from "../ManageBookingIssue/ViewBookingIssue";
import ViewContainersUnderLoading from "../ManageContainersUnderLoading/ViewContainersUnderLoading";
import ViewInvoiceGenerated from "../ManageInvoiceGenerated/ViewInvoiceGenerated";
import CustomImgPdfSingle from "../../general-components/CustomImgPdfSingle.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import { Button, Tooltip } from "@mui/material";

import { MdCancel } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { ImEnlarge } from "react-icons/im";
import { RiDeleteBin6Line } from "react-icons/ri";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PopUp from "../../general-components/PopUp.componenet";
import Remarks from "./Remarks";
import { IoCheckmarkCircle } from 'react-icons/io5';
import { IoRemoveCircle } from 'react-icons/io5';
import { BiLike } from 'react-icons/bi';
import { BiDislike } from 'react-icons/bi';
import { IoMdEye } from "react-icons/io";
import PopupRemarks from "./PopupRemarks";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomFileUpload from './../../general-components/CustomFileUpload.component';

export default function ManageBiDraftIssuedEdit({ onCancel, selectedValue }) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState([9]);
    const [orderDate, setOrderData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [conAttach, setConAttach] = useState([]);
    const [remarksAdd, setRemarksAdd] = useState("");
    const [newRemarks, setNewRemarks] = useState([]); // To store the remarks passed from the child
    const [checkboxClose, setCheckboxClose] = useState(false)
    const [checkboxBid, setCheckboxBid] = useState(false)

    const [conAttachNew, setConAttachNew] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalFile, setModalFile] = useState(null);
    const [popupRemarkOpen, setPopupRemarkOpen] = useState(false);
    const [updatePopup, setUpdatePopup] = useState(false);
    const [statusId, setStatusId] = useState(1);
    const [endCloseDateTime, setEndCloseDateTime] = useState(null);
    const [selectedLogList, setSelectedLogList] = useState([]);
    const [visiblePopup, setVisiblePopup] = useState(false)

    const [profileImage, setProfileImage] = useState("");  // For the selected new image
    const [fileOriginalName, setFileOriginalName] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [fileId, setFileId] = useState("");  // Track existing file ID or 0 if new

    const endFormatDate = (date) =>
        date
            ? new Date(date).toLocaleString('en-IN', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, // 12-hour format
            })
            : 'N/A';

    const handleCheckboxChange = (state) => {
        setCheckboxClose(state);

        // Set the time every time the checkbox is flagged (true)
        if (state) {
            const timestamp = new Date().toISOString();
            setEndCloseDateTime(timestamp); // update time on each flag
        }
    };

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((i) => i !== index) // Close if already open
                : [...prevIndexes, index] // Add to open accordions
        );
    };

    const handleConAttachChange = (file) => {
        if (file) {
            setConAttachNew([file]); // Only allow one file to be uploaded
        } else {
            setConAttachNew([]); // Set to an empty array if no file is selected
        }
    };

    useEffect(() => {
        // Set default date to current date
        const currentDate = new Date().toISOString().split("T")[0];
        setOrderData(currentDate);
    }, []);

    useEffect(() => {
        if (selectedValue && selectedValue.biDraftIssuedImagesList) {
            const conAttach = selectedValue.biDraftIssuedImagesList.map(img => ({
                id: img.id,
                name: img.originalFileName,
                url: img.imageURL,
                type: img.imageURL.endsWith('.pdf') ? 'pdf' : 'image',
                base64: "", // Set empty string for existing images
                isNew: false // Mark existing images
            }));
            // setConAttach(conAttach);
            if (conAttach.length > 0) {
                setFileOriginalName(conAttach[0].name);  // Use the first image's name
                setFileUrl(conAttach[0].url);            // Use the first image's URL
                setFileId(conAttach[0].id);              // Set the ID of the existing image
            }


        }
        // if (selectedValue) {
        //     setCheckboxClose(selectedValue?.biD_BIDraftIssueClosedDate);

        //     const firstImage = selectedValue?.biDraftIssuedImagesList?.[0];
        //     if (firstImage?.statusId) {
        //         setStatusId(firstImage.statusId);
        //     }
        //     setCheckboxBid(selectedValue?.biD_IsConfirmed || false);
        // }

        if (selectedValue) {
            // Set BL Draft Issue flag based on selected value
            setCheckboxClose(selectedValue?.biD_BIDraftIssueClosedDate);

            // Extract statusId from selectedValue
            const firstImage = selectedValue?.biDraftIssuedImagesList?.[0];
            // setSelectedLogList(firstImage)
            if (firstImage?.statusId) {
                setStatusId(firstImage.statusId);

                // Allow "BL Draft Issued" flag only if statusId indicates "Like"
                if (firstImage.statusId === 2) {
                    setCheckboxBid(true); // Status is "Like"
                } else {
                    setCheckboxBid(false); // Status is "Dislike" or other
                    setCheckboxClose(false); // Ensure "BL Draft Issued" is reset
                }
            } else {
                setStatusId(0); // No statusId present
                setCheckboxBid(false); // Reset to default state
                setCheckboxClose(false); // Disable "BL Draft Issued"
            }

            // const latestRemark = firstImage?.biDraftIssuedLogList
            //     ?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) // Sort by createdDate descending
            //     ?.find(log => log.remarks); // Find the first log with a non-empty remark

            // if (latestRemark) {
            //     setRemarks(latestRemark.remarks); // Set the latest remark
            // } else {
            //     setRemarks(""); // Reset if no remarks are found
            // }
        }
    }, [selectedValue]);

    const getMaxUploadsForRecord = (existingImagesCount, newImagesCount) => {
        return Math.max(0, 10 - existingImagesCount - newImagesCount);
    };

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleFileChange11 = async (e) => {
        const files = Array.from(e.target.files);
        const newFiles = files.slice(0, getMaxUploadsForRecord(conAttach.length, 0));
        const updatedConAttach = [...conAttach];

        for (const file of newFiles) {
            const base64 = await fileToBase64(file);
            updatedConAttach.push({
                name: file.name,
                url: URL.createObjectURL(file),
                base64: base64.split(',')[1], // Store the Base64 string
                type: file.type.includes('pdf') ? 'pdf' : 'image',
                isNew: true // Mark as a new file
            });
        }

        setConAttach(updatedConAttach);
    };

    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.href = file.url;
        link.download = file.name || 'download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleEnlarge = (file) => {
        if (file && file.url) {
            window.open(file.url, "_blank");
        } else {
            console.error("File URL is missing or invalid.");
        }
        // setModalFile(file);
        // setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalFile(null);
    };

    useEffect(() => {
        // Create a new date object in UTC and convert it to IST
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000);

        // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
        const formattedDate = istDate.toISOString().slice(0, 16);
        setOrderData(formattedDate);
    }, []);

    const handleStatusChange = (newStatusId) => {
        setStatusId(newStatusId);
        if (newStatusId === 2) {
            // "Like" clicked
            setCheckboxBid(true); // Set Status flag
        } else if (newStatusId === 3) {
            // "Dislike" clicked
            setCheckboxBid(false); // Auto unset Status flag
            setCheckboxClose(false); // Disable BL Draft checkbox
        }
    };

    const handleFileChange = async (file) => {
        if (file) {
            // setFileId(0);  // If a new file is uploaded, set ID to 0
            setProfileImage(file);  // Set the new file
        }
    };


    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {

            console.log("Selected Value ID:", selectedValue.id);
            console.log("Existing Attachments (conAttach):", conAttach);
            console.log("New Attachments (conAttachNew):", conAttachNew);

            // const biDraftIssuedImagesList = [{
            //     id: fileId || 0,  // If there is an existing image, use its ID, else pass 0
            //     imageName: profileImage.name || "", // Image name
            //     originalFileName: profileImage.name || "", // Original file name
            //     image_Base64: profileImage.base64 || "",  // Base64 string (if new file)
            //     remark: remarks || "",
            //     statusId: (profileImage.base64 || remarks) ? 1 : statusId  // Set to 1 if base64 or remarks exist, else use statusId
            // }];

            const biDraftIssuedImagesList = (profileImage.base64 || remarks || statusId)
                ? [{
                    id: fileId || 0,  // If there is an existing image, use its ID, else pass 0
                    imageName: profileImage.name || "", // Image name
                    originalFileName: profileImage.name || "", // Original file name
                    image_Base64: profileImage.base64 || "",  // Base64 string (if new file)
                    remark: remarks || "",
                    statusId: (profileImage.base64 || remarks) ? 1 : statusId  // Set to 1 if base64 or remarks exist, else use statusId
                }]
                : [];  // Empty array if no base64, remarks, or statusId is provided       

            console.log("biDraftIssuedImagesList before submission:", biDraftIssuedImagesList);

            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                biD_BIDraftRemark: remarksAdd || newRemarks.map(remark => remark.remarks).join("\n"),  // Use the remarks passed from the child
                biD_BIDraftIssueClosedDate: orderDate || null,
                biD_IsBIDraftIssueClose: checkboxClose || false,
                biD_IsConfirmed: statusId === 3 ? false : checkboxBid, // If Disliked, always pass false
                biDraftIssuedImagesList
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);

                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteRecordImage = async (index, id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeleteBIDraftIssuedImages?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                const updatedConAttach = conAttach.filter((_, i) => i !== index);
                setConAttach(updatedConAttach);

                toast.success('Record deleted successfully');
            } else {
                console.error('Failed to delete the record:', response.statusText);
                toast.error('Failed to delete the record');
            }
        } catch (error) {
            console.error('Error deleting the record:', error);
            toast.error('Error deleting the record');
        }
    };

    const handleVisibleOpen = () => {
        // Access the first item's log list inside biDraftIssuedImagesList
        const selectedLogs = selectedValue?.biDraftIssuedImagesList?.[0]?.biDraftIssuedLogList || [];
        setSelectedLogList(selectedLogs);
        setVisiblePopup(true);
    };


    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit BL Draft Issued</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion title={"PO recieved"}
                        isOpen={openAccordionIndex.includes(0)}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Issued"}
                        isOpen={openAccordionIndex.includes(1)}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Confirmation"}
                        isOpen={openAccordionIndex.includes(2)}
                        onClick={() => handleAccordionClick(2)}
                    >
                        <ViewPIConfirmedbyCustomer
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Advance Payment / LC"}
                        isOpen={openAccordionIndex.includes(3)}
                        onClick={() => handleAccordionClick(3)}
                    >
                        <ViewPaymentLCReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Accepted"}
                        isOpen={openAccordionIndex.includes(4)}
                        onClick={() => handleAccordionClick(4)}
                    >
                        <ViewOrderAccepted
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Under Process"}
                        isOpen={openAccordionIndex.includes(5)}
                        onClick={() => handleAccordionClick(5)}
                    >
                        <ViewOrderUnderProcess
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Booking Issued"}
                        isOpen={openAccordionIndex.includes(6)}
                        onClick={() => handleAccordionClick(6)}
                    >
                        <ViewBookingIssue
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Containers Under Loading"}
                        isOpen={openAccordionIndex.includes(7)}
                        onClick={() => handleAccordionClick(7)}
                    >
                        <ViewContainersUnderLoading
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Invoice Generated"}
                        isOpen={openAccordionIndex.includes(8)}
                        onClick={() => handleAccordionClick(8)}
                    >
                        <ViewInvoiceGenerated
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"BL Draft Issued"}
                        isOpen={openAccordionIndex.includes(9)}
                        onClick={() => handleAccordionClick(9)}
                    >
                        {/* <EditBiDraftIssued /> */}
                        <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="date" className="text-xs font-[400] mb-2">
                                        BL Draft Issued Date
                                    </label>
                                    <input
                                        type="datetime-local"
                                        required
                                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                        value={orderDate}
                                        onChange={(e) => setOrderData(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <CustomFileUpload
                                    id="pi-upload-add-v1"
                                    label=" BL Draft Issued Upload"
                                    isRequired={false}
                                    value={profileImage}
                                    onChange={handleFileChange}  // Pass the selected file here
                                    initialFileName={fileOriginalName}
                                    initialFileUrl={fileUrl}
                                />
                            </div>
                            <div className="flex gap-5 items-center">
                                <CustomCheckBox2
                                    label={"Status"}
                                    state={checkboxBid}
                                    setState={setCheckboxBid}
                                    disabled={true}
                                ></CustomCheckBox2>
                                <div className="mt-5">
                                    {statusId === 2 ? (
                                        <Tooltip title="ACCEPT" arrow placement="bottom">
                                            <span>
                                                <IoCheckmarkCircle className="h-9 w-9 text-green-500" />
                                            </span>
                                        </Tooltip>
                                    ) : statusId === 3 ? (
                                        <Tooltip title="REJECT" arrow placement="bottom">
                                            <span>
                                                <IoRemoveCircle className="h-10 w-9 text-red-500" />
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <p className="text-xs border border-yellow-500 modal-btn-comman text-yellow-500">Pending</p>
                                    )}

                                </div>
                                <Tooltip title="ACCEPT" arrow placement="bottom">
                                    <span>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            className={`bg-green-500 text-white grow max-w-[30px] mt-5 flex gap-2 justify-center items-center relative uppercase
                                              
                                                `}
                                            onClick={() => handleStatusChange(2)}
                                        >
                                            <BiLike />
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip title="REJECT" arrow placement="bottom">
                                    <span>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            className={` text-white grow bg-red-500 max-w-[30px] mt-5 flex gap-2 justify-center items-center relative uppercase
                                                
                                                `}
                                            onClick={() => handleStatusChange(3)}
                                        >
                                            <BiDislike />
                                        </Button>
                                    </span>
                                </Tooltip>

                                {/* </>
                                )} */}
                            </div>
                            <div className="flex gap-2 w-full">
                                <div className="w-[80%]">
                                    <CustomTextArea
                                        label={"Remark"}
                                        placeholder={"ENTER"}
                                        value={remarks}
                                        onChange={setRemarks}
                                        isRequired={false}
                                    ></CustomTextArea>
                                </div>
                                <div className="w-[20%] flex justify-center">
                                    <Tooltip title="VIEW REMARK HISTORY" arrow placement="bottom">
                                        <span>
                                            <button onClick={handleVisibleOpen} className="">
                                                <IoMdEye className="h-10 w-9 txt-prp-color mt-5" />
                                            </button>
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>

                            <div className="flex w-full ">
                                <Button
                                    variant={"contained"}
                                    size={"small"}
                                    className={` flex mt-5 w-[50%] gap-2 justify-center items-center relative uppercase`}
                                    type={"button"}
                                    style={{ backgroundColor: "#643c94" }}
                                    onClick={() => setPopupRemarkOpen(true)}
                                >
                                    <span className="whitespace-nowrap font-[400] font-Mitr text-white">
                                        Remarks
                                    </span>
                                </Button>
                            </div>
                            <div className="flex justify-between">
                                <CustomCheckBox2
                                    label={" BL Draft Issued"}
                                    state={checkboxClose}
                                    setState={handleCheckboxChange}
                                    disabled={!checkboxBid} // Disable when Status flag is not set
                                ></CustomCheckBox2>
                                {checkboxClose && (
                                    <CustomViewLabel
                                        label="BL Draft Issued Date & Time"
                                        value={endFormatDate(endCloseDateTime)}
                                    />
                                )}
                            </div>

                        </div>
                    </Accordion>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Enlarge File"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <div className="coman-modal-flex">
                    {modalFile && (
                        <div className="coman-modal-text">
                            <p>{modalFile.name}</p>
                        </div>
                    )}
                    <div className="coman-modal">
                        {modalFile && (
                            <>
                                <button onClick={() => handleDownload(modalFile)} className="modal-btn-comman modal-btn-dwl">
                                    <FaDownload />
                                </button>
                                <button onClick={closeModal} className="modal-btn-comman modal-btn-cnl">
                                    <MdCancel />
                                </button>
                            </>
                        )}
                    </div>
                </div>
                {modalFile && (
                    modalFile.type === 'pdf' ? (
                        <embed
                            src={modalFile.url}
                            type="application/pdf"
                            className="w-full h-[600px]" // Adjust height as needed
                        />
                    ) : (
                        <img
                            src={modalFile.url || `data:image/png;base64,${modalFile.base64}`}
                            alt="Enlarged"
                            className="w-full h-auto"
                        />
                    )
                )}
            </Modal>

            {popupRemarkOpen && (
                <PopUp>
                    <Remarks
                        selectedValue={selectedValue}
                        remarksAdd={remarksAdd}
                        setRemarksAdd={setRemarksAdd}
                        onCancel={() => setPopupRemarkOpen(false)}
                        onOk={(remarks) => {
                            setPopupRemarkOpen(false);
                            setNewRemarks(remarks);  // Store the remarks received from the child
                        }}
                    />
                </PopUp>
            )}

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}

            {visiblePopup && (
                <PopUp>
                    <PopupRemarks
                        piIssuedLogList={selectedLogList}
                        onCancel={() => setVisiblePopup(false)}
                    />
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
}
