// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import Accordion from "../../general-components/Accodion";
import EditOrderUnderProcess from "./EditOrderUnderProcess";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ViewPaymentLCReceived from "../ManagePaymentReceived_LCReceived/ViewPaymentLCReceived";
import ViewOrderAccepted from "../ManageOrderAccepted/ViewOrderAccepted";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import PopUp from "../../general-components/PopUp.componenet";

export default function ManageOrderUnderProcessEdit({ onCancel, selectedValue }) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState([5]);
    const [orderDate, setOrderDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [orderUnderProcessClose, setOrderUnderProcessClose] = useState(false)
    const [updatePopup, setUpdatePopup] = useState(false);
    const [endCloseDateTime, setEndCloseDateTime] = useState(null);
    const [shipDate, setShipDate] = useState("");

    const endFormatDate = (date) =>
        date
            ? new Date(date).toLocaleString('en-IN', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, // 12-hour format
            })
            : 'N/A';

    const handleCheckboxChange = (state) => {
        setOrderUnderProcessClose(state);

        // Set the time every time the checkbox is flagged (true)
        if (state) {
            const timestamp = new Date().toISOString();
            setEndCloseDateTime(timestamp); // update time on each flag
        }
    };

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((i) => i !== index) // Close if already open
                : [...prevIndexes, index] // Add to open accordions
        );
    };

    useEffect(() => {
        // Set default date to current date
        const currentDate = new Date().toISOString().split("T")[0];
        setOrderDate(currentDate);
    }, []);

    useEffect(() => {
        if (selectedValue) {
            setOrderUnderProcessClose(selectedValue?.ouP_IsOrderUnderProcess);
            setShipDate(selectedValue.ouP_OrderUnderProcessDate)
        }
    }, [selectedValue]);

    const token = localStorage.getItem('DIYAN_IE_authToken');

    useEffect(() => {
        // Create a new date object in UTC and convert it to IST
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000);

        // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
        const formattedDate = istDate.toISOString().slice(0, 16);
        setOrderDate(formattedDate);
        setShipDate(formattedDate);
    }, []);

    const setDefaultDate = () => {
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000);

        // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
        return istDate.toISOString().slice(0, 16);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const effectiveDate = shipDate || setDefaultDate();

            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                ouP_IsOrderUnderProcess: orderUnderProcessClose || false,
                ouP_OrderUnderProcessDate: effectiveDate,
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);
                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit Order Under Process</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion title={"PO recieved"}
                        isOpen={openAccordionIndex.includes(0)}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Issued"}
                        isOpen={openAccordionIndex.includes(1)}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Confirmation"}
                        isOpen={openAccordionIndex.includes(2)}
                        onClick={() => handleAccordionClick(2)}
                    >
                        <ViewPIConfirmedbyCustomer
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Advance Payment / LC"}
                        isOpen={openAccordionIndex.includes(3)}
                        onClick={() => handleAccordionClick(3)}
                    >
                        <ViewPaymentLCReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Accepted"}
                        isOpen={openAccordionIndex.includes(4)}
                        onClick={() => handleAccordionClick(4)}
                    >
                        <ViewOrderAccepted
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Under Process"}
                        isOpen={openAccordionIndex.includes(5)}
                        onClick={() => handleAccordionClick(5)}
                    >
                        {/* <EditOrderUnderProcess /> */}
                        <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="date" className="text-xs font-[400]">
                                        Shipment Schedule
                                    </label>
                                    <input
                                        type="datetime-local"
                                        required
                                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                        value={shipDate}
                                        onChange={(e) => setShipDate(e.target.value || "")}
                                        onBlur={() => {
                                            if (!shipDate) setShipDate(setDefaultDate());
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="">
                                <CustomCheckBox2
                                    label={"Order Under Process Close"}
                                    state={orderUnderProcessClose}
                                    setState={handleCheckboxChange}
                                ></CustomCheckBox2>
                            </div>
                            {orderUnderProcessClose && (
                                <CustomViewLabel
                                    label="Order Under Process Close Date & Time"
                                    value={endFormatDate(endCloseDateTime)}
                                />
                            )}
                        </div>
                    </Accordion>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={1000} />

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}
        </>
    )
}
