import React, { useState, useEffect } from 'react';
import { BsCheck } from "react-icons/bs";
import Select from 'react-select';
import axios from 'axios';

import CustomAlphatext from '../../general-components/CustomAlphatext.component';
import CustomSelect1 from '../../general-components/CustomSelect1.component';
import CustomDate from '../../general-components/CustomDate.component';
import CustomInputNumber from '../../general-components/CustomInputNumber.component';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton1 from '../../general-components/CustomButton1.component';
import CustomButton2 from '../../general-components/CustomButton2.component';
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomImagePdfMultiple from '../../general-components/CustomImagePdfMultiple.component';
import { FaDownload } from 'react-icons/fa';
import { ImEnlarge } from 'react-icons/im';
import { MdCancel } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import CustomInputNumberDecimal from '../../general-components/CustomInputNumberDecimal.component';


export default function PaymentReceivedEditInEdit({ onCancel, record, selectedValue }) {
    const [iNo, setINo] = useState("");
    const [amt, setAmt] = useState("");
    const [date, setDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paymentTermsData, setPaymentsTermsData] = useState([]);
    const [currencyTypeData, setCurrencyTypeData] = useState([]);
    const [paymentRecivedData, setPaymentRecivedData] = useState([]);
    const [paymentTermsId, setPaymentsTermsId] = useState("");
    const [currencyTypeId, setCurrencyTypeId] = useState("");
    const [paymentRecivedId, setPaymentRecivedId] = useState("");

    const [paymentTermsName, setPaymentsTermsName] = useState("");
    const [currencyTypeName, setCurrencyTypeName] = useState("");
    const [paymentRecivedName, setPaymentRecivedName] = useState("");
    const [poAmt, setPoAmt] = useState("")
    const [remainAmt, setRemainAmt] = useState("")
    const [paymentTermsId1, setPaymentsTermsId1] = useState("");
    const [paymentTermsName1, setPaymentsTermsName1] = useState("");
    const [currencyTypeId1, setCurrencyTypeId1] = useState("");
    const [currencyTypeName1, setCurrencyTypeName1] = useState("");
    const [totalAmt1, setTotalAmt1] = useState("");
    const [bankRefNo, setBankRefNo] = useState("");
    const [conAttach, setConAttach] = useState([]);
    const [fileError, setFileError] = useState(""); // State to store the file input error

    const [date1Ig, setDate1Ig] = useState("");
    const [piOptionsPy, setPiOptionsPy] = useState([]);
    const [poAmt1Py, setPoAmt1Py] = useState("");
    const [selectedPiNumberPy, setSelectedPiNumberPy] = useState(null);
    const [disPi, setDisPi] = useState('');
    const [piIssuedDatePy, setPiIssuedDatePy] = useState("");

    const handleConAttachChange = (files) => {
        setConAttach(files);
    };

    const dataActive = { searchText: "", isActive: true };

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentTermsList`, setPaymentsTermsData, "Error fetching Payment Terms Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCurrencyTypeList`, setCurrencyTypeData, "Error fetching Currency Type Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentReceivedList`, setPaymentRecivedData, "Error fetching Payment Received Data");
    }, []);

    useEffect(() => {
        if (record) {
            setINo(record.invoiceNumber || "");
            setAmt(record.amount || 0);
            // setDate(record.paymentReceivedDate ? record.paymentReceivedDate.split('T')[0] : "");
            setPaymentsTermsId(record.paymentTermsId || "");
            setPaymentsTermsName(record.paymentTerms || "");
            setCurrencyTypeId(record.currencyTypeId || "");
            setCurrencyTypeName(record.currencyType || "");
            setPaymentRecivedId(record.paymentReceivedId || "");
            setPaymentRecivedName(record.paymentReceived || "");
            setPoAmt(record.poAmount)
            // setRemainAmt(record.remainingAmount)
            // setTotalAmt1(record.totalReceivedAmount)
            setRemainAmt(record.remainingAmount || poAmt - amt);
            setTotalAmt1(record.totalReceivedAmount || amt);

            setBankRefNo(record.bankReferenceNumber || "");
            setConAttach(record.purchaseOrderPaymentReceivedImagesList || []);
            setDisPi(record.piNumber || "");

            if (record && record.paymentReceivedDate) {
                setDate1Ig(record.paymentReceivedDate.split('T')[0]); // Use record date if available
            } else {
                setDefaultDate();
            }

            if (record && record.invoiceGenerateDate) {
                setDate(record.invoiceGenerateDate.split('T')[0]); // Use record date if available
            } else {
                setDefaultDate();
            }
        }
    }, [record]);

    const setDefaultDate = () => {
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000);

        // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
        return istDate.toISOString().slice(0, 16);
    };

    useEffect(() => {
        if (selectedValue) {
            setCurrencyTypeId1(selectedValue.pO_CurrencyTypeId);
            setCurrencyTypeName1(selectedValue.pO_CurrencyType);
            setPaymentsTermsId1(selectedValue.pO_PaymentTermsId);
            setPaymentsTermsName1(selectedValue.pO_PaymentTerms);
        }
    }, [selectedValue])

    useEffect(() => {
        if (selectedValue) {
            if (selectedValue?.piIssuedList) {
                // Map piIssuedList to match React-Select's options format
                const options = selectedValue.piIssuedList.map(pi => ({
                    value: pi.id,
                    label: pi.piNumber,
                    piIssueDate: pi.piIssueDate, // Include issue date for easy access
                }));
                setPiOptionsPy(options);

                // Set default selected value if available
                const defaultPi = options.find(option => option.label === disPi) || options[0];
                if (defaultPi) {
                    setSelectedPiNumberPy(defaultPi);
                    setPiIssuedDatePy(defaultPi.piIssueDate);
                }
            }
        }
    }, [selectedValue, disPi]);

    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handlePaymentReceivedChange = (value) => {
        if (value === "") {
            // Reset amt and recalculate amounts when cleared
            setAmt("");
            setTotalAmt1(record.totalReceivedAmount || 0); // Reset total received amount to the initial value
            setRemainAmt(poAmt - (record.totalReceivedAmount || 0)); // Recalculate remaining amount
            return;
        }
    
        const newReceivedAmt = parseFloat(value) || 0; // Parse input value to ensure it's a number
        const updatedTotalAmt = (record.totalReceivedAmount || 0) + newReceivedAmt - (record.amount || 0); // Adjust for the difference between current and previous values
        const updatedRemainAmt = poAmt - updatedTotalAmt; // Calculate remaining amount based on PO amount
    
        // Update states
        setAmt(newReceivedAmt); // Update the new payment received
        setTotalAmt1(updatedTotalAmt); // Update the total received amount
        setRemainAmt(updatedRemainAmt); // Update the remaining amount
    };  

    const [invoiceError, setInvoiceError] = useState("");
    const [paymentTypeError, setPaymentTypeError] = useState("");

    const handleChange = (event) => {
        const newValue = event.target.value.toUpperCase();

        // Check if the value contains only alphanumeric characters
        if (/^[a-zA-Z0-9]*$/.test(newValue)) {
            setInvoiceError('');
            setINo(newValue);
        } else {
            setInvoiceError('Only alphanumeric characters are allowed');
        }
    };

    useEffect(() => {
        // Create a new date object in UTC and convert it to IST
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000);

        // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
        const formattedDate = istDate.toISOString().slice(0, 16);
        setDate(formattedDate);
    }, []);

    useEffect(() => {
        // Create a new date object in UTC and convert it to IST
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000);

        // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
        const formattedDate = istDate.toISOString().slice(0, 16);
        setDate1Ig(formattedDate);
    }, []);


    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);
        const updatedAttachments = [...conAttach];
        setFileError(""); // Reset errors

        for (const file of files) {
            const isAllowedType = allowedTypes.includes(file.type);
            if (!isAllowedType) {
                setFileError(`File type not allowed: ${file.name}`);
                continue;
            }
            const base64 = await fileToBase64(file);
            updatedAttachments.push({
                name: file.name,
                url: URL.createObjectURL(file),
                base64: base64.split(',')[1],
                isNew: true, // Mark as a new file
            });
        }

        setConAttach(updatedAttachments); // Update state with new files
    };

    const handleDownload = (file) => {
        const link = document.createElement('a');
        let downloadUrl = file.isNew ? file.url : file.imageURL; // Use 'imageURL' for existing files

        // Handle base64 files for new uploads
        if (!downloadUrl && file.base64) {
            const byteCharacters = atob(file.base64);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                const slice = byteCharacters.slice(offset, offset + 1024);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                byteArrays.push(new Uint8Array(byteNumbers));
            }
            const blob = new Blob(byteArrays, { type: file.type || 'application/octet-stream' });
            downloadUrl = URL.createObjectURL(blob);
        }

        // Ensure the correct download action
        link.href = downloadUrl;
        link.download = file.name || 'download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log("Downloading file:", file);
    };

    const handleEnlarge = (file) => {
        // Use 'url' for new files and 'imageURL' for existing files
        const fileUrl = file.isNew ? file.url : file.imageURL; // Adjust this based on your data structure
        window.open(fileUrl, '_blank'); // Opens in a new tab
        console.log("Enlarging file:", file);
    };

    const handlePiNumber = (option) => {
        if (option) {
            setSelectedPiNumberPy(option);
            setPiIssuedDatePy(option.piIssueDate); // Update issue date based on the selected PI number
        } else {
            setSelectedPiNumberPy(null);
            setPiIssuedDatePy(""); // Clear the issue date if no PI number is selected
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Reset errors
        setInvoiceError("");
        setPaymentTypeError("");

        // Validate fields
        let hasError = false;
        // if (!iNo) {
        //     setInvoiceError("Invoice Number is required");
        //     hasError = true;
        // }
        if (!paymentRecivedId) {
            setPaymentTypeError("Payment Type is required");
            hasError = true;
        }

        if (hasError) {
            return; // Prevent submission if there are errors
        }
        setIsLoading(true);

        try {

            const effectiveDate = date || setDefaultDate();
            const effectiveDate2 = date1Ig || setDefaultDate();
            const effectiveDate3 = piIssuedDatePy || setDefaultDate();

            const updatedFiles = conAttach.map((file) => {
                if (!file.isNew) {
                    // Existing file: Include only the id, leave other fields empty
                    return {
                        id: file.id || file.imageId, // Use the correct key for the file ID in your data
                        imageFileName: "",
                        imageOriginalFileName: "",
                        image_Base64: "",
                    };
                } else {
                    // New file: Include base64, file name, and id as 0
                    return {
                        id: 0,
                        imageFileName: file.name,
                        imageOriginalFileName: file.name,
                        image_Base64: file.base64,
                    };
                }
            });

            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                paymentReceived_Or_LCReceivedDetails: {
                    paymentOrLCReceived: 1,
                    paymentOrLCClosed: selectedValue.plR_IsPaymentOrLCClosed,
                    paymentReceivedDetail: [
                        {
                            id: record.id,
                            invoiceNumber: iNo || "",
                            paymentTermsId: paymentTermsId1 || 0,
                            paymentReceivedDate: effectiveDate,
                            currencyTypeId: currencyTypeId1 || 0,
                            amount: amt || 0,
                            paymentReceivedId: paymentRecivedId || 0,
                            poAmount: poAmt || 0,
                            remainingAmount: remainAmt || 0,
                            totalReceivedAmount: totalAmt1 || 0,
                            bankReferenceNumber: bankRefNo || "",
                            invoiceGenerateDate: effectiveDate2,
                            piNumber: selectedPiNumberPy.label || "",
                            piIssuedDate: effectiveDate3,
                            purchaseOrderPaymentReceivedImagesList: updatedFiles
                        },
                    ],
                    lcReceivedDetail: []
                }
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                onCancel();
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePaymentRecChange = (selectedOption) => {
        if (selectedOption) {
            setPaymentRecivedId(selectedOption.value);
            setPaymentRecivedName(selectedOption.label); // Update the department name label
        } else {
            setPaymentRecivedId(null); // Reset to null when cleared
            setPaymentRecivedName(''); // Clear the department name label
        }
    }

    const handleCancel = (imgIndex) => {
        const updatedAttachments = conAttach.filter((_, index) => index !== imgIndex);
        setConAttach(updatedAttachments);
    };

    const handleDeleteRecordImage = async (imgIndex, id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeletePurchaseOrderPaymentReceivedImages?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                const updatedAttachments = conAttach.filter((_, index) => index !== imgIndex);
                setConAttach(updatedAttachments);
                toast.success('Record deleted successfully');
            } else {
                toast.error('Failed to delete the record');
            }
        } catch (error) {
            console.error('Error deleting the record:', error);
            toast.error('Error deleting the record');
        }
    };

    // Calculate total attachments (existing + new)
    const totalAttachments = conAttach.length;

    return (
        <>
            <div className="pb-5 bg-white rounded-lg max-w-full">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className="text-l font-weight-[400]">Payment Received Record Edit</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-2 mx-2">
                    <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[800px] border border-prp p-5 rounded-lg">
                        <div className="">
                            <div className="flex flex-col gap-1">
                                <label className="text-xs font-400">
                                    Currency Type
                                </label>
                                <Select
                                    className="text-black text-xs select-bar uppercase"
                                    id="id"
                                    value={{ value: currencyTypeId1, label: currencyTypeName1 }} // Set initial value here
                                    menuPosition="fixed"
                                    isDisabled={true}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div>
                            <CustomInputNumberDecimal
                                label={"PO Amount"}
                                placeholder={"ENTER"}
                                value={poAmt}
                                // onChange={setPoAmt}
                                readOnly
                                isRequired={false}
                            ></CustomInputNumberDecimal>
                        </div>
                        <div>
                            <div className="flex flex-col gap-1">
                                <label className="text-xs font-400">PI Number</label>
                                <Select
                                    options={piOptionsPy}
                                    className="text-black text-xs select-bar uppercase"
                                    value={selectedPiNumberPy} // Use selectedPiNumberPy for the selected value
                                    onChange={handlePiNumber} // Update selected value and issue date on change
                                    isClearable
                                    menuPosition="fixed"
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="date" className="text-xs font-[400]">
                                    Pi Issued Date
                                </label>
                                <input
                                    type="datetime-local"
                                    disabled
                                    className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                    value={piIssuedDatePy}
                                />
                            </div>
                        </div>
                        <div>
                            <CustomInputNumberDecimal
                                label={"Total Received Amount"}
                                placeholder={"ENTER"}
                                value={totalAmt1}
                                // onChange={setTotalAmt1}
                                readOnly
                                isRequired={false}
                            // readOnly
                            ></CustomInputNumberDecimal>
                        </div>
                        <div>
                            <CustomInputNumberDecimal
                                label={"Remaining Amount"}
                                placeholder={"ENTER"}
                                value={remainAmt}
                                onChange={setRemainAmt}
                                isRequired={false}
                                readOnly
                            ></CustomInputNumberDecimal>
                        </div>
                        <div>
                            <div className="flex flex-col gap-1">
                                <label className="text-xs font-400">
                                    Payment Terms
                                </label>
                                <Select
                                    className="text-black text-xs select-bar uppercase"
                                    id="id"
                                    value={{ value: paymentTermsId1, label: paymentTermsName1 }} // Set initial value here
                                    menuPosition="fixed"
                                    isDisabled={true}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        {/* <div className="">
                            <div className="flex flex-col gap-1">
                                <label htmlFor="date" className="text-xs font-[400]">
                                    Invoice Generated Date
                                </label>
                                <input
                                    type="datetime-local"
                                    // required
                                    className={`p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs text-black bg-white`}
                                    value={date1Ig}
                                    onChange={(e) => setDate1Ig(e.target.value) || ""}
                                    onBlur={() => {
                                        if (!date) setDate(setDefaultDate());
                                    }}
                                />
                            </div>
                        </div> */}
                        {/* <div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="id" className="text-xs font-[400]">
                                    Invoice Number<span className="text-red-500 gap-3">*</span>
                                </label>
                                <input
                                    type="text"
                                    required
                                    value={iNo}
                                    className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                    id="id"
                                    placeholder={"Enter"}
                                    onChange={handleChange}
                                />
                                {invoiceError && <span className="text-red-500 text-xs">{invoiceError}</span>}
                            </div>
                        </div> */}
                        <div className="">
                            <div className="flex flex-col gap-1">
                                <label htmlFor="id" className="text-xs font-[400]">
                                    Payment Received Date
                                </label>
                                <input
                                    type="datetime-local"
                                    className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                    id="id"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value) || ""}
                                    onBlur={() => {
                                        if (!date) setDate(setDefaultDate());
                                    }}
                                />
                            </div>
                        </div>
                        <div className="">
                            <div className="flex flex-col gap-1">
                                <label htmlFor="id" className="text-xs font-400 ">
                                    Payment Type
                                </label>
                                <Select
                                    options={paymentRecivedData.map(cData => ({
                                        value: cData.id,
                                        label: cData.paymentReceived
                                    }))}
                                    onChange={handlePaymentRecChange}
                                    className="text-black text-xs select-bar"
                                    id="departmentId"
                                    isSearchable
                                    isClearable
                                    value={{ value: paymentRecivedId, label: paymentRecivedName }}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                                {paymentTypeError && <span className="text-red-500 text-xs">{paymentTypeError}</span>}
                            </div>
                        </div>
                        <div className="">
                            <CustomInputNumberDecimal
                                label={"Payment Received"}
                                placeholder={"ENTER"}
                                value={amt}
                                onChange={handlePaymentReceivedChange}
                            />
                        </div>
                        <div>
                            <CustomTextField2 label="Bank Reference Number" placeholder={"ENTER"} value={bankRefNo} onChange={setBankRefNo} isRequired={false} />
                        </div>
                        <div>
                            {/* <CustomImagePdfMultiple
                                label="Attachment"
                                id="poissuede435345ed4565it-edit-0"
                                value={conAttach}
                                isRequired={false}
                                onChange={handleConAttachChange}
                            /> */}
                            <div className="flex flex-col ">
                                <label htmlFor="file-upload" className="text-xs font-[400] pb-2 uppercase">
                                    Attachment
                                </label>
                                <div>
                                    <label
                                        htmlFor="file-upload"
                                        className={`text-xs p-2 rounded-md  ${totalAttachments >= 10 ? 'bg-gray-300 text-gray-600 cursor-not-allowed' : 'bg-prp-color text-white cursor-pointer'
                                            }`}
                                    >
                                        Attach File
                                    </label>
                                    <input
                                        type="file"
                                        id="file-upload"
                                        className="hidden"
                                        onChange={handleFileChange}
                                        multiple
                                        accept=".jpeg,.jpg,.png,.pdf"
                                        disabled={totalAttachments >= 10}
                                    />
                                    {fileError && <div className="text-red-500 text-xs mt-1">{fileError}</div>}
                                </div>

                                {/* Display existing images and new image previews */}
                                <div className="flex flex-wrap mt-3">
                                    {conAttach.length > 0 ? (
                                        conAttach.map((img, imgIndex) => (
                                            <div key={imgIndex} className="relative flex items-center space-x-2 mt-2">
                                                {/* Image action buttons */}
                                                <button onClick={() => handleDownload(img)} className="bg-prp-color text-white p-2 rounded">
                                                    <FaDownload />
                                                </button>

                                                {/* Delete image */}
                                                <button
                                                    onClick={() => img.isNew ? handleCancel(imgIndex) : handleDeleteRecordImage(imgIndex, img.id)}
                                                    className={`p-2 bg-white ${img.isNew ? 'border-prp  txt-prp-color' : 'border-red-500'} border rounded`}
                                                >
                                                    {img.isNew ? <MdCancel className="text-prp-color" /> : <RiDeleteBin6Line className="text-red-500" />}
                                                </button>

                                                <button onClick={() => handleEnlarge(img)} className="bg-prp-color text-white p-2 rounded">
                                                    <ImEnlarge />
                                                </button>


                                                {/* Handle image preview */}
                                                {img.isNew ? (
                                                    <img src={img.url} alt={img.name} className="w-10 h-10 object-cover rounded-full border border-[#3D3D3D66] " />
                                                ) : (
                                                    <img src={img.imageURL} alt={img.imageOriginalFileName} className="w-10 h-10 object-cover rounded-full border border-[#3D3D3D66]" />
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <div className="text-gray-500">No images available</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center gap-5 mt-3">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    );
}
