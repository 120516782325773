// CORE
import * as React from "react";
import { useState } from "react";
import axios from "axios";
import { Tooltip } from "@mui/material";
import { useEffect } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import Accordion from "../../general-components/Accodion";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ViewPaymentLCReceived from "../ManagePaymentReceived_LCReceived/ViewPaymentLCReceived";
import ViewOrderAccepted from "../ManageOrderAccepted/ViewOrderAccepted";
import ViewOrderUnderProcess from "../ManageOrderUnderProcess/ViewOrderUnderProcess";
import ViewBookingIssue from "../ManageBookingIssue/ViewBookingIssue";
import ViewContainersUnderLoading from "../ManageContainersUnderLoading/ViewContainersUnderLoading";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomFileUpload from "../../general-components/CustomFileUpload.component";
import PopUp from "../../general-components/PopUp.componenet";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import { BsPlusSquareFill } from "react-icons/bs";
import { CiSquareRemove } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomInputNumber from "../../general-components/CustomInputNumber.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";
import SubAccodion from "../../general-components/SubAccodion";
import CustomInputNumberDecimal from "../../general-components/CustomInputNumberDecimal.component";
import CustomInputNumberDecimalQty from "../../general-components/CustomInputNumberDecimalQty.component";

export default function ManageInvoiceGeneratedEdit({ onCancel, selectedValue }) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState([8]);
    const [cuLoadClose, setCuLoadClose] = useState(false)
    const [orderDate, setOrderDate] = useState("");
    const [updatePopup, setUpdatePopup] = useState(false);
    const [errorMessages, setErrorMessages] = useState({ main: '', rows: [] });
    const [endCloseDateTime, setEndCloseDateTime] = useState(null);
    const [imageError, setImageError] = useState({});
    const [qty, setQty] = useState("");

    const endFormatDate = (date) =>
        date
            ? new Date(date).toLocaleString('en-IN', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, // 12-hour format
            })
            : 'N/A';

    const handleCheckboxChange = (state) => {
        setCuLoadClose(state);

        // Set the time every time the checkbox is flagged (true)
        if (state) {
            const timestamp = new Date().toISOString();
            setEndCloseDateTime(timestamp); // update time on each flag
        }
    };

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((i) => i !== index) // Close if already open
                : [...prevIndexes, index] // Add to open accordions
        );
    };

    const [rows, setRows] = useState([]);
    const [conName, setConName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [mainConAttach, setMainConAttach] = useState("");
    const [invoiceAmt, setInvoiceAmt] = useState("");
    const [mainConAttachName, setMainConAttachName] = useState("");
    const [mainConAttachUrl, setMainConAttachUrl] = useState("");
    const [id, setId] = useState(0);

    const handleMainConAttachChange = (files) => {
        setMainConAttach(files);
        setErrorMessages((prev) => ({ ...prev, mainAttachment: "" })); // Clear the main attachment error
    };

    useEffect(() => {
        if (selectedValue && selectedValue.invoiceList) {
            const initialRow = selectedValue.invoiceList[0];
            if (initialRow) {
                setRows(selectedValue.invoiceList.map(invoice => ({
                    id: invoice.id,
                    conName: invoice.invoiceNumber,
                    orderDate: invoice.invoiceGeneratedDate,
                    invoiceAmt: invoice.invoiceAmount,
                    qty: invoice.quantity,
                    conAttach: {
                        name: invoice.invoiceOriginalFileName,
                        base64: "",
                        imageURL: invoice.invoiceImageURL,
                    }
                })));
                setId(initialRow.id);
                setOrderDate(initialRow.invoiceGeneratedDate || getCurrentISTDate());
                setConName(initialRow.invoiceNumber);
                setInvoiceAmt(initialRow.invoiceAmount);
                setQty(initialRow.quantity || selectedValue.pO_Quantity); // Default to pO_Quantity if qty is empty
                setMainConAttachName(initialRow.invoiceOriginalFileName);
                setMainConAttachUrl(initialRow.invoiceImageURL);
            } else {
                setQty(selectedValue.pO_Quantity || ""); // Set pO_Quantity if no rows
            }
        }

        if (selectedValue) {
            setCuLoadClose(selectedValue?.iN_IsInvoiceGenerateClose);
        }
    }, [selectedValue]);

    const getCurrentISTDate = () => {
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000);
        return istDate.toISOString().slice(0, 16); // Format to 'YYYY-MM-DDTHH:mm'
    };

    const handleAddRow = () => {
        const newRow = { id: 0, conName: '', conAttach: '', invoiceAmt: '', orderDate: getCurrentISTDate(), qty: '' };
        setRows([...rows, newRow]);
        setErrorMessages(prev => ({ ...prev, rows: [...prev.rows, {}] }));
    };

    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((row, rowIndex) => rowIndex !== index);
        setRows(updatedRows);
    }

    const handleRowChange = (index, field, value) => {
        const updatedRows = rows.map((row, rowIndex) =>
            rowIndex === index ? { ...row, [field]: value } : row
        );
        setRows(updatedRows);

        // Clear error message for this row's field if input is valid
        const updatedErrors = errorMessages.rows.map((error, rowIndex) => {
            if (rowIndex === index) {
                return { ...error, [field]: value?.trim() ? '' : error[field] };
            }
            return error;
        });
        setErrorMessages(prev => ({ ...prev, rows: updatedErrors }));
    };

    useEffect(() => {
        // Create a new date object in UTC and convert it to IST
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000);

        // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
        const formattedDate = istDate.toISOString().slice(0, 16);
        setOrderDate(formattedDate);
    }, []);

    const handleChange = (event) => {
        const value = event.target.value.toUpperCase();

        if (/^[a-zA-Z0-9/-]*$/.test(value)) {
            setErrorMessages(prev => ({ ...prev, main: '' })); // Clear error for main input
            setConName(value);
        } else {
            setErrorMessages(prev => ({ ...prev, main: 'Only text, digits, slashes, and hyphens are allowed' }));
        }
    };

    const handleConAttachChange = (index, files) => {
        console.log("Files received in handleConAttachChange:", files);
        setRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].conAttach = files;
            console.log("Updated rows in handleConAttachChange:", updatedRows);
            return updatedRows;
        });

        setErrorMessages((prev) => {
            const updatedRowsErrors = [...(prev.rows || [])];
            updatedRowsErrors[index] = { ...updatedRowsErrors[index], conAttach: "" }; // Clear the row's attachment error
            return { ...prev, rows: updatedRowsErrors };
        });
    };


    const token = localStorage.getItem('DIYAN_IE_authToken');
    const handleSubmit = async (e) => {
        e.preventDefault();
    setIsLoading(true);

    let hasError = false;
    let tempErrors = {};

    // Validate Invoice Number
    if (!conName.trim()) {
        tempErrors.main = "Invoice Number is required";
        hasError = true;
    } else {
        tempErrors.main = ""; // Clear invoice number error
    }

    // Validate Attachment
    if (!mainConAttach && !mainConAttachName) {
        tempErrors.mainAttachment = "Attachment is required";
        hasError = true;
    } else {
        tempErrors.mainAttachment = ""; // Clear attachment error
    }

    // Set all error messages at once
    setErrorMessages((prev) => ({ ...prev, ...tempErrors }));

    // Stop here if there are validation errors
    if (hasError) {
        setIsLoading(false);
        return;
    }

        try {
            const currentISTDate = getCurrentISTDate();

            const filteredRows = rows.filter(row => row.conName || row.conAttach);


            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                biD_BIDraftComment: "",
                invoiceList: [
                    {
                        id: id || 0,
                        invoiceGeneratedDate: orderDate || currentISTDate,
                        invoiceNumber: conName || 0,
                        invoiceAmount: invoiceAmt || 0,
                        quantity: parseFloat(qty) || 0,
                        invoiceImage: "",
                        invoiceOriginalFileName: mainConAttach?.name || "",
                        invoice_Base64: mainConAttach?.base64 || "",
                    },
                ],
                iN_IsInvoiceGenerateClose: cuLoadClose,
                iN_InvoiceGenerateClosedDate: orderDate
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);

                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteRecord = async (index, id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeleteInvoice?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                toast.success('Record deleted successfully');
                // Remove the row from the UI
                const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
                setRows(updatedRows);
            } else {
                console.error('Failed to delete the record:', response.statusText);
                toast.error('Failed to delete the record');
            }
        } catch (error) {
            console.error('Error deleting the record:', error);
            toast.error('Error deleting the record');
        }
    };

    const hasPaymentDetails = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length > 0;
    const hasLCDetails = selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length > 0;

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleString('en-US', options);
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit Invoice Generated</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion title={"PO recieved"}
                        isOpen={openAccordionIndex.includes(0)}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Issued"}
                        isOpen={openAccordionIndex.includes(1)}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Confirmation"}
                        isOpen={openAccordionIndex.includes(2)}
                        onClick={() => handleAccordionClick(2)}
                    >
                        <ViewPIConfirmedbyCustomer
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Advance Payment / LC"}
                        isOpen={openAccordionIndex.includes(3)}
                        onClick={() => handleAccordionClick(3)}
                    >
                        <ViewPaymentLCReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Accepted"}
                        isOpen={openAccordionIndex.includes(4)}
                        onClick={() => handleAccordionClick(4)}
                    >
                        <ViewOrderAccepted
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Under Process"}
                        isOpen={openAccordionIndex.includes(5)}
                        onClick={() => handleAccordionClick(5)}
                    >
                        <ViewOrderUnderProcess
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Booking Issued"}
                        isOpen={openAccordionIndex.includes(6)}
                        onClick={() => handleAccordionClick(6)}
                    >
                        <ViewBookingIssue
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Containers Under Loading"}
                        isOpen={openAccordionIndex.includes(7)}
                        onClick={() => handleAccordionClick(7)}
                    >
                        <ViewContainersUnderLoading
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Invoice Generated"}
                        isOpen={openAccordionIndex.includes(8)}
                        onClick={() => handleAccordionClick(8)}
                    >
                        <div>
                            {hasPaymentDetails && (
                                <SubAccodion title={"Advance Payment"}>
                                    <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                                        {selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length > 0 ? (
                                            selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <CustomViewLabel label={`${index + 1} - Currency Type`} value={item.currencyType} />
                                                    <CustomViewLabel label={`${index + 1} - PO Amount`} value={item.poAmount} />
                                                    <CustomViewLabel label={`${index + 1} - Pi Number`} value={item.piNumber} />
                                                    <CustomViewLabel label={`${index + 1} - Pi Issued Date`} value={formatDateTest1(item.piIssuedDate)} />
                                                    <CustomViewLabel label={`${index + 1} - Total Received Amount`} value={item.totalReceivedAmount} />
                                                    <CustomViewLabel label={`${index + 1} - Remaining Amount`} value={item.remainingAmount} />
                                                    <CustomViewLabel label={`${index + 1} - Payment Terms`} value={item.paymentTerms} />
                                                    {/* <CustomViewLabel label={`${index + 1} - Invoice Generated Date`} value={formatDateTest1(item.invoiceGenerateDate)} /> */}
                                                    {/* <CustomViewLabel label={`${index + 1} - Invoice Number`} value={item.invoiceNumber} /> */}
                                                    <CustomViewLabel label={`${index + 1} - Payment Received Date`} value={formatDateTest1(item.paymentReceivedDate)} />
                                                    <CustomViewLabel label={`${index + 1} - Payment Type`} value={item.paymentReceived} />
                                                    <CustomViewLabel label={`${index + 1} - Payment Received`} value={item.amount} />
                                                    <CustomViewLabel label={`${index + 1} - Bank Reference Number`} value={item.bankReferenceNumber} />
                                                    {/* <CustomViewLabel label={`${index + 1} - Attachment`} value={""} /> */}

                                                    {item.purchaseOrderPaymentReceivedImagesList && item.purchaseOrderPaymentReceivedImagesList.length > 0 ? (
                                                        <div>
                                                            {item.purchaseOrderPaymentReceivedImagesList.map((image, imgIndex) => (
                                                                <div key={imgIndex}>
                                                                    <CustomFileViewer
                                                                        fileURL={image.imageURL}
                                                                        fileName={image.imageOriginalFileName ?? ''}
                                                                        label={`Attachment ${imgIndex + 1}`}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <p className="text-xs">No attachments available.</p>
                                                    )
                                                    }
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <div className="text-xs ">No Advance Payment details available.</div>
                                        )}
                                    </div>
                                </SubAccodion>
                            )}

                            {!hasPaymentDetails && hasLCDetails && (
                                <SubAccodion title={"LC"}>
                                    <div className=" max-w-full w-[1000px]">
                                        {selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length > 0 ? (
                                            selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 mt-3">
                                                        <div className=''>
                                                            <CustomViewLabel label={`Currency Type`} value={selectedValue?.pO_CurrencyType} />
                                                        </div>
                                                        <div className="">
                                                            <CustomViewLabel label={"PO Amount"} value={item.poAmount} />
                                                        </div>
                                                        <div className="">
                                                            <CustomViewLabel label={"Pi Number"} value={item.piNumber} />
                                                        </div>
                                                        <div className="">
                                                            <CustomViewLabel label={"Pi Issued Date"} value={formatDateTest1(item.piIssuedDate)} />
                                                        </div>
                                                        <div className=''>
                                                            <CustomViewLabel label={`Remaining Amount`} value={"0"} />
                                                        </div>
                                                        <div className=''>
                                                            <CustomViewLabel label={`Payment Terms`} value={selectedValue?.pO_PaymentTerms} />
                                                        </div>
                                                        <div className="">
                                                            <CustomViewLabel label={`Received Date`} value={formatDateTest(item.receivedDate)} />
                                                        </div>
                                                        <div className="">
                                                            <CustomViewLabel label={`LC Number`} value={item.lcNumber} />
                                                        </div>
                                                        <div className="">
                                                            {item?.imageURL && !imageError[index] ? (
                                                                <CustomFileViewer fileURL={item.imageURL} fileName={item.originalFileName ?? ''} label={`LC Received Received Upload ${index + 1}`} />
                                                            ) : (
                                                                <>
                                                                    <div className="h-10 w-10 rounded-full object-cover mx-auto border text-gray-400 text-[10px] text-center flex items-center justify-center">
                                                                        No image
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>

                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <div className="text-xs ">No Lc details available.</div>
                                        )}
                                    </div>
                                </SubAccodion>
                            )}
                        </div>

                        {/* <EditInvoiceGenerated /> */}
                        <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="date" className="text-xs font-[400]">
                                        Invoice Generated Date
                                    </label>
                                    <input
                                        type="datetime-local"
                                        required
                                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                        value={orderDate}
                                        onChange={(e) => setOrderDate(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="id" className="text-xs font-[400] uppercase">
                                        Invoice Number<span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        value={conName}
                                        className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                        id="id"
                                        placeholder={"Enter"}
                                        onChange={handleChange}
                                    />
                                    {errorMessages.main && <span className="text-red-500 text-xs">{errorMessages.main}</span>}
                                </div>
                            </div>
                            <div>
                                <CustomInputNumberDecimal label="Invoice Amount" value={invoiceAmt} onChange={setInvoiceAmt} isRequired={false} placeholder={"Enter"} />
                            </div>
                            <div>
                                <CustomInputNumberDecimalQty
                                    label={"Final Qty"}
                                    placeholder={"ENTER"}
                                    value={qty}
                                    onChange={setQty}
                                    isRequired={false}
                                ></CustomInputNumberDecimalQty>
                            </div>
                            <div className="">
                                <CustomFileUpload
                                    label="Invoice Generated Upload"
                                    id={`poissuede433345345dit-edit-0`}
                                    value={mainConAttach}
                                    onChange={handleMainConAttachChange}
                                    initialFileName={mainConAttachName}
                                    initialFileUrl={mainConAttachUrl}
                                />
                                {errorMessages.mainAttachment && (
                                    <span className="text-red-500 text-xs">{errorMessages.mainAttachment}</span>
                                )}
                            </div>
                        </div>

                        <div className="mt-5 max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">

                            <div className="">
                                <CustomCheckBox2
                                    label={"Invoice Generated Close"}
                                    state={cuLoadClose}
                                    setState={handleCheckboxChange}
                                ></CustomCheckBox2>
                            </div>
                            {cuLoadClose && (
                                <CustomViewLabel
                                    label="Invoice Generated Close Date & Time"
                                    value={endFormatDate(endCloseDateTime)}
                                />
                            )}
                        </div>
                    </Accordion>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}
            <ToastContainer autoClose={2000} />
        </>
    )
}
