import React, { useState, useEffect } from 'react';
import { ImEnlarge } from "react-icons/im";
import Modal from 'react-modal';
import { MdCancel } from "react-icons/md";
import { FaDownload } from "react-icons/fa";

function CustomImgUpload(props) {
    const { id, label, isRequired, readOnly, value, onChange, disabled, initialFileName, initialFileUrl } = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState(initialFileName || "");
    const [fileType, setFileType] = useState("");
    const [fileBase64, setFileBase64] = useState("");
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        if (initialFileUrl) {
            setFileType(initialFileUrl.endsWith('.pdf') ? 'application/pdf' : 'image/jpeg');
        }
    }, [initialFileUrl]);

    useEffect(() => {
        if (error) {
            const errorTimeout = setTimeout(() => {
                setError("");
            }, 5000);

            return () => clearTimeout(errorTimeout);
        }
    }, [error]);

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            const extension = file.name.split('.').pop().toLowerCase();
            if (!allowedTypes.includes(file.type) || !allowedTypes.includes(`image/${extension}`)) {
                setError("Only .jpg, .jpeg, .png images are allowed.");
                return;
            }
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
            if (fileSizeInMB > 3) {
                setError("File size is more than 3 MB.");
                return;
            } else {
                setError("");
                setSuccessMessage("Uploading.....");
                setTimeout(() => {
                    // Clear success message after 5 seconds
                    setSuccessMessage("");
                }, 5000);
            }
            setFileName(file.name);
            setSelectedFile(file);
            setFileType(file.type);

            const base64 = await convertToBase64(file);
            setFileBase64(base64);
            onChange({ base64, name: file.name });
        } else {
            setFileName("");
            setSelectedFile(null);
            setFileType("");
            setFileBase64("");
            onChange({ base64: "", name: "" });
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result.replace(/^data:(.*,)?/, '');
                resolve(base64String);
            };
            reader.onerror = (error) => reject(error);
        });
    };

    const handleCancel = () => {
        setSelectedFile(null);
        setFileName("");
        setFileType("");
        setFileBase64("");
        onChange({ base64: "", name: "" });
    };

    const handleDownload = () => {
        const url = selectedFile ? URL.createObjectURL(selectedFile) : initialFileUrl;
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleEnlarge1 = () => {
        setIsModalOpen(true);
    };

    const handleEnlarge = () => {
        const fileUrl = selectedFile ? URL.createObjectURL(selectedFile) : initialFileUrl;
        window.open(fileUrl, '_blank');  // Opens the file in a new tab
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleDelete = () => {
        setSelectedFile(null);
        setFileName("");
        setFileType("");
        setFileBase64("");
        onChange({ base64: "", name: "" });
    
        // Clear file input to allow re-uploading the same file
        document.getElementById(`file-upload-${id}`).value = null;
    };
    
    return (
        <div className="flex flex-col gap-1 relative">
            <label htmlFor={id} className="text-xs font-[400]">
                {label.toUpperCase()}
                {isRequired && <span className="text-red-500 gap-3">*</span>}
            </label>
            <div className="flex justify-between items-center">
                <div>
                    <label
                        htmlFor={`file-upload-${id}`}
                        className={disabled ? "cursor-pointer text-xs bg-gray-600 text-white p-2 rounded-md" : "cursor-pointer text-xs bg-prp-color text-white p-2 rounded-md"}
                    >
                        Attachment
                    </label>
                    <input
                        type="file"
                        id={`file-upload-${id}`}
                        key={selectedFile ? fileName : "no-file"} // Dynamic key to force re-render
                        className="hidden"
                        onChange={handleFileChange}
                        disabled={disabled}
                    />
                </div>

                {(fileName || initialFileName) && (
                    <div className="flex items-center space-x-2">
                        <button onClick={handleDownload} type='button' className="bg-prp-color text-white p-1 rounded">
                            <FaDownload h-8 w-8 />
                        </button>
                        <button onClick={handleDelete} type='button' className="bg-white txt-prp-color border border-prp p-1 rounded">
                            <MdCancel h-8 w-8 />
                        </button>
                        <button onClick={handleEnlarge} type='button' className="bg-prp-color text-white p-1 rounded">
                            <ImEnlarge h-8 w-8 />
                        </button>
                    </div>
                )}

                <div>
                    {(selectedFile || initialFileUrl) && fileType.startsWith('image/') && (
                        <img
                            src={selectedFile ? URL.createObjectURL(selectedFile) : initialFileUrl}
                            alt="Profile"
                            className="w-10 h-10 rounded-full border border-[#3D3D3D66]"
                        />
                    )}
                    {(selectedFile || initialFileUrl) && fileType === 'application/pdf' && (
                        <embed
                            src={selectedFile ? URL.createObjectURL(selectedFile) : initialFileUrl}
                            type="application/pdf"
                            className="w-10 h-10 border border-[#3D3D3D66]"
                        />
                    )}
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Enlarge File"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <div className="coman-modal-flex">
                    {(fileName || initialFileName) && (
                        <div className="coman-modal-text">
                            <p>{fileName || initialFileName}</p>
                        </div>
                    )}
                    <div className="coman-modal">
                        <button onClick={handleDownload} className="modal-btn-comman modal-btn-dwl"><FaDownload h-8 w-8 /> </button>
                        <button onClick={closeModal} className="modal-btn-comman modal-btn-cnl"><MdCancel h-8 w-8 /></button>
                    </div>
                </div>
                {(selectedFile || initialFileUrl) && fileType.startsWith('image/') && (
                    <img
                        src={selectedFile ? URL.createObjectURL(selectedFile) : initialFileUrl}
                        alt="Enlarged"
                        className="w-full h-auto"
                    />
                )}
                {(selectedFile || initialFileUrl) && fileType === 'application/pdf' && (
                    <embed
                        src={selectedFile ? URL.createObjectURL(selectedFile) : initialFileUrl}
                        type="application/pdf"
                        alt="Enlarged"
                        className="w-full h-auto"
                    />
                )}
            </Modal>
            {error && <p className="absolute -bottom-6 text-[10px] font-normal mb-2" style={{ color: 'red' }}>{error}</p>}
            {successMessage && (
                <p className="absolute -bottom-6 text-[10px] font-normal mb-2" style={{ color: 'green' }}>{successMessage}</p>
            )}
        </div>
    );
}

CustomImgUpload.defaultProps = {
    isRequired: true,
};

export default CustomImgUpload;