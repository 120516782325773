// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

export default function ViewCommission({ selectedValue }) {
    const oC_OrderCompleteDate = selectedValue?.oC_OrderCompleteDate ? new Date(selectedValue.oC_OrderCompleteDate).toLocaleDateString('en-GB') : 'N/A';

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                    <CustomViewLabel label="Total Qty" value={selectedValue?.mC_TotalQty} />
                    <CustomViewLabel label="Total Payment Received" value={selectedValue?.mC_TotalPaymentReceived} />
                    <CustomViewLabel label="Commission Per Ton" value={selectedValue?.mC_CommissionPerTon} />
                    <CustomViewLabel label="Total Commission" value={selectedValue?.mC_TotalCommission} />
                    <CustomViewLabel label="Commission Close Date" value={formatDateTest1(selectedValue?.mC_CommissionClosedDate)} />
                    <CustomViewLabel label="Commission Closed" value={selectedValue?.mC_IsCommissionClosed} />
                    <CustomViewLabel label="Commission Closed Date & Time" value={formatDateTest1(selectedValue?.mC_CommissionClosedDateTime)} />
                </div>
            </div>
        </>
    )
}


