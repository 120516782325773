import React, { useState, useEffect } from 'react';

function CustomInputNumberDecimalMinus(props) {
    const { label, isRequired, readOnly, value, onChange, disabled, placeholder } = props;
    const [inputValue, setInputValue] = useState(value);
    const [error, setError] = useState('');

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleChange = (event) => {
        let newValue = event.target.value.trim();

        // Allow only numeric characters, one decimal point, and an optional minus sign
        const decimalRegex = /^-?\d*\.?\d*$/;

        if (!decimalRegex.test(newValue)) {
            setError('Only numeric values, one decimal point, and an optional minus sign are allowed.');
            return;
        } else {
            setError('');
        }

        setInputValue(newValue);
        onChange(newValue);
    };

    return (
        <div className="flex flex-col gap-1">
            <label htmlFor="id" className="text-xs font-[400]">
                {label.toUpperCase()}
                {isRequired && <span className="text-red-500 gap-3">*</span>}
            </label>
            <input
                type="tel"
                required={isRequired}
                readOnly={readOnly}
                id="id"
                disabled={disabled}
                value={inputValue}
                placeholder={placeholder}
                onChange={handleChange}
                className={`p-2 uppercase border rounded min-w-[14rem] text-xs placeholder:text-xs 
                    ${error ? 'border-red-500' : 'border-gray-300'} 
                    ${disabled || readOnly ? 'bg-gray-200 text-gray-500' : 'bg-white'}`}
            />
            {error && <span className="text-red-500 text-xs">{error}</span>}
        </div>
    );
}

CustomInputNumberDecimalMinus.defaultProps = {
    isRequired: true,
    value: '',
    onChange: () => {},
};

export default CustomInputNumberDecimalMinus;
