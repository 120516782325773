// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import '../../../assets/css/selectbar.css';
import axios from 'axios';
import Select from 'react-select';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import Accordion from "../../general-components/Accodion";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import PopUp from "../../general-components/PopUp.componenet";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

import { FaDownload } from "react-icons/fa";
import { ImEnlarge } from "react-icons/im";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdCancel } from "react-icons/md";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomCheckBox4 from "../../general-components/CustomCheckBox4.component";
import CustomInputNumberDecimal from "../../general-components/CustomInputNumberDecimal.component";

export default function PaymentReceivedEdit({ onCancel, selectedValue, setSelectedValue }) {
  const [checkboxPi, setCheckboxPi] = useState(true);
  const [openAccordionIndex, setOpenAccordionIndex] = useState([3]);
  const [prlCloseAll, setPlrCloseAll] = useState(false);
  const [updatePopup1, setUpdatePopup1] = useState(false);

  const [endCloseDateTime, setEndCloseDateTime] = useState(null);

  const endFormatDate = (date) =>
    date
      ? new Date(date).toLocaleString('en-IN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // 12-hour format
      })
      : 'N/A';

  const handleCheckboxChange = (state) => {
    setPlrCloseAll(state);

    // Set the time every time the checkbox is flagged (true)
    if (state) {
      const timestamp = new Date().toISOString();
      setEndCloseDateTime(timestamp); // update time on each flag
    }
  };

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index) // Close if already open
        : [...prevIndexes, index] // Add to open accordions
    );
  };

  useEffect(() => {
    if (selectedValue) {
      setPlrCloseAll(selectedValue.plR_IsPaymentOrLCClosed);
      setPoAmt1(selectedValue.pO_Amount);
    }
  }, [selectedValue])

  const [date, setDate] = useState("");
  // =========== 11 
  const [iNo1, setINo1] = useState("");
  const [amt1, setAmt1] = useState("");
  const [date1, setDate1] = useState("");
  const [date1Ig, setDate1Ig] = useState("");
  const [rows1, setRows1] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const [paymentTermsData1, setPaymentsTermsData1] = useState([]);
  const [currencyTypeData1, setCurrencyTypeData1] = useState([]);
  const [paymentRecivedData1, setPaymentRecivedData1] = useState([]);
  const [paymentTermsId1, setPaymentsTermsId1] = useState("");
  const [paymentTermsName1, setPaymentsTermsName1] = useState("");
  const [currencyTypeId1, setCurrencyTypeId1] = useState("");
  const [currencyTypeName1, setCurrencyTypeName1] = useState("");
  const [paymentRecivedId1, setPaymentRecivedId1] = useState("");
  const [paymentRecivedIdName1, setPaymentRecivedName1] = useState("");

  const [poAmt1, setPoAmt1] = useState("");
  const [remainAmt, setRemainAmt] = useState(0)
  const [fileError, setFileError] = useState(""); // State to store the file input error
  const [error1, setError1] = useState("");
  const [existingTotalAmt, setExistingTotalAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);
  const [piOptionsPy, setPiOptionsPy] = useState([]);
  const [selectedPiNumberPy, setSelectedPiNumberPy] = useState(null);
  const [piIssuedDatePy, setPiIssuedDatePy] = useState("");
  const [payId, setPayId] = useState(0);

  const token1 = localStorage.getItem('DIYAN_IE_authToken');
  const dataActive1 = { searchText: "", isActive: true };

  const fetchData1 = async (url, setData, errorMsg) => {
    setIsLoading1(true);
    try {
      const response = await axios.post(url, dataActive1, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token1}`
        },
      });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error(errorMsg);
    } finally {
      setIsLoading1(false);
    }
  };

  useEffect(() => {
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentTermsList`, setPaymentsTermsData1, "Error fetching Payment Terms Data");
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCurrencyTypeList`, setCurrencyTypeData1, "Error fetching Currency Type Data");
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentReceivedList`, setPaymentRecivedData1, "Error fetching Payment Received Data");
  }, []);

  const [bankRefNo, setBankRefNo] = useState("");
  const [conAttach, setConAttach] = useState([]);

  useEffect(() => {
    if (selectedValue) {
      const initialPoAmt = selectedValue.pO_Amount;
      const existingPayments = selectedValue.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail || [];

      // Calculate total of existing received payments
      const totalReceivedFromRecords = existingPayments.reduce(
        (sum, record) => sum + (parseFloat(record.amount) || 0),
        0
      );

      setPoAmt1(initialPoAmt);
      setExistingTotalAmt(totalReceivedFromRecords);
      setTotalAmt(totalReceivedFromRecords); // Initialize totalAmt with existing total
      setRemainAmt(initialPoAmt - totalReceivedFromRecords);

      if (selectedValue?.piIssuedList) {
        // Map piIssuedList to match React-Select's options format
        const options = selectedValue.piIssuedList.map(pi => ({
          value: pi.id,
          label: pi.piNumber,
          piIssueDate: pi.piIssueDate, // Include issue date for easy access
        }));
        setPiOptionsPy(options);

        // Set the latest PI number as the default selected value
        if (options.length > 0) {
          const latestPi = options[0];
          setSelectedPiNumberPy(latestPi);
          setPiIssuedDatePy(latestPi.piIssueDate); // Set issue date for the default selected value
        }
      }
    }
  }, [selectedValue]);

  // Utility function for IST Date
  const getCurrentISTDateTime = () => {
    const now = new Date();
    const offset = 330; // IST is UTC+5:30
    const istDate = new Date(now.getTime() + offset * 60 * 1000);
    return istDate.toISOString().slice(0, 16); // Format to 'YYYY-MM-DDTHH:mm'
  };

  useEffect(() => {
    if (selectedValue) {
      const details = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail;

      if (details && details.length > 0) {
        const record = details[0]; // Assuming you're binding the first record in the array.
        setPayId(record.id || 0);
        setAmt1(record.amount || 0);
        setPaymentRecivedId1(record.paymentReceivedId || "");
        setPaymentRecivedName1(record.paymentReceived || "");
        setPoAmt1(record.poAmount || 0);
        setRemainAmt(record.remainingAmount || (record.poAmount - record.amount));
        setTotalAmt(record.totalReceivedAmount || record.amount);

        setBankRefNo(record.bankReferenceNumber || "");
        setConAttach(record.purchaseOrderPaymentReceivedImagesList || []);

        setSelectedPiNumberPy({ label: record.piNumber, value: record.id });
        setPiIssuedDatePy(record.piIssuedDate || getCurrentISTDateTime());

        if (record && record.paymentReceivedDate) {
          console.log("Setting Date:", record.paymentReceivedDate.slice(0, 16));
          setDate1(record.paymentReceivedDate.slice(0, 16)); // Truncate for datetime-local format
        } else {
          console.warn("Missing paymentReceivedDate. Setting default.");
          setDate1(setDefaultDate());
        }
      } else {
        console.warn("No payment details found.");
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    // Create a new date object in UTC and convert it to IST
    const now = new Date();
    const offset = 330; // IST is UTC+5:30
    const istDate = new Date(now.getTime() + offset * 60 * 1000);

    // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
    const formattedDate = istDate.toISOString().slice(0, 16);
    setDate1(formattedDate);
    setDate1Ig(formattedDate);
  }, []);

  useEffect(() => {
    if (selectedValue) {
      setCurrencyTypeId1(selectedValue.pO_CurrencyTypeId);
      setCurrencyTypeName1(selectedValue.pO_CurrencyType);
      setPaymentsTermsId1(selectedValue.pO_PaymentTermsId);
      setPaymentsTermsName1(selectedValue.pO_PaymentTerms);
    }
  }, [selectedValue])

  const handleAmt1Change = (value) => {
    if (value === "") {
      // Reset amt1 and recalculate amounts when cleared
      setAmt1("");
      setTotalAmt(existingTotalAmt); // Reset total amount to the initial value
      setRemainAmt(poAmt1 - existingTotalAmt); // Recalculate remaining amount
      return;
    }

    const newReceivedAmt = parseFloat(value) || 0; // Convert input to a number
    let errorMessage = "";

    // Validation: Payment cannot be negative
    if (newReceivedAmt < 0) {
      errorMessage = "Payment received cannot be negative.";
    }

    if (errorMessage) {
      setError1(errorMessage);
      return;
    }

    setError1(""); // Clear any existing errors

    // Calculate total amount (existing records total + current payment)
    const updatedTotalAmt = existingTotalAmt + newReceivedAmt;

    // Calculate remaining amount (PO amount - updated total amount)
    const updatedRemainAmt = poAmt1 - updatedTotalAmt;

    // Update states
    setAmt1(newReceivedAmt); // Keep the entered value as a number
    setTotalAmt(updatedTotalAmt);
    setRemainAmt(updatedRemainAmt);
  };

  const [paymentTypeError, setPaymentTypeError] = useState("");

  const handlePiNumber = (option) => {
    if (option) {
      setSelectedPiNumberPy(option);
      setPiIssuedDatePy(option.piIssueDate || getCurrentISTDateTime());
    } else {
      setSelectedPiNumberPy(null);
      setPiIssuedDatePy(getCurrentISTDateTime());
    }
  };

  const setDefaultDate = () => {
    const now = new Date();
    const offset = 330; // IST is UTC+5:30
    const istDate = new Date(now.getTime() + offset * 60 * 1000);

    // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
    return istDate.toISOString().slice(0, 16);
  };

  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    const updatedAttachments = [...conAttach];
    setFileError(""); // Reset errors

    for (const file of files) {
      const isAllowedType = allowedTypes.includes(file.type);
      if (!isAllowedType) {
        setFileError(`File type not allowed: ${file.name}`);
        continue;
      }
      const base64 = await fileToBase64(file);
      updatedAttachments.push({
        name: file.name,
        url: URL.createObjectURL(file),
        base64: base64.split(',')[1],
        isNew: true, // Mark as a new file
      });
    }

    setConAttach(updatedAttachments); // Update state with new files
  };

  const handleDownload = (file) => {
    const link = document.createElement('a');
    let downloadUrl = file.isNew ? file.url : file.imageURL; // Use 'imageURL' for existing files

    // Handle base64 files for new uploads
    if (!downloadUrl && file.base64) {
      const byteCharacters = atob(file.base64);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
      }
      const blob = new Blob(byteArrays, { type: file.type || 'application/octet-stream' });
      downloadUrl = URL.createObjectURL(blob);
    }

    // Ensure the correct download action
    link.href = downloadUrl;
    link.download = file.name || 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    console.log("Downloading file:", file);
  };

  const handleEnlarge = (file) => {
    // Use 'url' for new files and 'imageURL' for existing files
    const fileUrl = file.isNew ? file.url : file.imageURL; // Adjust this based on your data structure
    window.open(fileUrl, '_blank'); // Opens in a new tab
    console.log("Enlarging file:", file);
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    // Reset errors
    setPaymentTypeError("");

    // Determine if all fields are empty
    const isAllEmpty = !amt1 && !paymentRecivedId1;

    // Validate fields
    let hasError = false;
    if (!isAllEmpty && !paymentRecivedId1) {
      setPaymentTypeError("Payment Type is required");
      hasError = true;
    }

    if (hasError) {
      setUpdatePopup1(false)
      return; // Prevent submission if there are errors
    }
    setIsLoading1(true);

    try {
      // Construct paymentReceivedDetail only if there's at least one field with a non-default value

      const effectiveDate = date1 || setDefaultDate();
      const effectiveDate2 = date1Ig || setDefaultDate();
      const effectiveDate31 = piIssuedDatePy || setDefaultDate();
      const effectiveDate3 = piIssuedDatePy || getCurrentISTDateTime();
      const updatedFiles = conAttach.map((file) => {
        if (!file.isNew) {
          // Existing file: Include only the id, leave other fields empty
          return {
            id: file.id || file.imageId, // Use the correct key for the file ID in your data
            imageFileName: "",
            imageOriginalFileName: "",
            image_Base64: "",
          };
        } else {
          // New file: Include base64, file name, and id as 0
          return {
            id: 0,
            imageFileName: file.name,
            imageOriginalFileName: file.name,
            image_Base64: file.base64,
          };
        }
      });

      const paymentReceivedDetail = [];
      if (!isAllEmpty) {
        paymentReceivedDetail.push({
          id: payId || 0,
          invoiceNumber: iNo1 || "",
          paymentTermsId: paymentTermsId1 || 0,
          paymentReceivedDate: effectiveDate,
          currencyTypeId: currencyTypeId1 || 0,
          amount: amt1 || 0,
          paymentReceivedId: paymentRecivedId1 || 0,
          poAmount: poAmt1 || 0,
          remainingAmount: remainAmt || 0,
          totalReceivedAmount: totalAmt || 0,
          bankReferenceNumber: bankRefNo || "",
          invoiceGenerateDate: effectiveDate2,
          piNumber: selectedPiNumberPy.label || "",
          piIssuedDate: effectiveDate3,
          purchaseOrderPaymentReceivedImagesList: updatedFiles
          // purchaseOrderPaymentReceivedImagesList: conAttach
          //   .filter(file => file.base64 || file.name) // Include only if base64 or name is present
          //   .map((file) => ({
          //     id: 0,
          //     imageFileName: file.name,
          //     imageOriginalFileName: file.name,
          //     image_Base64: file.base64,
          //   })),
        });
      }

      const requestBody1 = {
        id: selectedValue.id,
        customerId: selectedValue.customerId || 0,
        paymentReceived_Or_LCReceivedDetails: {
          paymentOrLCReceived: 1,
          paymentOrLCClosed: prlCloseAll,
          paymentReceivedDetail: paymentReceivedDetail.length > 0 ? paymentReceivedDetail : undefined,
          lcReceivedDetail: []
        }
      };

      console.log("Request Body: ", requestBody1);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token1}`
        },
        body: JSON.stringify(requestBody1),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        toast.success("Record Updated successfully");
        setUpdatePopup1(false);
        setTimeout(() => {
          // window.location.reload(); // Reload the page or fetch updated list
          onCancel();
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }

    } catch (error) {
      console.error("Error performing PO Received operation:", error);
      toast.error("Error performing PO Received operation");
    } finally {
      setIsLoading1(false);
    }
  };

  const handleCancel = (imgIndex) => {
    const updatedAttachments = conAttach.filter((_, index) => index !== imgIndex);
    setConAttach(updatedAttachments);
  };

  const handleDeleteRecordImage = async (imgIndex, id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeletePurchaseOrderPaymentReceivedImages?Id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedAttachments = conAttach.filter((_, index) => index !== imgIndex);
        setConAttach(updatedAttachments);
        toast.success('Record deleted successfully');
      } else {
        toast.error('Failed to delete the record');
      }
    } catch (error) {
      console.error('Error deleting the record:', error);
      toast.error('Error deleting the record');
    }
  };

  // Calculate total attachments (existing + new)
  const totalAttachments = conAttach.length;

  const handlePaymentRecChange = (selectedOption) => {
    if (selectedOption) {
      setPaymentRecivedId1(selectedOption.value);
      setPaymentRecivedName1(selectedOption.label); // Update the department name label
    } else {
      setPaymentRecivedId1(null); // Reset to null when cleared
      setPaymentRecivedName1(''); // Clear the department name label
    }
  }

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className=" font-weight-[400]">Edit Advance Payment / LC</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
          <Accordion title={"PO recieved"}
            isOpen={openAccordionIndex.includes(0)}
            onClick={() => handleAccordionClick(0)}
          >
            <ManagePOView
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Issued"}
            isOpen={openAccordionIndex.includes(1)}
            onClick={() => handleAccordionClick(1)}
          >
            <ViewPOIssued
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Confirmation"}
            isOpen={openAccordionIndex.includes(2)}
            onClick={() => handleAccordionClick(2)}
          >
            <ViewPIConfirmedbyCustomer
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"Advance Payment / LC"}
            isOpen={openAccordionIndex.includes(3)}
            onClick={() => handleAccordionClick(3)}
          >
            <div className="max-w-full grid grid-cols-1 gap-4 md:grid-cols-1 w-[1000px]">
              <div className="mt-2 flex gap-4 items-center justify-between">
                <div>
                  <CustomCheckBox4
                    label={"Advance Payment"}
                    state={checkboxPi}
                    setState={setCheckboxPi}
                    readonly
                  />
                </div>
              </div>
            </div>

            <div className="max-w-full grid grid-cols-2 mt-5 gap-4 md:grid-cols-3 w-[1000px]">
              <div className="">
                <div className="flex flex-col gap-1">
                  <label className="text-xs font-400">
                    Currency Type
                  </label>
                  <Select
                    className="text-black text-xs select-bar uppercase"
                    id="id"
                    value={{ value: currencyTypeId1, label: currencyTypeName1 }} // Set initial value here
                    menuPosition="fixed"
                    isDisabled={true}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: '#e5e7eb',
                      },
                    })}
                  />
                </div>
              </div>
              <div>
                <CustomInputNumberDecimal label="PO Amount" value={poAmt1} readOnly isRequired={false} />
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <label className="text-xs font-400">PI Number</label>
                  <Select
                    options={piOptionsPy}
                    className="text-black text-xs select-bar uppercase"
                    id="id"
                    value={selectedPiNumberPy} // Show the selected value
                    onChange={handlePiNumber} // Update selected value on change
                    isClearable
                    menuPosition="fixed"
                    theme={theme => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: '#e5e7eb',
                      },
                    })}
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="date" className="text-xs font-[400]">
                    Pi Issued Date
                  </label>
                  <input
                    type="datetime-local"
                    disabled
                    className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                    value={piIssuedDatePy}
                  />
                </div>
              </div>
              <div className="">
                <CustomInputNumberDecimal label="Total Received Amount" value={totalAmt} readOnly isRequired={false} />
              </div>
              <div>
                <CustomInputNumberDecimal label="Remaining Amount" value={remainAmt} readOnly isRequired={false} />
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <label className="text-xs font-400">
                    Payment Terms
                  </label>
                  <Select
                    className="text-black text-xs select-bar uppercase"
                    id="id"
                    value={{ value: paymentTermsId1, label: paymentTermsName1 }} // Set initial value here
                    menuPosition="fixed"
                    isDisabled={true}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: '#e5e7eb',
                      },
                    })}
                  />
                </div>
              </div>
              <div className="">
                <div className="flex flex-col gap-1">
                  <label htmlFor="date" className="text-xs font-[400]">
                    Payment received Date
                  </label>
                  <input
                    type="datetime-local"
                    // required
                    className={`p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs text-black bg-white`}
                    value={date1}
                    onChange={(e) => setDate1(e.target.value) || ""}
                    onBlur={() => {
                      if (!date1) setDate(setDefaultDate());
                    }}
                  />
                </div>
              </div>
              <div className="">
                <div className="flex flex-col gap-1">
                  <label htmlFor="id" className="text-xs font-400">
                    Payment Type<span className="text-red-500 gap-3">*</span>
                  </label>
                  <Select
                    options={paymentRecivedData1.map((cData) => ({
                      value: cData.id,
                      label: cData.paymentReceived,
                    }))}
                    value={paymentRecivedId1 ? { value: paymentRecivedId1, label: paymentRecivedIdName1 } : null}
                    onChange={handlePaymentRecChange}
                    // onChange={(option) => {
                    //   setPaymentRecivedId1(option ? option.value : "");
                    //   if (option) {
                    //     setPaymentTypeError(""); // Clear error when a valid value is selected
                    //   }
                    // }}
                    className="text-black text-xs select-bar uppercase"
                    id="id"
                    isSearchable
                    isClearable
                    menuPosition={"fixed"}
                    closeMenuOnSelect={true}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: "#e5e7eb",
                      },
                    })}
                  />
                  {paymentTypeError && <span className="text-red-500 text-xs">{paymentTypeError}</span>}
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="amt1" className="text-xs font-[400]">Payment Received</label>
                  <input
                    type="number"
                    step="0.01"
                    className={`p-2 border rounded grow min-w-[14rem] text-xs text-black bg-white ${error1 ? 'border-red-500' : ''}`}
                    id="amt1"
                    value={amt1}
                    placeholder="ENTER"
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'); // Allow digits and one decimal
                      handleAmt1Change(sanitizedValue);
                    }}
                  />
                  {error1 && <span className="text-red-500 text-xs">{error1}</span>}
                </div>
              </div>
              <div>
                <CustomTextField2 label="Bank Reference Number" placeholder="ENTER" value={bankRefNo} onChange={setBankRefNo} isRequired={false} />
              </div>
              {/* <div>
                    <CustomImagePdfMultiple
                      label="Attachment"
                      id="poissuede435345ed4565it-edit-0"
                      value={conAttach}
                      isRequired={false}
                      onChange={handleConAttachChange}
                    />
                  </div> */}
              <div>
                <div className="flex flex-col ">
                  <label htmlFor="file-upload" className="text-xs font-[400] pb-2 uppercase">
                    Attachment
                  </label>
                  <div>
                    <label
                      htmlFor="file-upload"
                      className={`text-xs p-2 rounded-md  ${totalAttachments >= 10 ? 'bg-gray-300 text-gray-600 cursor-not-allowed' : 'bg-prp-color text-white cursor-pointer'
                        }`}
                    >
                      Attach File
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      className="hidden"
                      onChange={handleFileChange}
                      multiple
                      accept=".jpeg,.jpg,.png,.pdf"
                      disabled={totalAttachments >= 10}
                    />
                    {fileError && <div className="text-red-500 text-xs mt-1">{fileError}</div>}
                  </div>

                  {/* Display existing images and new image previews */}
                  <div className="flex flex-wrap mt-3">
                    {conAttach.length > 0 ? (
                      conAttach.map((img, imgIndex) => (
                        <div key={imgIndex} className="relative flex items-center space-x-2 mt-2">
                          {/* Image action buttons */}
                          <button onClick={() => handleDownload(img)} className="bg-prp-color text-white p-2 rounded">
                            <FaDownload />
                          </button>

                          {/* Delete image */}
                          <button
                            onClick={() => img.isNew ? handleCancel(imgIndex) : handleDeleteRecordImage(imgIndex, img.id)}
                            className={`p-2 bg-white ${img.isNew ? 'border-prp  txt-prp-color' : 'border-red-500'} border rounded`}
                          >
                            {img.isNew ? <MdCancel className="text-prp-color" /> : <RiDeleteBin6Line className="text-red-500" />}
                          </button>

                          <button onClick={() => handleEnlarge(img)} className="bg-prp-color text-white p-2 rounded">
                            <ImEnlarge />
                          </button>


                          {/* Handle image preview */}
                          {img.isNew ? (
                            <img src={img.url} alt={img.name} className="w-10 h-10 object-cover rounded-full border border-[#3D3D3D66] " />
                          ) : (
                            <img src={img.imageURL} alt={img.imageOriginalFileName} className="w-10 h-10 object-cover rounded-full border border-[#3D3D3D66]" />
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-500">No images available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="max-w-full grid grid-cols-1  gap-x-2 md:grid-cols-3  w-[1000px]">
              <CustomCheckBox2
                label={"Payment / LC Closed"}
                state={prlCloseAll}
                setState={handleCheckboxChange}
              ></CustomCheckBox2>

              {prlCloseAll && (
                <CustomViewLabel
                  label="Payment / LC Closed Date & Time"
                  value={endFormatDate(endCloseDateTime)}
                />
              )}
            </div>

            <div className="flex justify-center gap-5 pt-3">
              <div onClick={() => setUpdatePopup1(true)}>
                <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
              </div>
              <div onClick={onCancel}>
                <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
              </div>
            </div>
          </Accordion>
        </div>
      </div >

      {
        updatePopup1 && (
          <PopUp>
            <div className="pb-2 bg-white border rounded-lg">
              <div className="flex flex-col p-4">
                <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                <div className="flex justify-end gap-2">
                  <div onClick={handleSubmit1}>
                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                  </div>
                  <div onClick={() => setUpdatePopup1(false)}>
                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                  </div>
                </div>
              </div>
            </div>
          </PopUp>
        )
      }

      <ToastContainer autoClose={2000} />
    </>
  )
}