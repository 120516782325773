import React, { useEffect, useState } from 'react'
import CustomButton2 from '../../general-components/CustomButton2.component'
import CustomButton1 from '../../general-components/CustomButton1.component'
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomInputNumber from '../../general-components/CustomInputNumber.component';
import CustomDate from './../../general-components/CustomDate.component';
import CustomInputNumberDecimal from '../../general-components/CustomInputNumberDecimal.component';
import Select from 'react-select';
import axios from 'axios';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomSelect1 from '../../general-components/CustomSelect1.component';
import CustomInputNumberDecimalMinus from './../../general-components/CustomInputNumberDecimalMinus.component';

export default function ManageSellRateEdit({ onCancel, selectedValue }) {
    const [isLoading, setIsLoading] = useState(false);
    const [containers, setContainers] = useState("");
    const [rate, setRate] = useState("");
    const [reuseFresh, setReuseFresh] = useState("FRESH");
    const [landFreight, setLandFreight] = useState("");
    const [transporterInvoice, setTransporterInvoice] = useState("");
    const [seaFreight, setSeaFreight] = useState("");
    const [transporter, setTransporter] = useState("");
    const [transporterName, setTransporterName] = useState("");
    const [forwarder, setForwarder] = useState("");
    const [forwarderName, setForwarderName] = useState("");
    const [forwarderInvoice, setForwarderInvoice] = useState("");
    const [cha, setCha] = useState("");
    const [chaName, setChaName] = useState("");
    const [chaInvoice, setChaInvoice] = useState("");
    const [clearing, setClearing] = useState("");
    const [sbNumber, setSbNumber] = useState("");
    const [date, setDate] = useState("");
    const [exchangeRate, setExchangeRate] = useState("");
    const [fobValue, setFobValue] = useState("");
    const [currentExchangeRate, setCurrentExchangeRate] = useState("");
    const [drawbackRodtep, setDrawbackRodtep] = useState("");
    const [invoiceAmountInInr, setInvoiceAmountInInr] = useState("");
    const [netSellRate, setNetSellRate] = useState("");

    const [transporterList, setTransporterList] = useState([]);
    const [forwarderList, setForwarderList] = useState([]);
    const [chaList, setChaList] = useState([]);
    const datacomp = { searchText: "", isActive: true };

    const [drawbackPr, setDrawbackPr] = useState("");
    const [fobValueInr, setFobValueInr] = useState("");
    const [finalInvAmt, setFinalInvAmt] = useState("");
    const [commissionIn, setCommissionIn] = useState("");
    const [qty, setQty] = useState("");
    // const [selectedRf, setSelectedRf] = useState("2")
    const [selectedRf, setSelectedRf] = useState(2); // Default as number

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, datacomp, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetTransporterList`, setTransporterList, "Error fetching Transporter Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetForwarderList`, setForwarderList, "Error fetching Forwarder Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCHAList`, setChaList, "Error fetching CHA Data");
    }, []);

    const onTransporterChange = (selectedOption) => {
        if (selectedOption) {
            setTransporter(selectedOption.value);
            setTransporterName(selectedOption.label); // Update the department name label
        } else {
            setTransporter(null); // Reset to null when cleared
            setTransporterName(''); // Clear the department name label
        }
        // setTransporter(selectedOption.value);
    }

    const onForwarderChange = (selectedOption) => {
        if (selectedOption) {
            setForwarder(selectedOption.value);
            setForwarderName(selectedOption.label); // Update the department name label
        } else {
            setForwarder(null); // Reset to null when cleared
            setForwarderName(''); // Clear the department name label
        }
        // setForwarder(selectedOption.value);
    }

    const onChaChange = (selectedOption) => {
        if (selectedOption) {
            setCha(selectedOption.value);
            setChaName(selectedOption.label); // Update the department name label
        } else {
            setCha(null); // Reset to null when cleared
            setChaName(''); // Clear the department name label
        }
        // setCha(selectedOption.value);
    }

    useEffect(() => {
        if (selectedValue) {
            console.log("Selected Value:", selectedValue); // Log selectedValue
            setFobValueInr(selectedValue.totalFOBValue || 0);
            setFinalInvAmt(selectedValue.invoiceAmount || 0);
            setCommissionIn(selectedValue.commissionMentionInSBill || 0);
            setQty(selectedValue.quantity || 0);
            setSelectedRf(selectedValue.reuseOrFreshId)
            setContainers(selectedValue.containers);
            setRate(selectedValue.rate);
            setReuseFresh(selectedValue.reuse_Fresh);
            setLandFreight(selectedValue.landFreight);
            setTransporterInvoice(selectedValue.transporterInvoice);
            setSeaFreight(selectedValue.seaFreight);
            setForwarderInvoice(selectedValue.forwarderInvoice);
            setChaInvoice(selectedValue.chaInvoice);
            setClearing(selectedValue.clearing);
            setSbNumber(selectedValue.sbNo);
            setDate(selectedValue.sbDate ? selectedValue.sbDate.split("T")[0] : null);   // Handle null/undefined
            setExchangeRate(selectedValue.exchangeRate);
            // setFobValue(selectedValue.);
            setCurrentExchangeRate(selectedValue.currentExchangeRate);
            setDrawbackRodtep(selectedValue.drawBack_RodTep);
            setInvoiceAmountInInr(selectedValue.invoiceAmountInINR);
            setNetSellRate(selectedValue.netSellRate);

            setCha(selectedValue.chaId);
            setForwarder(selectedValue.forwarderId);
            setTransporter(selectedValue.transporterId);
            setChaName(selectedValue.cha);
            setForwarderName(selectedValue.forwarder);
            setTransporterName(selectedValue.transporter);

            setDrawbackPr(selectedValue.drawBackPerct);
        } else {
            console.log("No selected value provided"); // Log if selectedValue is null/undefined
        }
    }, [selectedValue]);

    useEffect(() => {
        const totalLandFreight = containers * rate;
        setLandFreight(totalLandFreight);
    }, [containers, rate])

    useEffect(() => {
        const totalDrawBack = drawbackPr * fobValueInr;
        setDrawbackRodtep(totalDrawBack);
    }, [drawbackPr, fobValueInr])

    useEffect(() => {
        const totalInvInr = finalInvAmt * currentExchangeRate;
        setInvoiceAmountInInr(totalInvInr);
    }, [finalInvAmt, currentExchangeRate])

    useEffect(() => {
        const totalNetSellRate = (invoiceAmountInInr + drawbackRodtep - landFreight - seaFreight - clearing - commissionIn) / (qty * 1000);
        setNetSellRate(totalNetSellRate);
    }, [invoiceAmountInInr, drawbackRodtep, landFreight, seaFreight, clearing, commissionIn, qty])



    const token = localStorage.getItem('DIYAN_IE_authToken');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            let requestBody = {};
            requestBody = {
                id: selectedValue.id,
                containers: containers || 0,
                reuseOrFreshId: selectedRf || 0,
                transporterId: transporter || 0,
                rate: rate || 0,
                landFreight: landFreight || 0,
                transporterInvoice: transporterInvoice || "",
                forwarderId: forwarder || 0,
                forwarderInvoice: forwarderInvoice || "",
                seaFreight: seaFreight || 0,
                chaId: cha || 0,
                chaInvoice: chaInvoice || "",
                clearing: clearing || 0,
                sbNo: sbNumber || "",
                sbDate: date || null,
                exchangeRate: exchangeRate || 0,
                currentExchangeRate: currentExchangeRate || 0,
                drawBackPerct: drawbackPr || 0,
                drawBack_RodTep: drawbackRodtep || 0,
                invoiceAmountInINR: invoiceAmountInInr || 0,
                netSellRate: netSellRate || 0,
            };
            console.log("Request Body:", requestBody); // Debugging: check request body

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageMIS/UpdateMIS_SellRate`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            console.log("Response Data:", responseData); // Debugging: check response data

            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                // setUpdatePopup(false);
                setTimeout(() => {
                    onCancel()
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing operation:", error);
            toast.error("Error performing operation");
        } finally {
            setIsLoading(false);
        }
    };

    const optionsRF = [
        { value: 1, label: "REUSE" }, // Values as numbers
        { value: 2, label: "FRESH" },
    ];

    const handleRfChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedRf(selectedOption.value); // Already a number
        } else {
            setSelectedRf(null);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Edit Sell Rate</h1>
                    <div className="flex item-center text-sm"></div>
                </div>

                <div className="p-4 gap-4 max-h-[70vh] overflow-y-auto max-w-full w-[900px]">
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                        <CustomInputNumber label="Containers " placeholder="Enter" isRequired={false}
                            value={containers}
                            onChange={setContainers}
                        />
                        {/* <CustomTextField2 label="REUSE/FRESH" placeholder="Enter" isRequired={false} readOnly
                            value={reuseFresh}
                            onChange={setReuseFresh}
                        /> */}
                        {/* <CustomSelect1 label={"REUSE/FRESH"}
                            options={optionsRF}
                            isRequired={false}
                            onChange={handleRfChange}
                        /> */}

                        <div className="flex flex-col gap-1">
                            <label htmlFor="id" className="text-xs font-400 ">REUSE/FRESH</label>

                            <Select
                                options={optionsRF}
                                onChange={handleRfChange}
                                // className="px-1.5 py-2 border rounded grow min-w-[14rem] text-gray-400 text-xs outline-none bg-white"
                                className="text-black text-xs select-bar uppercase"
                                id="id"
                                isSearchable
                                isClearable
                                // value={optionsRF.find((option) => option.value === selectedRf)} // Set the selected value
                                value={optionsRF.find((option) => option.value === selectedRf)} // Match number value
                                menuPosition={'fixed'} // Set menuPosition to fixed
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#e5e7eb',
                                    },
                                })}
                            />

                        </div>

                        {/* <CustomTextField2 label="TRANSPORTER" placeholder="Enter" isRequired={false}
                            value={transporter}
                            onChange={setTransporter}
                        /> */}
                        {/* <CustomSelect1 label="TRANSPORTER" options={options} isRequired={false} /> */}
                        <div className="flex flex-col gap-1">
                            <label htmlFor="id" className="text-xs font-400 ">
                                TRANSPORTER
                            </label>

                            <Select
                                options={transporterList.map(data => ({
                                    value: data.id,
                                    label: data.transporter
                                }))}
                                onChange={onTransporterChange}
                                value={
                                    transporter && transporterName
                                        ? { value: transporter, label: transporterName }
                                        : null
                                } // Set the selected value in the dropdown
                                // className="px-1.5 py-2 border rounded grow min-w-[14rem] text-gray-400 text-xs outline-none bg-white"
                                className="text-black text-xs select-bar uppercase"
                                id="id"
                                isSearchable
                                isClearable
                                menuPosition={'fixed'} // Set menuPosition to fixed
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#e5e7eb',
                                    },
                                })}
                            />
                        </div>

                        <CustomInputNumberDecimal label="RATE" placeholder="Enter" isRequired={false}
                            value={rate}
                            onChange={setRate}
                        />
                        <CustomInputNumberDecimal label="LAND FREIGHT" placeholder="Enter" isRequired={false}
                            value={landFreight}
                            onChange={setLandFreight}
                            readOnly
                        />
                        <CustomTextField2 label="TRANSPORTER INVOICE" placeholder="Enter" isRequired={false}
                            value={transporterInvoice}
                            onChange={setTransporterInvoice}
                        />
                        {/* <CustomTextField2 label="FORWARDER" placeholder="Enter" isRequired={false}
                            value={forwarder}
                            onChange={setForwarder}
                        /> */}
                        {/* <CustomSelect1 label="FORWARDER" options={options} isRequired={false} /> */}
                        <div className="flex flex-col gap-1">
                            <label htmlFor="id" className="text-xs font-400 ">
                                FORWARDER
                            </label>

                            <Select
                                options={forwarderList.map(data => ({
                                    value: data.id,
                                    label: data.forwarder
                                }))}
                                onChange={onForwarderChange}
                                value={
                                    forwarder && forwarderName
                                        ? { value: forwarder, label: forwarderName }
                                        : null
                                } // Set the selected value in the dropdown
                                // className="px-1.5 py-2 border rounded grow min-w-[14rem] text-gray-400 text-xs outline-none bg-white"
                                className="text-black text-xs select-bar uppercase"
                                id="id"
                                isSearchable
                                isClearable
                                menuPosition={'fixed'} // Set menuPosition to fixed
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#e5e7eb',
                                    },
                                })}
                            />
                        </div>

                        <CustomTextField2 label="FORWARDER INVOICE" placeholder="Enter" isRequired={false}
                            value={forwarderInvoice}
                            onChange={setForwarderInvoice}
                        />
                        <CustomInputNumberDecimal label="SEA FREIGHT" placeholder="Enter" isRequired={false}
                            value={seaFreight}
                            onChange={setSeaFreight}
                        />
                        {/* <CustomTextField2 label="CHA" placeholder="Enter" isRequired={false}
                            value={cha}
                            onChange={setCha}
                        /> */}
                        {/* <CustomSelect1 label="CHA" options={options} isRequired={false} /> */}
                        <div className="flex flex-col gap-1">
                            <label htmlFor="id" className="text-xs font-400 ">
                                CHA
                            </label>

                            <Select
                                options={chaList.map(data => ({
                                    value: data.id,
                                    label: data.cha
                                }))}
                                onChange={onChaChange}
                                value={
                                    cha && chaName
                                        ? { value: cha, label: chaName }
                                        : null
                                } // Set the selected value in the dropdown
                                // className="px-1.5 py-2 border rounded grow min-w-[14rem] text-gray-400 text-xs outline-none bg-white"
                                className="text-black text-xs select-bar uppercase"
                                id="id"
                                isSearchable
                                isClearable
                                menuPosition={'fixed'} // Set menuPosition to fixed
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#e5e7eb',
                                    },
                                })}
                            />
                        </div>

                        <CustomTextField2 label="CHA INVOICE" placeholder="Enter" isRequired={false}
                            value={chaInvoice}
                            onChange={setChaInvoice}
                        />
                        <CustomInputNumberDecimal label="CLEARING" placeholder="Enter" isRequired={false}
                            value={clearing}
                            onChange={setClearing}
                        />
                        <CustomInputNumber label="SB No" placeholder="Enter" isRequired={false}
                            value={sbNumber}
                            onChange={setSbNumber}
                            readOnly
                        />
                        <CustomDate label="SB DATE" placeholder="Enter" isRequired={false}
                            value={date}
                            onChange={setDate}
                            readOnly
                        />
                        <CustomInputNumberDecimal label="EXCHANGE RATE" placeholder="Enter" isRequired={false}
                            value={exchangeRate}
                            onChange={setExchangeRate}
                            readOnly
                        />
                        {/* <CustomInputNumber label="FOB VALUE(RS.)" placeholder="Enter" isRequired={false}
                            value={fobValue}
                            onChange={setFobValue}
                        /> */}
                        <CustomInputNumberDecimal label="CURRENT EXCHANGE RATE" placeholder="Enter" isRequired={false}
                            value={currentExchangeRate}
                            onChange={setCurrentExchangeRate}
                        />
                        <CustomInputNumberDecimal label="Drawback %" placeholder="Enter" isRequired={false}
                            value={drawbackPr}
                            onChange={setDrawbackPr}
                        />
                        <CustomInputNumberDecimal label="DRAWBACK + RODTEP" placeholder="Enter" isRequired={false}
                            value={drawbackRodtep}
                            onChange={setDrawbackRodtep}
                            readOnly
                        />
                        <CustomInputNumberDecimal label="INVOICE AMOUNT IN INR" placeholder="Enter" isRequired={false}
                            value={invoiceAmountInInr}
                            onChange={setInvoiceAmountInInr}
                            readOnly
                        />
                        <CustomInputNumberDecimalMinus label="NET SELL RATE" placeholder="Enter" isRequired={false}
                            value={netSellRate}
                            onChange={setNetSellRate}
                            readOnly
                        />
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" type="submit" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                    </div>
                </div>
            </div>
        </>
    );
}


