import React, { useEffect, useState } from 'react';
import { ImEnlarge } from "react-icons/im";
import { MdCancel } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import "../../assets/css/selectbar.css";

function CustomImagePdfMultiple(props) {
    const { id, label, isRequired, readOnly, value, onChange, disabled } = props;
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        if (error) {
            const errorTimeout = setTimeout(() => {
                setError("");
            }, 5000);

            return () => clearTimeout(errorTimeout);
        }
    }, [error]);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
        const maxFileSizeMB = 3;
        const maxFiles = 10;

        let newFiles = [];
        let invalidFiles = [];

        files.forEach(file => {
            const isAllowedType = allowedTypes.includes(file.type);

            if (!isAllowedType) {
                invalidFiles.push(file.name);
            } else if (file.size / (1024 * 1024) > maxFileSizeMB) {
                invalidFiles.push(file.name);
            } else {
                newFiles.push(file);
            }
        });

        if (selectedFiles.length + newFiles.length > maxFiles) {
            setError(`You can only upload a maximum of ${maxFiles} files.`);
            return;
        }

        if (invalidFiles.length > 0) {
            setError(`The following files are not allowed: ${invalidFiles.join(', ')}.`);
        }

        if (newFiles.length > 0) {
            const fileReaders = newFiles.map(file => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onload = () => {
                        resolve({
                            name: file.name,
                            base64: reader.result.replace(/^data:.*;base64,/, ''),
                            isPDF: file.type === 'application/pdf'
                        });
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            });

            Promise.all(fileReaders).then(results => {
                const updatedFiles = [...selectedFiles, ...results];
                setSelectedFiles(updatedFiles);
                onChange(updatedFiles);
            }).catch(() => {
                setError('Failed to read files');
            });
        }
    };

    const handleCancel = (index) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(updatedFiles);
        onChange(updatedFiles);

        document.getElementById(`file-upload-${id}`).value = null;
    };

    const handleDownload = (file) => {
        const url = file.isPDF
            ? `data:application/pdf;base64,${file.base64}`
            : `data:image/png;base64,${file.base64}`;

        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleEnlarge = (file) => {
        const url = file.isPDF
            ? `data:application/pdf;base64,${file.base64}`
            : `data:image/png;base64,${file.base64}`;

        window.open(url, '_blank');
    };

    return (
        <div className="flex flex-col gap-1 relative">
            <label htmlFor={id} className="text-xs font-[400]">
                {label.toUpperCase()}
                {isRequired && <span className="text-red-500 gap-3">*</span>}
            </label>
            <div className="flex justify-between items-center mt-1 mb-4">
                <div>
                    <label
                        htmlFor={`file-upload-${id}`}
                        className={disabled ? "cursor-pointer text-xs bg-gray-600 text-white p-2 rounded-md" : "cursor-pointer text-xs bg-prp-color text-white p-2 rounded-md"}
                    >
                        Attachment
                    </label>
                    <input
                        type="file"
                        id={`file-upload-${id}`}
                        key={selectedFiles.length}
                        className="hidden"
                        onChange={handleFileChange}
                        disabled={disabled}
                        multiple
                    />
                </div>
            </div>

            {selectedFiles.map((file, index) => (
                <div key={index} className="flex items-center space-x-2 mt-2">
                    <div className="flex items-center space-x-2">
                        <button onClick={() => handleDownload(file)} className="bg-prp-color text-white p-2 rounded">
                            <FaDownload />
                        </button>
                        <button onClick={() => handleCancel(index)} className="bg-white txt-prp-color border border-prp p-2 rounded">
                            <MdCancel />
                        </button>
                        <button onClick={() => handleEnlarge(file)} className="bg-prp-color text-white p-2 rounded">
                            <ImEnlarge />
                        </button>
                    </div>

                    <div>
                        {file.base64 && !file.isPDF && (
                            <img
                                src={`data:image/png;base64,${file.base64}`}
                                alt="Preview"
                                className="w-10 h-10 rounded-full border border-[#3D3D3D66]"
                            />
                        )}
                        {file.isPDF && (
                            // <p className="text-sm">{file.name}</p>
                            <embed
                                src={`data:application/pdf;base64,${file.base64}`}
                                type="application/pdf"
                                className="w-10 h-10 border border-[#3D3D3D66]"
                            />
                        )}
                    </div>
                </div>
            ))}

            {error && (
                <p
                    className="absolute -bottom-6 text-[10px] font-normal"
                    style={{
                        color: 'red',
                        marginTop: '8px',
                        maxWidth: '100%',
                        wordWrap: 'break-word'
                    }}
                >
                    {error}
                </p>
            )}
        </div>
    );
}

CustomImagePdfMultiple.defaultProps = {
    isRequired: true,
};

export default CustomImagePdfMultiple;
