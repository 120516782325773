// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import axios from 'axios';

// COMPONENTS
import CustomButton2 from "../../general-components/CustomButton2.component";
import Title from "../../general-components/Title.component";
import PopUp from "../../general-components/PopUp.componenet";
import { IoMdAdd } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";
import { toast, ToastContainer } from 'react-toastify';
import { BsEyeFill } from "react-icons/bs";
import 'react-toastify/dist/ReactToastify.css';
import '../../../assets/css/tracking.css';

import ManagePOEdit from "../../forms-components/ManagePO/ManagePOAdd";
import ManagePOIssuedEdit from "../../forms-components/ManagePOIssued/ManagePOIssuedEdit";
import PaymentLCReceivedEdit from "../../forms-components/ManagePaymentReceived_LCReceived/PaymentLCReceivedEdit";
import EditPIConfirmedbyCustomer from "../../forms-components/ManagePIConfirmedbyCustomer/AllPi/ManagePIConfirmedbyCustomerEdit";
import EditOrderAccepted from "../../forms-components/ManageOrderAccepted/ManageOrderAcceptedEdit";
import EditOrderUnderProcess from "../../forms-components/ManageOrderUnderProcess/ManageOrderUnderProcessEdit";
import EditBookingIssue from "../../forms-components/ManageBookingIssue/ManageBookingIssueEdit";
import EditContainersUnderLoading from "../../forms-components/ManageContainersUnderLoading/ManageContainersUnderLoadingEdit";
import EditInvoiceGenerated from "../../forms-components/ManageInvoiceGenerated/ManageInvoiceGeneratedEdit";
import EditBiDraftIssued from "../../forms-components/ManageBiDraftIssued/ManageBiDraftIssuedEdit";
import ManageFinalBiIssuedEdit from "../../forms-components/ManageFinalBiIssued/ManageFinalBiIssuedEdit";
import EditFinalAmountToPay from "../../forms-components/ManageFinalAmountToPay/ManageFinalAmountToPayEdit";
import EditDocumentSend from "../../forms-components/ManageDocumentSendDhlSubmitted/ManageDocumentSendEdit";
import EditAwbGeneratedPending from "../../forms-components/ManageAwbGeneratedPending/ManageAwbGeneratedPendingEdit";
import EditOrderCompletedPending from "../../forms-components/ManageOrderCompletedPending/ManageOrderCompletedPendingEdit";
import ManagePaymentReceivedEdit from "../../forms-components/ManagePaymentReceived/ManagePaymentReceivedEdit";
import ManagePOView from "../ManagePO/ManagePOView";
import ManagePOIssuedView from "../ManagePOIssued/ManagePOIssuedView";
import ManagePIConfirmedbyCustomerView from "../ManagePIConfirmedbyCustomer/AllPi/ManagePIConfirmedbyCustomerView";
import PaymentLCReceivedView from "../ManagePaymentReceived_LCReceived/PaymentLCReceivedView";
import ManageOrderAcceptedView from "../ManageOrderAccepted/ManageOrderAcceptedView";
import ManageOrderUnderProcessView from "../ManageOrderUnderProcess/ManageOrderUnderProcessView";
import ManageBookingIssueView from "../ManageBookingIssue/ManageBookingIssueView";
import ManageContainersUnderLoadingView from "../ManageContainersUnderLoading/ManageContainersUnderLoadingView";
import ManageInvoiceGeneratedView from "../ManageInvoiceGenerated/ManageInvoiceGeneratedView";
import ManageBiDraftIssuedView from "../ManageBiDraftIssued/ManageBiDraftIssuedView";
import ManageFinalBiIssuedView from "../ManageFinalBiIssued/ManageFinalBiIssuedView";
import ManageFinalAmountToPayView from "../ManageFinalAmountToPay/ManageFinalAmountToPayView";
import ManageDocumentSendView from "../ManageDocumentSendDhlSubmitted/ManageDocumentSendView";
import ManageAwbGeneratedPendingView from "../ManageAwbGeneratedPending/ManageAwbGeneratedPendingView";
import ManageOrderCompletedPendingView from "../ManageOrderCompletedPending/ManageOrderCompletedPendingView";
import ManagePaymentReceivedView from "../ManagePaymentReceived/ManagePaymentReceivedView";
import { BiEdit } from "react-icons/bi";
import ManageCommissionView from "../ManageCommission/ManageCommissionView";
import ManageCommissionEdit from "../ManageCommission/ManageCommissionEdit";

function ManageTrackingStatusDesign() {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get("orderId");
    const customerId = searchParams.get("customerId");
    const trackingId = searchParams.get("trackingId");
    const tabId = searchParams.get("tab");

    console.log("urlData-->", orderId, customerId, trackingId, tabId);

    //for edit form
    const [popupAddOne, setPopupAddOne] = useState(false);
    const [popupAddTwo, setPopupAddTwo] = useState(false);
    const [popupAddThree, setPopupAddThree] = useState(false);
    const [popupAddFour, setPopupAddFour] = useState(false);
    const [popupAddFive, setPopupAddFive] = useState(false);
    const [popupAddSix, setPopupAddSix] = useState(false);
    const [popupAddSeven, setPopupAddSeven] = useState(false);
    const [popupAddEight, setPopupAddEight] = useState(false);
    const [popupAddNine, setPopupAddNine] = useState(false);
    const [popupAddTen, setPopupAddTen] = useState(false);
    const [popupAddTrackOne, setPopupAddTrackOne] = useState(false);
    const [popupAddTrackTwo, setPopupAddTrackTwo] = useState(false);
    const [popupAddTrackThree, setPopupAddTrackThree] = useState(false);
    const [popupAddTrackFour, setPopupAddTrackFour] = useState(false);
    const [popupAddTrackFive, setPopupAddTrackFive] = useState(false);
    const [popupAddTrackSix, setPopupAddTrackSix] = useState(false);
    const [popupAddTrackFourComm, setPopupAddTrackFourComm] = useState(false);


    // for view form
    const [popupAddOneView, setPopupAddOneView] = useState(false);
    const [popupAddTwoView, setPopupAddTwoView] = useState(false);
    const [popupAddThreeView, setPopupAddThreeView] = useState(false);
    const [popupAddFourView, setPopupAddFourView] = useState(false);
    const [popupAddFiveView, setPopupAddFiveView] = useState(false);
    const [popupAddSixView, setPopupAddSixView] = useState(false);
    const [popupAddSevenView, setPopupAddSevenView] = useState(false);
    const [popupAddEightView, setPopupAddEightView] = useState(false);
    const [popupAddNineView, setPopupAddNineView] = useState(false);
    const [popupAddTenView, setPopupAddTenView] = useState(false);
    const [popupAddTrackOneView, setPopupAddTrackOneView] = useState(false);
    const [popupAddTrackTwoView, setPopupAddTrackTwoView] = useState(false);
    const [popupAddTrackThreeView, setPopupAddTrackThreeView] = useState(false);
    const [popupAddTrackFourView, setPopupAddTrackFourView] = useState(false);
    const [popupAddTrackFiveView, setPopupAddTrackFiveView] = useState(false);
    const [popupAddTrackSixView, setPopupAddTrackSixView] = useState(false);
    const [popupAddTrackFourViewComm, setPopupAddTrackFourViewComm] = useState(false);



    const backClick = () => {
        navigate("/admin/app/manage-tracking-status");
    }
    const token = localStorage.getItem('DIYAN_IE_authToken');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const fetchApiDataById = async (orderId) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/GetPurchaseOrderById?Id=${orderId}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && response.data.data) {
                const apiData = response.data.data;
                setSelectedValue(apiData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Manage Purchase Order Tracking details");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (orderId) {
            fetchApiDataById(orderId);
        }
    }, [orderId]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    return (
        <>
            <div className="flex flex-col h-full p-2 uppercase">
                <div className="p-4 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                    <Title title1={"Manage Tracking Status"} title2={"Manage Tracking"} />
                    <div onClick={backClick} className="flex justify-end mt-3">
                        {/* <CustomButton2 label={"Back"} icon={<IoArrowBack />} variant="outlined" className="txt-prp-color" /> */}
                        <CustomButton2
                            label={"Back"}
                            icon={<IoArrowBack />}
                            variant="outlined"
                            className="txt-prp-color"
                            onClick={() => {
                                localStorage.setItem('diyanActiveTrackingStatusTab', tabId); // Ensure the Role tab is active
                                navigate("/admin/app/manage-tracking-status");
                            }}
                        />
                    </div>

                    <div className=" bg-white rounded grow">
                        <div class="col-12 col-md-10  pt45 pb20">
                            <div class="row flex justify-content-between">
                                <div class={`order-tracking ${selectedValue?.pO_IsPOStatusClosed === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center gap-1 items-center">
                                        <p>PO Recived<br /> <span>{formatDateTest(selectedValue?.pO_StatusClosedDate)}</span></p>
                                        <div>
                                            {selectedValue?.pO_IsPOStatusClosed && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.pO_IsPOStatusClosed === false || selectedValue?.pO_IsPOStatusClosed === null) && (
                                                <Tooltip title="ADD" arrow placement="bottom">
                                                    <span>
                                                        <button onClick={() => setPopupAddOne(true)} className="bg-white txt-prp-color border border-prp px-1 py-1 rounded">
                                                            <IoMdAdd h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}

                                            <Tooltip title="View" arrow placement="bottom">
                                                <span>
                                                    <button onClick={() => setPopupAddOneView(true)} className="bg-white txt-prp-color border border-prp px-1 py-1 rounded">
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div class={`order-tracking ${selectedValue?.piI_IsClosed === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center gap-1 items-center">
                                        <p>PI Issued<br /><span>{formatDateTest(selectedValue?.piI_CloseDate)}</span></p>
                                        <div>
                                            {selectedValue?.piI_IsClosed && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.piI_IsClosed === false || selectedValue?.piI_IsClosed === null) && (
                                                <Tooltip title="EDIT" arrow placement="bottom">
                                                    <span>
                                                        <button
                                                            onClick={() => setPopupAddTwo(true)}
                                                            className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.pO_IsPOStatusClosed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        >
                                                            <BiEdit h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            {/* {(selectedValue?.piI_IsClosed === true) && ( */}
                                            <Tooltip title="VIEW" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddTwoView(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.pO_IsPOStatusClosed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.pO_IsPOStatusClosed !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                            {/* )} */}
                                        </div>
                                    </div>
                                </div>
                                <div class={`order-tracking ${selectedValue?.piC_IsConfirmed === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>PI Confirmation By Customer <br /> <span>{formatDateTest(selectedValue?.piC_CloseDate)}</span></p>
                                        <div>
                                            {selectedValue?.piC_IsConfirmed && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.piC_IsConfirmed === false || selectedValue?.piC_IsConfirmed === null) && (
                                                <Tooltip title="EDIT" arrow placement="bottom">
                                                    <span>
                                                        <button
                                                            onClick={() => setPopupAddTrackFive(true)}
                                                            className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.piI_IsClosed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                            disabled={selectedValue?.piI_IsClosed !== true} // Disable if Document Send step is not completed
                                                        >
                                                            <BiEdit h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="VIEW" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddTrackFiveView(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.piI_IsClosed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.piI_IsClosed !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className="mx-0 flex justify-center text-center mt-1">
                                            <span className={`${selectedValue?.plR_IsPaymentOrLCClosed === true ? 'div-boder-completed' : 'div-boder-pending'}`}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row  flex flex-row-reverse  justify-content-between mt-1">
                                <div class={`order-tracking-revs ${selectedValue?.plR_IsPaymentOrLCClosed === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Advance Payment / LC <br />  <span>{formatDateTest(selectedValue?.plR_PaymentOrLCClosedDate)}</span></p>
                                        <div>
                                            {selectedValue?.plR_PaymentOrLCClosedDate && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.plR_IsPaymentOrLCClosed === false) && (
                                                <Tooltip title="EDIT" arrow placement="bottom">
                                                    <span>
                                                        <button
                                                            onClick={() => setPopupAddThree(true)}
                                                            className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.piC_IsConfirmed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                            disabled={selectedValue?.piC_IsConfirmed !== true} // Disable if Document Send step is not completed
                                                        >
                                                            <BiEdit h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="VIEW" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddThreeView(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.piC_IsConfirmed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.piC_IsConfirmed !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div class={`order-tracking-revs ${selectedValue?.oA_IsOrderAccepted === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Order Accepted<br /> <span>{formatDateTest(selectedValue?.oA_OrderAcceptedClosedDate)}</span></p>
                                        <div>
                                            {selectedValue?.oA_IsOrderAccepted && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.oA_IsOrderAccepted === false || selectedValue?.oA_IsOrderAccepted === null) && (
                                                <Tooltip title="EDIT" arrow placement="bottom">
                                                    <span>
                                                        <button
                                                            onClick={() => setPopupAddFour(true)}
                                                            className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.plR_IsPaymentOrLCClosed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                            disabled={selectedValue?.plR_IsPaymentOrLCClosed !== true} // Disable if Document Send step is not completed
                                                        >
                                                            <BiEdit h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="VIEW" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddFourView(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.plR_IsPaymentOrLCClosed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.plR_IsPaymentOrLCClosed !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div class={`order-tracking-revs ${selectedValue?.ouP_IsOrderUnderProcess === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Order Under Process <br /> <span>{formatDateTest(selectedValue?.ouP_OrderUnderProcessClosedDate)}</span></p>
                                        <div>
                                            {selectedValue?.ouP_IsOrderUnderProcess && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.ouP_IsOrderUnderProcess === false || selectedValue?.ouP_IsOrderUnderProcess === null) && (
                                                <Tooltip title="EDIT" arrow placement="bottom">
                                                    <span>
                                                        <button
                                                            onClick={() => setPopupAddFive(true)}
                                                            className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.oA_IsOrderAccepted === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                            disabled={selectedValue?.oA_IsOrderAccepted !== true} // Disable if Document Send step is not completed
                                                        >
                                                            <BiEdit h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="VIEW" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddFiveView(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.oA_IsOrderAccepted === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.oA_IsOrderAccepted !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className="mx-0 flex justify-center text-center mt-1">
                                            <span className={`${selectedValue?.bI_IsBookingIssueAccepted === true ? 'div-boder-completed' : 'div-boder-pending'}`}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row flex justify-content-between mt-1">
                                <div class={`order-tracking ${selectedValue?.bI_IsBookingIssueAccepted === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Booking Issued <br /> <span>{formatDateTest(selectedValue?.bI_BookingIssueAcceptedDate)}</span></p>
                                        <div>
                                            {selectedValue?.bI_IsBookingIssueAccepted && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex gap-2 justify-center items-center">
                                        {(selectedValue?.bI_IsBookingIssueAccepted === false || selectedValue?.bI_IsBookingIssueAccepted === null) && (
                                            <Tooltip title="EDIT" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddSix(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.ouP_IsOrderUnderProcess === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.ouP_IsOrderUnderProcess !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BiEdit h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        )}
                                        <Tooltip title="VIEW" arrow placement="bottom">
                                            <span>
                                                <button
                                                    onClick={() => setPopupAddSixView(true)}
                                                    className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.ouP_IsOrderUnderProcess === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                    disabled={selectedValue?.ouP_IsOrderUnderProcess !== true} // Disable if Document Send step is not completed
                                                >
                                                    <BsEyeFill h-8 w-8 />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div class={`order-tracking ${selectedValue?.cuL_IsContainersUnderLoadingClose === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Containers Under Loading<br /><span>{formatDateTest(selectedValue?.cuL_ContainersUnderLoadingClosedDateTime)}</span></p>
                                        <div>
                                            {selectedValue?.cuL_IsContainersUnderLoadingClose && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex gap-2 justify-center items-center">
                                        {(selectedValue?.cuL_IsContainersUnderLoadingClose === false || selectedValue?.cuL_IsContainersUnderLoadingClose === null) && (
                                            <Tooltip title="EDIT" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddSeven(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.bI_IsBookingIssueAccepted === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.bI_IsBookingIssueAccepted !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BiEdit h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        )}
                                        <Tooltip title="VIEW" arrow placement="bottom">
                                            <span>
                                                <button
                                                    onClick={() => setPopupAddSevenView(true)}
                                                    className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.bI_IsBookingIssueAccepted === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                    disabled={selectedValue?.bI_IsBookingIssueAccepted !== true} // Disable if Document Send step is not completed
                                                >
                                                    <BsEyeFill h-8 w-8 />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div class={`order-tracking ${selectedValue?.iN_IsInvoiceGenerateClose === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Invoice Generated<br /><span>{formatDateTest(selectedValue?.iN_InvoiceGenerateClosedDateTime)}</span></p>
                                        <div>
                                            {selectedValue?.iN_IsInvoiceGenerateClose && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.iN_IsInvoiceGenerateClose === false || selectedValue?.iN_IsInvoiceGenerateClose === null) && (
                                                <Tooltip title="EDIT" arrow placement="bottom">
                                                    <span>
                                                        <button
                                                            onClick={() => setPopupAddEight(true)}
                                                            className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.cuL_IsContainersUnderLoadingClose === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                            disabled={selectedValue?.cuL_IsContainersUnderLoadingClose !== true} // Disable if Document Send step is not completed
                                                        >
                                                            <BiEdit h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="VIEW" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddEightView(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.cuL_IsContainersUnderLoadingClose === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.cuL_IsContainersUnderLoadingClose !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className="mx-0 flex justify-center text-center mt-1">
                                            <span className={`${selectedValue?.iN_IsInvoiceGenerateClose === true ? 'div-boder-completed' : 'div-boder-pending'}`}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row  flex flex-row-reverse  justify-content-between mt-1">
                                <div class={`order-tracking-revs ${selectedValue?.biD_IsBIDraftIssueClose === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>BL Draft Issued<br /><span>{formatDateTest(selectedValue?.biD_BIDraftIssueClosedDateTime)}</span></p>
                                        <div>
                                            {selectedValue?.biD_IsBIDraftIssueClose && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex gap-2 justify-center items-center">
                                        {(selectedValue?.biD_IsBIDraftIssueClose === false || selectedValue?.biD_IsBIDraftIssueClose === null) && (
                                            <Tooltip title="EDIT" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddNine(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.iN_IsInvoiceGenerateClose === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.iN_IsInvoiceGenerateClose !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BiEdit h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        )}
                                        <Tooltip title="VIEW" arrow placement="bottom">
                                            <span>
                                                <button
                                                    onClick={() => setPopupAddNineView(true)}
                                                    className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.iN_IsInvoiceGenerateClose === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                    disabled={selectedValue?.iN_IsInvoiceGenerateClose !== true} // Disable if Document Send step is not completed
                                                >
                                                    <BsEyeFill h-8 w-8 />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div class={`order-tracking-revs ${selectedValue?.fbI_IsFinalBIDraftIssueClose === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Final BL Issued<br /><span>{formatDateTest(selectedValue?.fbI_FinalBIDraftIssueClosedDateTime)}</span></p>
                                        <div>
                                            {selectedValue?.fbI_IsFinalBIDraftIssueClose && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.fbI_IsFinalBIDraftIssueClose === false || selectedValue?.fbI_IsFinalBIDraftIssueClose === null) && (
                                                <Tooltip title="EDIT" arrow placement="bottom">
                                                    <span>
                                                        <button
                                                            onClick={() => setPopupAddTen(true)}
                                                            className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.biD_IsBIDraftIssueClose === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                            disabled={selectedValue?.biD_IsBIDraftIssueClose !== true} // Disable if Document Send step is not completed
                                                        >
                                                            <BiEdit h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="VIEW" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddTenView(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.biD_IsBIDraftIssueClose === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.biD_IsBIDraftIssueClose !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div class={`order-tracking-revs ${selectedValue?.faP_IsFinalAmountToPayClose === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Final Amount to Pay<br /><span>{formatDateTest(selectedValue?.faP_FinalAmountToPayClosedDateTime)}</span></p>
                                        <div>
                                            {selectedValue?.faP_IsFinalAmountToPayClose && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.faP_IsFinalAmountToPayClose === false || selectedValue?.faP_IsFinalAmountToPayClose === null) && (
                                                <Tooltip title="EDIT" arrow placement="bottom">
                                                    <span>
                                                        <button
                                                            onClick={() => setPopupAddTrackOne(true)}
                                                            className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.fbI_IsFinalBIDraftIssueClose === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                            disabled={selectedValue?.fbI_IsFinalBIDraftIssueClose !== true} // Disable if Document Send step is not completed
                                                        >
                                                            <BiEdit h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="VIEW" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddTrackOneView(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.fbI_IsFinalBIDraftIssueClose === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.fbI_IsFinalBIDraftIssueClose !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className="mx-0 flex justify-center text-center mt-1">
                                            <span className={`${selectedValue?.pR_IsPaymentReceived === true ? 'div-boder-completed' : 'div-boder-pending'}`}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row flex justify-content-between mt-1">

                                <div class={`order-tracking ${selectedValue?.ddS_IsDocumentSendDHL_Submitted === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Document Send Through <br /> DHL / Submitted<br /><span>{formatDateTest(selectedValue?.ddS_DocumentSendDHL_SubmittedClosedDate)}</span></p>
                                        <div>
                                            {selectedValue?.ddS_IsDocumentSendDHL_Submitted && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.ddS_IsDocumentSendDHL_Submitted === false || selectedValue?.ddS_IsDocumentSendDHL_Submitted === null) && (
                                                <Tooltip title="EDIT" arrow placement="bottom">
                                                    <span>
                                                        <button
                                                            onClick={() => setPopupAddTrackTwo(true)}
                                                            className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.faP_IsFinalAmountToPayClose === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                            disabled={selectedValue?.faP_IsFinalAmountToPayClose !== true} // Disable if Document Send step is not completed
                                                        >
                                                            <BiEdit h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="VIEW" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddTrackTwoView(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.faP_IsFinalAmountToPayClose === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.faP_IsFinalAmountToPayClose !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div class={`order-tracking ${selectedValue?.pR_IsPaymentReceived === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Final Payment Received<br /><span>{formatDateTest(selectedValue?.pR_PaymentReceivedClosedDate)}</span></p>
                                        <div>
                                            {selectedValue?.pR_IsPaymentReceived && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.pR_IsPaymentReceived === false || selectedValue?.pR_IsPaymentReceived === null) && (
                                                <Tooltip title="EDIT" arrow placement="bottom">
                                                    <span>
                                                        <button
                                                            onClick={() => setPopupAddTrackSix(true)}
                                                            className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.ddS_IsDocumentSendDHL_Submitted === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                            disabled={selectedValue?.ddS_IsDocumentSendDHL_Submitted !== true} // Disable if Document Send step is not completed
                                                        >
                                                            <BiEdit h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="VIEW" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddTrackSixView(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.ddS_IsDocumentSendDHL_Submitted === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.ddS_IsDocumentSendDHL_Submitted !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>

                                    </div>
                                </div>
                                <div class={`order-tracking ${selectedValue?.poC_IsPOClosed === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>PO closed <br /><span>{formatDateTest(selectedValue?.poC_POClosedDateTime)}</span></p>
                                        <div>
                                            {selectedValue?.poC_IsPOClosed && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            {(selectedValue?.poC_IsPOClosed === false || selectedValue?.poC_IsPOClosed === null) && (
                                                <Tooltip title="EDIT" arrow placement="bottom">
                                                    <span>
                                                        <button
                                                            onClick={() => setPopupAddTrackThree(true)}
                                                            className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.pR_IsPaymentReceived === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                            disabled={selectedValue?.pR_IsPaymentReceived !== true} // Disable if Document Send step is not completed
                                                        >
                                                            <BiEdit h-8 w-8 />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="VIEW" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddTrackThreeView(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.pR_IsPaymentReceived === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.pR_IsPaymentReceived !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BsEyeFill h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className="mx-0 flex justify-center text-center mt-1">
                                            <span className={`${selectedValue?.poC_IsPOClosed === true ? 'div-boder-completed' : 'div-boder-pending'}`}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row  flex flex-row-reverse  justify-content-between mt-1">
                                <div class={`order-tracking-revs ${selectedValue?.mC_IsCommissionClosed === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Manage Commission <br /><span>{formatDateTest(selectedValue?.oC_OrderCompleteClosedDate)}</span></p>
                                        <div>
                                            {selectedValue?.mC_IsCommissionClosed && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex gap-2 justify-center items-center">
                                        {(selectedValue?.mC_IsCommissionClosed === false || selectedValue?.mC_IsCommissionClosed === null) && (
                                            <Tooltip title="EDIT" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddTrackFourComm(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.poC_IsPOClosed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.poC_IsPOClosed !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BiEdit h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        )}
                                        <Tooltip title="VIEW" arrow placement="bottom">
                                            <span>
                                                <button
                                                    onClick={() => setPopupAddTrackFourViewComm(true)}
                                                    className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.poC_IsPOClosed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                    disabled={selectedValue?.poC_IsPOClosed !== true} // Disable if Document Send step is not completed
                                                >
                                                    <BsEyeFill h-8 w-8 />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div class={`order-tracking-revs ${selectedValue?.oC_IsOrderCompleteClosed === true ? 'completed' : ''}`}>
                                    <span class="is-complete"></span>
                                    <div className="flex flex-col justify-center items-center">
                                        <p>Order Completed <br /><span>{formatDateTest(selectedValue?.oC_OrderCompleteClosedDate)}</span></p>
                                        <div>
                                            {selectedValue?.oC_IsOrderCompleteClosed && (
                                                <div className="text-xs mb-0.5">
                                                    Uploaded By: <span>{selectedValue?.creatorName}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex gap-2 justify-center items-center">
                                        {(selectedValue?.oC_IsOrderCompleteClosed === false || selectedValue?.oC_IsOrderCompleteClosed === null) && (
                                            <Tooltip title="EDIT" arrow placement="bottom">
                                                <span>
                                                    <button
                                                        onClick={() => setPopupAddTrackFour(true)}
                                                        className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.poC_IsPOClosed || selectedValue?.mC_IsCommissionClosed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                        disabled={selectedValue?.poC_IsPOClosed || selectedValue?.mC_IsCommissionClosed !== true} // Disable if Document Send step is not completed
                                                    >
                                                        <BiEdit h-8 w-8 />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        )}
                                        <Tooltip title="VIEW" arrow placement="bottom">
                                            <span>
                                                <button
                                                    onClick={() => setPopupAddTrackFourView(true)}
                                                    className={`txt-prp-color border px-1 py-1 rounded ${selectedValue?.poC_IsPOClosed || selectedValue?.mC_IsCommissionClosed === true ? 'bg-white border-prp' : 'bg-gray-300 border-gray-300 cursor-not-allowed'}`}
                                                    disabled={selectedValue?.poC_IsPOClosed || selectedValue?.mC_IsCommissionClosed !== true} // Disable if Document Send step is not completed
                                                >
                                                    <BsEyeFill h-8 w-8 />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {popupAddOne && (
                <PopUp>
                    <ManagePOEdit
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddOne(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}

            {popupAddOneView && (
                <PopUp>
                    <div className="pb-10 bg-white rounded-lg">
                        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                            <h1 className=" font-weight-[400]">View PO</h1>
                            <div className="flex items-center text-sm"></div>
                        </div>

                        <div className="p-5 mx-auto">
                            <ManagePOView
                                selectedValue={selectedValue}
                            />
                        </div>
                        <div className="flex justify-center gap-5 pt-3">
                            <div onClick={() => setPopupAddOneView(false)}>
                                <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}

            {popupAddTwo && (
                <PopUp>
                    <ManagePOIssuedEdit
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTwo(false)
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddTwoView && (
                <PopUp>
                    <ManagePOIssuedView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTwoView(false)
                        }}
                    />
                </PopUp>
            )}

            {popupAddTrackFive && (
                <PopUp>
                    <EditPIConfirmedbyCustomer
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackFive(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddTrackFiveView && (
                <PopUp>
                    <ManagePIConfirmedbyCustomerView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackFiveView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddThree && (
                <PopUp>
                    <PaymentLCReceivedEdit
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        onCancel={() => {
                            setPopupAddThree(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddThreeView && (
                <PopUp>
                    <PaymentLCReceivedView
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        onCancel={() => {
                            setPopupAddThreeView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddFour && (
                <PopUp>
                    <EditOrderAccepted
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddFour(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddFourView && (
                <PopUp>
                    <ManageOrderAcceptedView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddFourView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddFive && (
                <PopUp>
                    <EditOrderUnderProcess
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddFive(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddFiveView && (
                <PopUp>
                    <ManageOrderUnderProcessView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddFiveView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddSix && (
                <PopUp>
                    <EditBookingIssue
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddSix(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddSixView && (
                <PopUp>
                    <ManageBookingIssueView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddSixView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddSeven && (
                <PopUp>
                    <EditContainersUnderLoading
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddSeven(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddSevenView && (
                <PopUp>
                    <ManageContainersUnderLoadingView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddSevenView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddEight && (
                <PopUp>
                    <EditInvoiceGenerated
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddEight(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddEightView && (
                <PopUp>
                    <ManageInvoiceGeneratedView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddEightView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddNine && (
                <PopUp>
                    <EditBiDraftIssued
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddNine(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddNineView && (
                <PopUp>
                    <ManageBiDraftIssuedView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddNineView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddTen && (
                <PopUp>
                    <ManageFinalBiIssuedEdit
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTen(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddTenView && (
                <PopUp>
                    <ManageFinalBiIssuedView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTenView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddTrackOne && (
                <PopUp>
                    <EditFinalAmountToPay
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackOne(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddTrackOneView && (
                <PopUp>
                    <ManageFinalAmountToPayView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackOneView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddTrackTwo && (
                <PopUp>
                    <EditDocumentSend
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackTwo(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddTrackTwoView && (
                <PopUp>
                    <ManageDocumentSendView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackTwoView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddTrackThree && (
                <PopUp>
                    <EditAwbGeneratedPending
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackThree(false)
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddTrackThreeView && (
                <PopUp>
                    <ManageAwbGeneratedPendingView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackThreeView(false)
                        }}
                    />
                </PopUp>
            )}

            {popupAddTrackFour && (
                <PopUp>
                    <EditOrderCompletedPending
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackFour(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddTrackFourView && (
                <PopUp>
                    <ManageOrderCompletedPendingView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackFourView(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddTrackFourComm && (
                <PopUp>
                    <ManageCommissionEdit
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackFourComm(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddTrackFourViewComm && (
                <PopUp>
                    <ManageCommissionView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackFourViewComm(false);
                        }}
                    />
                </PopUp>
            )}

            {popupAddTrackSix && (
                <PopUp>
                    <ManagePaymentReceivedEdit
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackSix(false);
                            window.location.reload();
                        }}
                    />
                </PopUp>
            )}
            {popupAddTrackSixView && (
                <PopUp>
                    <ManagePaymentReceivedView
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupAddTrackSixView(false);
                        }}
                    />
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    );
}

export default ManageTrackingStatusDesign;
