import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";
import { Tooltip } from "@mui/material";
import { IoMdEye } from "react-icons/io";
import PopUp from "../../general-components/PopUp.componenet";
import PopupRemarks from "./PopupRemarks";

export default function ViewBiDraftIssued({ selectedValue }) {
    const biD_IsBIDraftIssueClose = selectedValue?.biD_IsBIDraftIssueClose ? 'True' : 'False';

    const [selectedLogList, setSelectedLogList] = useState([]);
    const [visiblePopup, setVisiblePopup] = useState(false)

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const biD_BIDraftIssueClosedDate = formatDateTest(selectedValue?.biD_BIDraftIssueClosedDate);

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';

        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleString('en-US', options);
    };

    const handleVisibleOpen = () => {
        // Access the first item's log list inside biDraftIssuedImagesList
        const selectedLogs = selectedValue?.biDraftIssuedImagesList?.[0]?.biDraftIssuedLogList || [];
        setSelectedLogList(selectedLogs);
        setVisiblePopup(true);
    };


    return (
        <>
            <div>
                {/* Display remarks log */}
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">
                    <div className="mb-4">
                        <div className="flex flex-col">
                            <label htmlFor="id" className="text-xs font-[400]">
                                Diyan Remarks
                            </label>
                            {selectedValue?.biDraftIssuedRemarkLogList?.map((remark, index) => (
                                <React.Fragment key={index}>
                                    <input
                                        type="text"
                                        disabled
                                        value={remark?.remarks}
                                        className="p-2 min-w-[14rem] bg-white uppercase text-gray-400 text-xs"
                                    />
                                    <p className="text-xs text-gray-500">
                                        By: {remark?.creatorName} | Date: {formatDateTest1(remark?.createdDate)}
                                    </p>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>

                    {/* Display comments log */}
                    <div className="mb-4">
                        <div className="flex flex-col">
                            <label htmlFor="id" className="text-xs font-[400]">
                                {/* BL Draft Issued Comments */}
                                Client Comment
                            </label>
                            {selectedValue?.biDraftIssuedCommentLogList?.map((comment, index) => (
                                <React.Fragment key={index}>
                                    <input
                                        type="text"
                                        disabled
                                        value={comment?.comments}
                                        className="p-2 min-w-[14rem] bg-white uppercase text-gray-400 text-xs"
                                    />
                                    <p className="text-xs text-gray-500">
                                        By: {comment?.creatorName} | Date: {formatDateTest1(comment?.createdDate)}
                                    </p>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>

                    {/* Display uploaded images */}
                    <div>
                        {selectedValue?.biDraftIssuedImagesList?.map((image, index) => (
                            <React.Fragment key={index}>
                                <CustomFileViewer
                                    fileURL={image.imageURL}
                                    fileName={image.originalFileName ?? ''}
                                    label={`BL Draft Issued Upload`}
                                />
                            </React.Fragment>
                        ))}
                    </div>

                    {selectedValue?.biDraftIssuedImagesList?.map((image, index) => {
                        // Extract the latest log with a non-empty remark
                        const latestLog = image.biDraftIssuedLogList
                            ?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) // Sort logs by createdDate in descending order
                            ?.find(log => log.remarks); // Find the first log with a non-empty remark

                        return (
                            <React.Fragment key={index}>
                                <div className="flex flex-col">
                                    <div className="flex flex-col gap-4 border border-gray-300 px-4 py-2 mb-6 rounded-lg shadow-sm">
                                        {latestLog ? (
                                            <>
                                                <div className="flex items-center justify-between border-b border-gray-300 ">
                                                    <label htmlFor="id" className="text-xs font-medium ">
                                                        BL Draft Issued Accept/Reject Remarks
                                                    </label>
                                                    <Tooltip title="View Remark History" arrow placement="bottom">
                                                        <button
                                                            onClick={handleVisibleOpen}
                                                            className="hover:bg-gray-100 p-2 rounded-full"
                                                        >
                                                            <IoMdEye className="text-xl text-gray-500 hover:text-purple-600" />
                                                        </button>
                                                    </Tooltip>
                                                </div>

                                                <div className=" space-y-2">
                                                    <div className="flex justify-start gap-2 items-center">
                                                        <p className="text-xs font-medium text-gray-500">
                                                            Status:
                                                        </p>
                                                        <span className="text-xs text-purple-600 font-medium">
                                                            {latestLog.statusName}
                                                        </span>
                                                    </div>
                                                    <div className="flex justify-start gap-2 items-center">
                                                        <p className="text-xs font-medium text-gray-500">
                                                            Remarks:
                                                        </p>
                                                        <span className="text-xs text-purple-600">
                                                            {latestLog.remarks}
                                                        </span>
                                                    </div>
                                                    <div className="flex justify-start gap-2 items-center">
                                                        <p className="text-xs font-medium text-gray-500">
                                                            Created By:
                                                        </p>
                                                        <span className="text-xs text-purple-600">
                                                            {latestLog.creatorName}
                                                        </span>
                                                    </div>
                                                    <div className="flex justify-start gap-2 items-center">
                                                        <p className="text-xs font-medium text-gray-500">
                                                            Date:
                                                        </p>
                                                        <span className="text-xs text-purple-600">
                                                            {formatDateTest1(latestLog.createdDate)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <p className="text-xs text-gray-400">
                                                No remarks available.
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}

                    {selectedValue?.biDraftIssuedImagesList?.map((image, index) => (
                        <React.Fragment key={index}>
                            <CustomViewLabel label="Status" value={image.statusName} />
                        </React.Fragment>
                    ))}



                    {/* <div>
                        <div className="flex flex-col">
                            <label htmlFor="id" className="text-xs font-[400]">
                                Remark
                            </label>

                            <div className="">
                                <Tooltip title="VIEW REMARK HISTORY" arrow placement="bottom">
                                    <span>
                                        <button onClick={handleVisibleOpen} className="">
                                            <IoMdEye className="h-10 w-9 txt-prp-color" />
                                        </button>
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </div> */}


                    {/* <CustomViewLabel label="Remark" value={""} /> */}
                    <CustomViewLabel label="BL Draft Issued Closed Date" value={formatDateTest1(selectedValue?.biD_BIDraftIssueClosedDate)} />
                    <CustomViewLabel label="BL Draft Issued Closed" value={biD_IsBIDraftIssueClose} />
                    <CustomViewLabel label="BL Draft Issued Close Date & Time" value={formatDateTest1(selectedValue?.biD_BIDraftIssueClosedDateTime)} />
                </div>
            </div>

            {visiblePopup && (
                <PopUp>
                    <PopupRemarks
                        piIssuedLogList={selectedLogList}
                        onCancel={() => setVisiblePopup(false)}
                    />
                </PopUp>
            )}
        </>
    );
}
