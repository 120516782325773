import React, { useEffect, useState } from 'react'
import CustomButton2 from '../../general-components/CustomButton2.component'
import CustomButton1 from '../../general-components/CustomButton1.component'
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomInputNumber from '../../general-components/CustomInputNumber.component';
import CustomInputNumberDecimal from '../../general-components/CustomInputNumberDecimal.component';
import CustomDate from '../../general-components/CustomDate.component';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomAlphatext from './../../general-components/CustomAlphatext.component';
import CustomSelect1 from '../../general-components/CustomSelect1.component';
import axios from 'axios';
import CustomSelect2 from '../../general-components/CustomSelect2.component';

export default function ManageFinalInvoicePaymentRecordEdit({ onCancel, selectedValue }) {
    const [test4, setTest4] = useState("");
    const [test5, setTest5] = useState("");
    const [test6, setTest6] = useState("");
    const [test8, setTest8] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [deptData, setDeptData] = useState([]);
    const [selectedDept, setSelectedDept] = useState(null);
    const [selectedDeptName, setSelectedDeptName] = useState(null);
    const [portName, setPortName] = useState("");
    const [portCode, setPortCode] = useState("");
    const dataActive = { searchText: "", isActive: true };

    const fetchDeptApiData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPortList`, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data;
                setDeptData(sortedData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching port Code Data");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDeptApiData();
    }, [])

    useEffect(() => {
        if (selectedValue) {
            console.log("Selected Value:", selectedValue); // Log selectedValue
            setSelectedDept(selectedValue.portId);
            setSelectedDeptName(selectedValue.portName);
            setPortCode(selectedValue.portCode);
            setTest4(selectedValue.freight);
            setTest8(selectedValue.portCode);
            setTest6(selectedValue.sbNo);
            setTest5(selectedValue.sbDate ? selectedValue.sbDate.split("T")[0] : null);   // Handle null/undefined
        } else {
            console.log("No selected value provided"); // Log if selectedValue is null/undefined
        }
    }, [selectedValue]);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            let requestBody = {};
            requestBody = {
                id: selectedValue.id,
                freight: test4,
                // portCode: test8 || "",
                portId: selectedDept || 0,
                sbNo: test6 || "",
                sbDate: test5 || null,
            };
            console.log("Request Body:", requestBody); // Debugging: check request body

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageMIS/UpdateMIS_FinalInvoicePayment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            console.log("Response Data:", responseData); // Debugging: check response data

            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                // setUpdatePopup(false);
                setTimeout(() => {
                    onCancel()
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing operation:", error);
            toast.error("Error performing operation");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePaperTypeChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedDept(selectedOption.value);
            setSelectedDeptName(selectedOption.label);
        } else {
            setSelectedDept(null); // Reset to null when cleared
            setSelectedDeptName("");
            setPortCode("");
        }
    };


    useEffect(() => {
        if (selectedDept) {
            const selectedCustomer = deptData.find(c => c.id === selectedDept);

            if (selectedCustomer) {
                setPortCode(selectedCustomer.portCode);
                // setCountryNameRead(selectedCustomer.countryName);

                // // Check if the selected customer's customerTypeId is 2
                // if (selectedCustomer.customerTypeId === 2) {
                //     setIsCpt(2);
                //     return; // Exit early if the selected customerTypeId is already 2
                // }

                // // Find the parent customer in the customerData array
                // const parentCustomer = customerData.find(c => c.customerName === selectedCustomer.parentCustomer);

                // // Check if the parent customer's customerTypeId is 2
                // if (parentCustomer && parentCustomer.customerTypeId === 2) {
                //     setIsCpt(2);
                //     return;
                // }
            }
        }
    }, [selectedDept, deptData]);

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Edit Final Invoice & Payment Records</h1>
                    <div className="flex  item-center text-sm"></div>
                </div>

                <div className="p-4 gap-4 max-h-[70vh] overflow-y-auto max-w-full w-[700px]">
                    <div className="grid grid-cols-1  gap-4 md:grid-cols-2 ">
                        <CustomInputNumberDecimal label="FREIGHT" placeholder="Enter" isRequired={false}
                            value={test4}
                            onChange={setTest4}
                        />
                        {/* <CustomAlphatext label="Port Code" placeholder="Enter" isRequired={false}
                            value={test8}
                            onChange={setTest8}
                        /> */}
                        {/* <CustomSelect1 label={"Port Code"} options={options} isRequired={false} /> */}
                        <CustomSelect2 label={"Port Name"}
                            //  options={options} 
                            options={deptData.map(depData => ({
                                value: depData.id,
                                label: depData.portName
                            }))}
                            isClearable={true}
                            value={
                                selectedDept && selectedDeptName
                                    ? { value: selectedDept, label: selectedDeptName }
                                    : null // Display null if no value is selected
                            }
                            onChange={handlePaperTypeChange}
                            isRequired={false}
                        />

                        <CustomTextField2 label="Port Code" placeholder="Enter" isRequired={false}
                            value={portCode}
                            onChange={setPortCode}
                            readOnly
                        />

                        <CustomInputNumber label="SB No" placeholder="Enter" isRequired={false}
                            value={test6}
                            onChange={setTest6}
                        />
                        <CustomDate label="SB Date" placeholder="Enter" isRequired={false}
                            value={test5}
                            onChange={setTest5}
                        />
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" type="submit" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
