import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomEmail from "../../general-components/CustomEmail.component";
import CustomNumber from "../../general-components/CustomNumber.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomInputNumber from "../../general-components/CustomInputNumber.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";

export default function EditContactDetailsEditPopup({
    cName,
    currentCustomerId,
    contactIdEdit,
    conPersonEdit,
    setConPersonEdit,
    phoneNoEdit,
    setphoneNoEdit,
    emailEdit,
    setEmailEdit,
    statusCon,
    setStatusCon,
    onCancel
}) {
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem('DIYAN_IE_authToken');
    const [status, setStatus] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                id: contactIdEdit,
                customerId: currentCustomerId,
                contactPerson: conPersonEdit || "",
                mobileNo: phoneNoEdit,
                emailId: emailEdit,
                isActive: statusCon
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Customer/SaveContactDetails`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                console.log("Record Updated successfully:", responseData.message);
                toast.success("Record Updated successfully");
                // console.log("Record Inserted successfully:", responseData.message);
                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing customer operation:", error);
            toast.error("Error performing customer operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit Contact Details</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[900px]">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField2
                                        label={"Customer Name"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        value={cName}
                                        readOnly={true}
                                    ></CustomTextField2>
                                </td>
                                <td>
                                    <CustomTextField2
                                        label={"Contact Person"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        value={conPersonEdit}
                                        onChange={setConPersonEdit}
                                    ></CustomTextField2>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomInputNumber
                                        label={"Mobile Number"}
                                        placeholder={"ENTER"}
                                        value={phoneNoEdit}
                                        isRequired={false}
                                        onChange={setphoneNoEdit}
                                    ></CustomInputNumber>
                                </td>
                                <td>
                                    <CustomEmail
                                        label={"Email Id"}
                                        placeholder={"ENTER"}
                                        value={emailEdit}
                                        onChange={setEmailEdit}
                                    ></CustomEmail>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomCheckBox3
                                        label={"Is Active"}
                                        state={statusCon}
                                        setState={setStatusCon}
                                    ></CustomCheckBox3>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={2000} />
        </>
    )
}
