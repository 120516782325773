import * as React from "react";
import { useState, useEffect } from "react";
import { Tab, TabContainer, TabContent } from "../../general-components/Tab.component";
import axios from 'axios';
import Cookies from "js-cookie"; // Import js-cookie for handling cookies

import ClientDetails from "../../section-components/ManageParentCustomersAdd/ClientDetails";
import ContactDetails from "../../section-components/ManageParentCustomersAdd/ContactDetails";
import BillingDetails from "../../section-components/ManageParentCustomersAdd/BillingDetails";
import ShippingDetails from "../../section-components/ManageParentCustomersAdd/ShippingDetails";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ManageParentCustomersAdd({ parentCustomerId, parentCustomer, onCancel }) {
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [cName, setCName] = useState("");
  const [mobile, setMobile] = useState("");
  const [landLineNumber, setLandLineNumber] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [countryId, setCountryId] = useState("");

  // contactDetailsList
  const [contactPersonCon, setContactPersonCon] = useState("");
  const [mobileNoCon, setMobileNoCon] = useState("");
  const [emailIdCon, setEmailIdCon] = useState("");
    const [statusCon, setStatusCon] = useState(true);

  // billingDetailsList
  const [streetNameBilling, setStreetNameBilling] = useState("");
  let [checkboxSameAddress, setCheckboxSameAddress] = useState(false);

  // shippingDetailsList
  const [streetNameShipping, setStreetNameShipping] = useState("");

  const [allTerData, setAllTerData] = useState([]);
  const [allTerDataTemp, setAllTerDataTemp] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryTemp, setSelectedCountryTemp] = useState(null);

  const token = localStorage.getItem('DIYAN_IE_authToken');

  const handleSaveAndContinue = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };

  const handleBack = () => {
    setActiveTab((prevTab) => prevTab - 1);
  };

  const [countryData, setCountryData] = useState([]);
  const dataActive = { searchText: "", isActive: true };

  const fetchData = async (url, setData, errorMsg) => {
    setIsLoading(true);
    try {
      const response = await axios.post(url, dataActive, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetCountryList`, setCountryData, "Error fetching Country Data");
    fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetCountryList`, setAllTerData, "Error fetching Country Data");
    fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetCountryList`, setAllTerDataTemp, "Error fetching Country Data");
  }, []);

  // Update shipping details when checkboxSameAddress is toggled or billing details change
  useEffect(() => {
    if (checkboxSameAddress) {
      setSelectedCountryTemp(selectedCountry); // Update child country with the parent country
      setStreetNameShipping(streetNameBilling);

    } else {
      setSelectedCountryTemp(null); // Reset child country when unchecked
      setStreetNameShipping("");
    }
  }, [checkboxSameAddress, selectedCountry, streetNameBilling]);

   // Function to save form data into cookies
      const saveFormDataToCookies = () => {
          const formData = {
              id: 0,
              moduleType: "Cust",
              customerName: cName,
              mobileNo: mobile,
              landlineNumber: landLineNumber || "",
              customerTypeId: customerId || 0,
              emailId: emailIdCon || "",
              countryId: countryId || 0,
              contactName: contactPersonCon || "",
              leadStatusId: 2,
              isActive: true,
              contactDetailsList: [
                  {
                      id: 0,
                      customerId: 0,
                      contactPerson: contactPersonCon || "",
                      mobileNo: mobileNoCon || "",
                      emailId: emailIdCon || "",
                      isActive: statusCon,
                  },
              ],
              billingDetailsList: [
                  {
                      id: 0,
                      customerId: 0,
                      streetName: streetNameBilling || "",
                      countryId: selectedCountry || 0,
                      stateId: 0,
                      postalZipCodde: "",
                      isActive: true,
                  },
              ],
              shippingDetailsList: [
                  {
                      id: 0,
                      customerId: 0,
                      streetName: streetNameShipping || "",
                      countryId: selectedCountryTemp || 0,
                      stateId: 0,
                      postalZipCodde: "",
                      isActive: true,
                  },
              ],
          };
          Cookies.set("customerFormDataParent", JSON.stringify(formData), { expires: 7 }); // Save as JSON string
      };
  
      // Function to load form data from cookies
      const loadFormDataFromCookies = () => {
          const savedData = Cookies.get("customerFormDataParent");
          if (savedData) {
              const parsedData = JSON.parse(savedData);
              setCName(parsedData.customerName || "");
              setMobile(parsedData.mobileNo || "");
              // setParentCId(parsedData.parentCustomerId || "");
              setLandLineNumber(parsedData.landlineNumber || "");
              setCustomerId(parsedData.customerTypeId || "");
              setCountryId(parsedData.countryId || "");
              setContactPersonCon(parsedData.contactName || "");
              setMobileNoCon(parsedData.contactDetailsList[0]?.mobileNo || "");
              setEmailIdCon(parsedData.contactDetailsList[0]?.emailId || "");
              setStatusCon(parsedData.contactDetailsList[0]?.isActive || true);
              setStreetNameBilling(parsedData.billingDetailsList[0]?.streetName || "");
              setCheckboxSameAddress(false); // Reset manually
              setStreetNameShipping(parsedData.shippingDetailsList[0]?.streetName || "");
              setSelectedCountry(parsedData.billingDetailsList[0]?.countryId || 0);
              setSelectedCountryTemp(parsedData.shippingDetailsList[0]?.countryId || 0);
          }
      };
  
      // Clear cookies after submission
      const clearFormCookies = () => {
          Cookies.remove("customerFormDataParent");
      };
  
      useEffect(() => {
          loadFormDataFromCookies(); // Load data on component mount
      }, []);
  
      useEffect(() => {
          saveFormDataToCookies(); // Save data whenever a field changes
      }, [
          cName,
          mobile,
          landLineNumber,
          customerId,
          countryId,
          contactPersonCon,
          mobileNoCon,
          emailIdCon,
          statusCon,
          streetNameBilling,
          checkboxSameAddress,
          streetNameShipping,
          selectedCountry,
          selectedCountryTemp,
      ]);

  const handleCustomerDataSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const requestBody = {
        id: 0,
        customerName: cName,
        moduleType: "Cust",
        mobileNo: mobile,
        parentCustomerId: parentCustomerId || 0,
        landlineNumber: landLineNumber || "",
        customerTypeId: customerId || 0,
        emailId: emailIdCon || "",
        countryId: countryId || 0,
        contactName: contactPersonCon || "",
        leadStatusId: 2,
        isActive: true,
        contactDetailsList: [{
          id: 0,
          customerId: 0,
          contactPerson: contactPersonCon || "",
          mobileNo: mobileNoCon,
          emailId: emailIdCon || "",
          isActive: statusCon
        }],
        billingDetailsList: [{
          id: 0,
          customerId: 0,
          streetName: streetNameBilling || "",
          countryId: selectedCountry || 0,
          stateId: 0,
          postalZipCodde: "",
          isActive: true
        }],
        shippingDetailsList: [{
          id: 0,
          customerId: 0,
          streetName: streetNameShipping || "",
          countryId: selectedCountryTemp || 0,
          stateId: 0,
          postalZipCodde: "",
          isActive: true
        }]
      };

      console.log("Customer Request Body:", requestBody);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Customer/SaveCustomer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      console.log("Customer Response Data:", responseData);
      if (responseData.isSuccess) {
        toast.success("Record Inserted successfully");

        setTimeout(() => {
          window.location.reload(); // Reload the page or fetch updated list
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }
    } catch (error) {
      console.error("Error performing Customer operation:", error);
      toast.error("Error performing Customer operation");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className="text-l font-weight-[400]">Add Customers</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="flex flex-col h-full uppercase">
          <div className="p-6 mt-2 bg-white rounded grow">
            <div className="px-2  lg:px-4 minbox ">
              <TabContainer>
                <Tab label="Client Details" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab label="Contact Details" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab label="Billing Details" index={2} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab label="Shipping Address" index={3} activeTab={activeTab} setActiveTab={setActiveTab} />
              </TabContainer>
            </div>

            <TabContent index={0} activeTab={activeTab}>
              <ClientDetails
                cName={cName}
                setCName={setCName}
                mobile={mobile}
                setMobile={setMobile}
                parentCustomer={parentCustomer}
                landLineNumber={landLineNumber}
                setLandLineNumber={setLandLineNumber}
                setCustomerId={setCustomerId}
                setCountryId={setCountryId}
                countryData={countryData}
                next={handleSaveAndContinue}
                onCancel={onCancel}
              />
            </TabContent>

            <TabContent index={1} activeTab={activeTab}>
              <ContactDetails
                cName={cName}
                setCName={setCName}
                contactPersonCon={contactPersonCon}
                setContactPersonCon={setContactPersonCon}
                mobileNoCon={mobileNoCon}
                setMobileNoCon={setMobileNoCon}
                emailIdCon={emailIdCon}
                setEmailIdCon={setEmailIdCon}
                statusCon={statusCon}
                setStatusCon={setStatusCon}
                next={handleSaveAndContinue}
                back={handleBack}
                onCancel={onCancel}
              />
            </TabContent>

            <TabContent index={2} activeTab={activeTab}>
              <BillingDetails
                allTerData={allTerData}
                setSelectedCountry={setSelectedCountry}
                streetNameBilling={streetNameBilling}
                setStreetNameBilling={setStreetNameBilling}
                checkboxSameAddress={checkboxSameAddress}
                setCheckboxSameAddress={setCheckboxSameAddress}

                next={handleSaveAndContinue}
                back={handleBack}
                onCancel={onCancel}
              />

            </TabContent>

            <TabContent index={3} activeTab={activeTab}>
              <ShippingDetails
                streetNameShipping={streetNameShipping}
                setStreetNameShipping={setStreetNameShipping}
                allTerDataTemp={allTerDataTemp}
                setSelectedCountryTemp={setSelectedCountryTemp}
                selectedCountryTemp={selectedCountryTemp}

                back={handleBack}
                onCancel={onCancel}
                handleCustomerDataSubmit={handleCustomerDataSubmit}
              />
            </TabContent>
          </div >
        </div >
      </div>

      <ToastContainer autoClose={2000} />
    </>
  )
}

