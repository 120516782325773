import React, { useEffect, useState } from 'react'
import CustomButton2 from '../../general-components/CustomButton2.component'
import CustomButton1 from '../../general-components/CustomButton1.component'
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomInputNumber from '../../general-components/CustomInputNumber.component';
import CustomSelect1 from '../../general-components/CustomSelect1.component';
import CustomInputNumberDecimal from './../../general-components/CustomInputNumberDecimal.component';
import axios from 'axios';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomSelect2 from '../../general-components/CustomSelect2.component';

export default function ManageAdvanceAmountEdit({ onCancel, selectedValue }) {
    const [test3, setTest3] = useState("");
    const [test5, setTest5] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [deptData, setDeptData] = useState([]);
    const [selectedDept, setSelectedDept] = useState(null);
    const [selectedDeptName, setSelectedDeptName] = useState(null);
    const dataActive = { searchText: "", isActive: true };

    const fetchDeptApiData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetBankList`, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data;
                setDeptData(sortedData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Bank name Data");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDeptApiData();
    }, [])

    useEffect(() => {
        if (selectedValue) {
            // setTest3(selectedValue.bank);
            setSelectedDept(selectedValue.bankId);
            setSelectedDeptName(selectedValue.bankName);
            setTest5(selectedValue.bankCommission);
        }
    }, [selectedValue])

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            let requestBody = {};
            requestBody = {
                id: selectedValue.id,
                bankId: selectedDept || 0,
                bankCommission: test5 || 0,
            };
            console.log("Request Body:", requestBody); // Debugging: check request body

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageMIS/UpdateMIS_AdvanceAmount`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            console.log("Response Data:", responseData); // Debugging: check response data

            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                // setUpdatePopup(false);
                setTimeout(() => {
                    onCancel()
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing operation:", error);
            toast.error("Error performing operation");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePaperTypeChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedDept(selectedOption.value);
            setSelectedDeptName(selectedOption.label);
        } else {
            setSelectedDept(null); // Reset to null when cleared
            setSelectedDeptName("");
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Edit Advance Amount</h1>
                    <div className="flex  item-center text-sm"></div>
                </div>

                <div className="p-4 gap-4 max-h-[70vh] overflow-y-auto max-w-full w-[700px]">
                    <div className="grid grid-cols-1  gap-4 md:grid-cols-2 ">
                        <div>
                            {/* <CustomTextField2 label="BANK" placeholder="Enter" isRequired={false}
                                value={test3}
                                onChange={setTest3}
                            /> */}
                            <CustomSelect2 label={"BANK"}
                                placeholder={"Select..."}
                                options={deptData.map(depData => ({
                                    value: depData.id,
                                    label: depData.bankName
                                }))}
                                isClearable={true}
                                value={
                                    selectedDept && selectedDeptName
                                        ? { value: selectedDept, label: selectedDeptName }
                                        : null // Display null if no value is selected
                                }
                                onChange={handlePaperTypeChange}
                                isRequired={false}
                            />

                        </div>
                        <CustomInputNumberDecimal label="Bank Commission" placeholder="Enter" isRequired={false}
                            value={test5}
                            onChange={setTest5}
                        />
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" type="submit" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}

