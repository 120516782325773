import React, { useState } from 'react';

function CustomTextField2(props) {
    const { label, isRequired, readOnly, value, onChange } = props;

    const handleChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        const upperCaseValue = event.target.value.toUpperCase();
        onChange(upperCaseValue);
    };

    return (
        <div className="flex flex-col gap-1">
            <label htmlFor="id" className="text-xs font-[400]">
                {label.toUpperCase()}
                {isRequired && <span className="text-red-500 gap-3">*</span>}
            </label>
            <input
                type="text"
                required={isRequired}
                readOnly={readOnly}
                value={value}
                className={`p-2 border rounded grow min-w-[14rem]  text-xs placeholder:text-xs uppercase border-gray-300 ${readOnly ? ' bg-gray-200 text-gray-500' : 'text-black bg-white'}`}
                id="id"
                placeholder={props.placeholder}
                onChange={handleChange}
            />
        </div>
    );
}

CustomTextField2.defaultProps = {
    isRequired: true,
    value: '',
    onChange: () => { },
};

export default CustomTextField2;
