// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import Select from 'react-select';
import { Tooltip } from "@mui/material";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import PopUp from "../../general-components/PopUp.componenet";
import CustomFileUpload from "../../general-components/CustomFileUpload.component";
import CustomAlphatext from "../../general-components/CustomAlphatext.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomInputNumber from "../../general-components/CustomInputNumber.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

import DeliveryTermsAdd from "./DeliveryTermsAdd";
import PaymentsTermsAdd from "./PaymentsTermsAdd";

// COMPONENTS
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomSelect2 from "../../general-components/CustomSelect2.component";
import CustomSelect4 from "../../general-components/CustomSelect4.component";
import CustomAlphatextPoNumber from "../../general-components/CustomAlphatextPoNumber.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomInputNumberDecimal from "../../general-components/CustomInputNumberDecimal.component";
import CustomInputNumberDecimalQty from "../../general-components/CustomInputNumberDecimalQty.component";

const optionsPayment = [
    { value: 1, label: "Advance Payment" },
    { value: 2, label: "LC" }
]

export default function ManagePOEdit({ onCancel, selectedValue }) {

    const token = localStorage.getItem('DIYAN_IE_authToken');
    let [deliveryTermsAdd, setDeliveryTermsAdd] = useState(false);
    let [paymentsTermsAdd, setPaymentsTermsAdd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [checkboxAdd, setCheckboxAdd] = useState(false);
    const [poFlag, setPoFlag] = useState(false);
    const [qty, setQty] = useState("");
    const [curVal, setCurVal] = useState("");
    const [poNo, setPoNo] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [parentCustomerRead, setParentCustomerRead] = useState("");
    const [countryNameRead, setCountryNameRead] = useState("");
    const [poAmt, setPoAmt] = useState("")

    const [selectedPaperTId, setSelectedPaperTId] = useState([]);
    const [selectedPaperTName, setSelectedPaperTName] = useState([]);

    const [paymentsTermsData, setPaymentsTermsData] = useState([]); // Sample initial deliveryTermsData
    const [deliveryTermsData, setDeliveryTermsData] = useState([]); // Sample initial deliveryTermsData
    const [portDichargeData, setPortDischargeData] = useState([]);
    const [currencyData, setCurrencyData] = useState([]);
    const [paperTData, setPaperTData] = useState([]);
    const [brandData, setBrandData] = useState([]);
    const [typeOfPackageData, setTypeOfPackageData] = useState([]);
    const [selectedPayTermId, setSelectedPayTermId] = useState("");
    const [selectedPayTermName, setSelectedPayTermName] = useState("");

    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedPortDischargeId, setSelectedPortDischargeId] = useState("");
    const [selectedCurrencyId, setSelectedCurrencyId] = useState("");
    const [selectedBrandId, setSelectedBrandId] = useState([]);
    const [selectedTypeOfPackageId, setSelectedTypeOfPackageId] = useState([]);
    const [selectedDeliveryTermsId, setSelectedDeliveryTermsId] = useState("");
    const [selectedPaymentsTermsId, setSelectedPaymentsTermsId] = useState("");

    const [selectedCustomerName, setSelectedCustomerName] = useState(null);
    const [selectedPortDischargeName, setSelectedPortDischargeName] = useState("");
    const [selectedCurrencyName, setSelectedCurrencyName] = useState("");
    const [selectedBrandName, setSelectedBrandName] = useState([]);
    const [selectedTypeOfPackageName, setSelectedTypeOfPackageName] = useState([]);
    const [selectedDeliveryTermsName, setSelectedDeliveryTermsName] = useState("");
    const [selectedPaymentsTermsName, setSelectedPaymentsTermsName] = useState("");

    const [fileName, setFileName] = useState("");
    const [fileOriginalName, setFileOriginalName] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [updatePopup, setUpdatePopup] = useState(false);
    const [cpt, setCpt] = useState("");
    const [isCpt, setIsCpt] = useState("");

    const [customerData, setCustomerData] = useState([]);
    const dataActive = { searchText: "", isActive: true };
    const dataCustomerList = { searchText: "", customerId: 0, leadStatusId: 2, parentCustomerId: 0, isActive: true };
    const [endCloseDateTime, setEndCloseDateTime] = useState(null);
    const [shipDate, setShipDate] = useState("");

    useEffect(() => {
        // Create a new date object in UTC and convert it to IST
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000);

        // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
        const formattedDate = istDate.toISOString().slice(0, 16);
        setShipDate(formattedDate);
    }, []);

    const endFormatDate = (date) =>
        date
            ? new Date(date).toLocaleString('en-IN', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, // 12-hour format
            })
            : 'N/A';

    const handleCheckboxChange = (state) => {
        setCheckboxAdd(state);

        // Set the time every time the checkbox is flagged (true)
        if (state) {
            const timestamp = new Date().toISOString();
            setEndCloseDateTime(timestamp); // update time on each flag
        }
    };

    const fetchDataFilter = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                url,
                { isActive: true },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data.sort((a, b) => a.id - b.id);
                // Log sorted data to verify
                console.log("Sorted Data:", sortedData);
                setData(sortedData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDataFilter(
            `${process.env.REACT_APP_BASE_URL}/Customer/GetCustomerList`,
            setCustomerData,
            "Error fetching Customer Data"
        );
    }, []);

    useEffect(() => {
        if (selectedValue) {
            setPoFlag(selectedValue.pO_IsPOReceived);
            setQty(selectedValue.pO_Quantity);
            setCurVal(selectedValue.pO_CurrencyValue);
            setPoNo(selectedValue.pO_PONumber);
            setParentCustomerRead(selectedValue.parentCustomer);
            setCountryNameRead(selectedValue.countryName);

            setSelectedCustomerId(selectedValue.customerId);
            setSelectedPortDischargeId(selectedValue.pO_PortDischargeId);
            setSelectedCurrencyId(selectedValue.pO_CurrencyTypeId);

            setSelectedDeliveryTermsId(selectedValue.pO_DeliveryTermsId);
            setSelectedPaymentsTermsId(selectedValue.pO_PaymentTermsId);

            setSelectedCustomerName(selectedValue.customerName);
            setSelectedPortDischargeName(selectedValue.pO_PortDischarge);
            setSelectedCurrencyName(selectedValue.pO_CurrencyType);

            setSelectedDeliveryTermsName(selectedValue.pO_DeliveryTerms);
            setSelectedPaymentsTermsName(selectedValue.pO_PaymentTerms);

            const splitIds = (ids) => (ids ? ids.split(",") : []); // Initialize as empty array if null
            const splitLabels = (labels) => (labels ? labels.split(",") : []);

            setSelectedPaperTId(splitIds(selectedValue.pO_PaperTypeId));
            setSelectedPaperTName(splitLabels(selectedValue.pO_PaperType));
            setSelectedBrandId(splitIds(selectedValue.pO_BrandId));
            setSelectedBrandName(splitLabels(selectedValue.pO_Brand));
            setSelectedTypeOfPackageId(splitIds(selectedValue.pO_TypeOfPackagingId));
            setSelectedTypeOfPackageName(splitLabels(selectedValue.pO_TypeOfPackaging));

            setFileName(selectedValue.pO_Image);
            setFileOriginalName(selectedValue.pO_OriginalFileName);
            setFileUrl(selectedValue.pO_ImageURL);

            setCheckboxAdd(selectedValue.pO_IsPOStatusClosed);
            setPoAmt(selectedValue.pO_Amount);
            setSelectedPayTermId(selectedValue.pO_PaymentType);

            setCpt(selectedValue.pO_CommissionPerTon);
            setShipDate(selectedValue.ouP_OrderUnderProcessDate)
        }
    }, [selectedValue])

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPortDischargeList`, setPortDischargeData, "Error fetching Port OF Discharge Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCurrencyTypeList`, setCurrencyData, "Error fetching Currency Type Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaperTypeList`, setPaperTData, "Error fetching Paper Type Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetBrandList`, setBrandData, "Error fetching Brand Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetTypeOfPackagingList`, setTypeOfPackageData, "Error fetching Type of Packagining Data");
    }, []);

    const fetchPaymentTearmsData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentTermsList`, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setPaymentsTermsData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Payments Terms Data");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDeliveryTearmsData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetDeliveryTermsList`, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setDeliveryTermsData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Payments Terms Data");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchListData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Customer/GetCustomerList`, dataCustomerList, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data;
                const filteredData = response.data.data.filter(
                    (cData) => cData.customerName && cData.customerName.trim() !== ""
                );
                setCustomerData(filteredData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Customer list");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchListData();
        fetchPaymentTearmsData();
        fetchDeliveryTearmsData();
    }, []);

    // useEffect(() => {
    //     if (selectedCustomerId) {
    //         const selectedCustomer = customerData.find(c => c.id === selectedCustomerId);
    //         if (selectedCustomer) {
    //             setParentCustomerRead(selectedCustomer.parentCustomer);
    //             setCountryNameRead(selectedCustomer.countryName);
    //             setIsCpt(selectedCustomer.customerTypeId);
    //         }
    //     }
    // }, [selectedCustomerId, customerData]);

    useEffect(() => {
        if (selectedCustomerId) {
            const selectedCustomer = customerData.find(c => c.id === selectedCustomerId);

            if (selectedCustomer) {
                setParentCustomerRead(selectedCustomer.parentCustomer);
                setCountryNameRead(selectedCustomer.countryName);

                // Check if the selected customer's customerTypeId is 2
                if (selectedCustomer.customerTypeId === 2) {
                    setIsCpt(2);
                    return; // Exit early if the selected customerTypeId is already 2
                }

                // Find the parent customer in the customerData array
                const parentCustomer = customerData.find(c => c.customerName === selectedCustomer.parentCustomer);

                // Check if the parent customer's customerTypeId is 2
                if (parentCustomer && parentCustomer.customerTypeId === 2) {
                    setIsCpt(2);
                    return;
                }
            }

            // If neither the selected customer nor the parent customer has customerTypeId === 2
            setIsCpt(null);
        }
    }, [selectedCustomerId, customerData]);


    // const handleCustomerChange = (selectedOption) => {
    //     if (selectedOption) {
    //         setSelectedCustomerId(selectedOption.value);
    //         setSelectedCustomerName(selectedOption.label); // Update the department name label
    //         setCustError(''); // Clear the error
    //     } else {
    //         setSelectedCustomerId(null); // Reset to null when cleared
    //         setSelectedCustomerName(''); // Clear the department name label
    //     }
    // };

    const handleCustomerChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCustomerId(selectedOption.value);
            setSelectedCustomerName(selectedOption.label); // Update the department name label
            setCustError('');
        } else {
            setSelectedCustomerId(null);
            setSelectedCustomerName(''); // Clear the department name label
            setParentCustomerRead('');
            setCountryNameRead('');
            setIsCpt(null);
            setCpt('');
        }
    };


    const handlePortDischargeChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedPortDischargeId(selectedOption.value);
            setSelectedPortDischargeName(selectedOption.label); // Update the department name label
            // No error state associated with this field
        } else {
            setSelectedPortDischargeId(null); // Reset to null when cleared
            setSelectedPortDischargeName(''); // Clear the department name label
        }
    };

    const handleCurrencyChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCurrencyId(selectedOption.value);
            setSelectedCurrencyName(selectedOption.label); // Update the department name label
            setCurTypeError(''); // Clear the error
        } else {
            setSelectedCurrencyId(null); // Reset to null when cleared
            setSelectedCurrencyName(''); // Clear the department name label
        }
    };

    const handleDeliveryTermsChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedDeliveryTermsId(selectedOption.value);
            setSelectedDeliveryTermsName(selectedOption.label); // Update the department name label
            // No error state associated with this field
        } else {
            setSelectedDeliveryTermsId(null); // Reset to null when cleared
            setSelectedDeliveryTermsName(''); // Clear the department name label
        }
    };

    const handlePaymentsChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedPaymentsTermsId(selectedOption.value);
            setSelectedPaymentsTermsName(selectedOption.label); // Update the department name label
            setPaymentTermError(''); // Clear the error
        } else {
            setSelectedPaymentsTermsId(null); // Reset to null when cleared
            setSelectedPaymentsTermsName(''); // Clear the department name label
        }
    };

    const handlePaychangeChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedPayTermId(selectedOption.value);
            setSelectedPayTermName(selectedOption.label); // Update the department name label
            setPaymentTypeError(''); // Clear the error
        } else {
            setSelectedPayTermId(null); // Reset to null when cleared
            setSelectedPayTermName(''); // Clear the department name label
        }
    };

    const handlePaperTChange = (selectedOptions) => {
        const ids = selectedOptions ? selectedOptions.map(option => option.value) : [];
        const names = selectedOptions ? selectedOptions.map(option => option.label) : [];

        setSelectedPaperTId(ids);
        setSelectedPaperTName(names);
    };

    const handleBrandChange = (selectedOptions) => {
        const ids = selectedOptions ? selectedOptions.map(option => option.value) : [];
        const names = selectedOptions ? selectedOptions.map(option => option.label) : [];

        setSelectedBrandId(ids);
        setSelectedBrandName(names);
    }

    const handleTypeOfPackageChange = (selectedOptions) => {
        const ids = selectedOptions ? selectedOptions.map(option => option.value) : [];
        const names = selectedOptions ? selectedOptions.map(option => option.label) : [];

        setSelectedTypeOfPackageId(ids);
        setSelectedTypeOfPackageName(names);
    }

    useEffect(() => {
        if (selectedCustomerId) {
            const selectedCustomer = customerData.find(c => c.id === selectedCustomerId);
            if (selectedCustomer) {
                setParentCustomerRead(selectedCustomer.parentCustomer);
                setCountryNameRead(selectedCustomer.countryName);
            }
        }
    }, [selectedCustomerId, customerData]);

    const [paymentTypeError, setPaymentTypeError] = useState('');
    const [curTypeError, setCurTypeError] = useState('');
    const [paymentTermError, setPaymentTermError] = useState('');
    const [custError, setCustError] = useState('');
    const [poAmtError, setPoAmtError] = useState('');

    const handleChange = (event) => {
        let newValue = event.target.value.trim();

        // Allow numbers and a single decimal point
        const cleanedValue = newValue.replace(/[^0-9.]/g, '');

        // Ensure only one decimal point is allowed
        if (cleanedValue.split('.').length > 2) {
            setPoAmtError('Only one decimal point is allowed');
            return; // Exit without updating the value
        }

        // Set error message if input doesn't match cleaned value
        if (cleanedValue !== newValue) {
            setPoAmtError('Only numbers and a decimal point are allowed');
        } else {
            setPoAmtError('');
        }
        setPoAmt(cleanedValue);
    };

    const setDefaultDate = () => {
        const now = new Date();
        const offset = 330; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 60 * 1000);

        // Format to 'YYYY-MM-DDTHH:mm' for datetime-local
        return istDate.toISOString().slice(0, 16);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Validate Payment Type
        if (!selectedCustomerId) {
            setCustError('Please select a Customer Name');
            setUpdatePopup(false)
            setIsLoading(false);
            return; // Prevent form submission
        } else {
            setCustError(''); // Clear the error if valid
        }

        if (!selectedPaymentsTermsId) {
            setPaymentTermError('Please select a payment terms');
            setUpdatePopup(false)
            setIsLoading(false);
            return; // Prevent form submission
        } else {
            setPaymentTermError(''); // Clear the error if valid
        }

        // Validate Payment Type
        if (!selectedPayTermId) {
            setPaymentTypeError('Please select a payment type');
            setUpdatePopup(false)
            setIsLoading(false);
            return; // Prevent form submission
        } else {
            setPaymentTypeError(''); // Clear the error if valid
        }

        if (!selectedCurrencyId) {
            setCurTypeError('Please select a Currency type');
            setUpdatePopup(false)
            setIsLoading(false);
            return; // Prevent form submission
        } else {
            setCurTypeError(''); // Clear the error if valid
        }

        // Validate PO Amount
        if (!poAmt || isNaN(poAmt) || poAmt <= 0) {
            setPoAmtError('Please enter a valid PO Amount');
            setUpdatePopup(false)
            setIsLoading(false);
            return;
        } else {
            setPoAmtError('');
        }

        try {
            let requestBody = {};
            const effectiveDate = shipDate || setDefaultDate();


            if (profileImage && profileImage.base64) {
                requestBody = {
                    id: selectedValue.id,
                    customerId: selectedCustomerId,
                    pO_PortDischargeId: selectedPortDischargeId || 0,
                    pO_IsPOReceived: poFlag,
                    pO_PONumber: poNo || "",
                    pO_PaymentTermsId: selectedPaymentsTermsId || 0,
                    pO_Quantity: qty || 0,
                    pO_PaymentType: selectedPayTermId || 1,
                    pO_CurrencyTypeId: selectedCurrencyId || 0,
                    pO_CurrencyValue: curVal || 0,
                    pO_Amount: poAmt ? parseFloat(poAmt) : 0,
                    pO_PaperTypeId: selectedPaperTId.join(",") || "", // Join array to comma-separated string
                    pO_BrandId: selectedBrandId.join(",") || "", // Join array to comma-separated string
                    pO_TypeOfPackagingId: selectedTypeOfPackageId.join(",") || "", // Join array to comma-separated string
                    pO_DeliveryTermsId: selectedDeliveryTermsId || 0,
                    pO_IsPOStatusClosed: checkboxAdd,
                    pO_POImage: '',
                    pO_POOriginalFileName: profileImage.name || "",
                    pO_Image_Base64: profileImage.base64 || "",
                    pO_CommissionPerTon: cpt || 0,
                    isActive: true,
                    piI_IsClosed: false,
                    // piC_Date: null,
                    // piC_Remarks: "",
                    piC_IsConfirmed: false,
                    ouP_OrderUnderProcessDate: effectiveDate,
                    piIssuedList: [],
                    paymentReceived_Or_LCReceivedDetails: {
                        paymentOrLCReceived: 0,
                        paymentOrLCClosed: false,
                        paymentReceivedDetail: [],
                        lcReceivedDetail: []
                    }
                };
            } else {
                requestBody = {
                    id: selectedValue.id,
                    customerId: selectedCustomerId,
                    pO_PortDischargeId: selectedPortDischargeId || 0,
                    pO_IsPOReceived: poFlag,
                    pO_PONumber: poNo || "",
                    pO_PaymentTermsId: selectedPaymentsTermsId || 0,
                    pO_Quantity: qty || 0,
                    pO_PaymentType: selectedPayTermId || 1,
                    pO_CurrencyTypeId: selectedCurrencyId || 0,
                    pO_CurrencyValue: curVal || 0,
                    pO_Amount: poAmt ? parseFloat(poAmt) : 0,
                    pO_PaperTypeId: Array.isArray(selectedPaperTId) ? selectedPaperTId.join(",") : "", // Join array to comma-separated string
                    pO_BrandId: Array.isArray(selectedBrandId) ? selectedBrandId.join(",") : "", // Join array to comma-separated string
                    pO_TypeOfPackagingId: Array.isArray(selectedTypeOfPackageId) ? selectedTypeOfPackageId.join(",") : "", // Join array to comma-separated string
                    pO_DeliveryTermsId: selectedDeliveryTermsId || 0,
                    pO_IsPOStatusClosed: checkboxAdd,
                    pO_POImage: fileName || '',
                    pO_POOriginalFileName: fileOriginalName || '',
                    pO_Image_Base64: "",
                    pO_CommissionPerTon: cpt || 0,
                    isActive: true,
                    piI_IsClosed: false,
                    // piC_Date: null,
                    // piC_Remarks: "",
                    piC_IsConfirmed: false,
                    piIssuedList: [],
                    ouP_OrderUnderProcessDate: effectiveDate,
                    paymentReceived_Or_LCReceivedDetails: {
                        paymentOrLCReceived: 0,
                        paymentOrLCClosed: false,
                        paymentReceivedDetail: [],
                        lcReceivedDetail: []
                    }
                };
            }
            console.log("Request Body:", requestBody); // Debugging: check request body

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            console.log("Response Data:", responseData); // Debugging: check response data

            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);
                setTimeout(() => {
                    onCancel()
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className="text-l font-weight-[400]">Edit PO Received</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                {/* <form onSubmit={handleSubmit}> */}
                <div className="p-5 mx-auto">
                    <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                        <div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="id" className="text-xs font-400 uppercase">
                                    Customer Name<span className="text-red-500 gap-3">*</span>
                                </label>
                                <Select
                                    options={customerData.map(cData => ({
                                        value: cData.id,
                                        label: cData.customerName
                                    }))}
                                    value={{ value: selectedCustomerId, label: selectedCustomerName }} // Set initial value here
                                    className="text-black text-xs select-bar uppercase"
                                    id="id"
                                    isSearchable
                                    isClearable
                                    menuPosition={'fixed'}
                                    onChange={handleCustomerChange}
                                    closeMenuOnSelect={true}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                                {custError && (
                                    <span className="text-red-500 text-xs mt-1">{custError}</span>
                                )}
                            </div>
                        </div>
                        <div>
                            <CustomTextField2 label={"Parent Customer"} placeholder={"Read only"} readOnly={true} value={parentCustomerRead} isRequired={false} />
                        </div>
                        <div>
                            <CustomTextField2 label={"Country"} placeholder={"Read Only"} readOnly={true} value={countryNameRead} isRequired={false} />
                        </div>
                        <div>
                            <CustomSelect2
                                label={"Port of Discharge"}
                                placeholder={"Select..."}
                                options={portDichargeData.map(cData => ({
                                    value: cData.id,
                                    label: cData.portDischarge
                                }))}
                                isRequired={false}
                                value={{ value: selectedPortDischargeId, label: selectedPortDischargeName }} // Set initial value here
                                onChange={handlePortDischargeChange}
                                isClearable // Pass isClearable prop for conditional rendering
                            />
                        </div>
                        <div className='pt-5'>
                            <CustomCheckBox3
                                label={"PO Received"}
                                state={poFlag}
                                setState={setPoFlag}
                            ></CustomCheckBox3>
                        </div>
                        <div className="">
                            <CustomAlphatextPoNumber
                                label={"PO Number"}
                                placeholder={!poFlag ? "disabled " : "ENTER"}
                                isRequired={false}
                                value={poNo}
                                onChange={setPoNo}
                                disabled={!poFlag}  // Disable if PO flag is not checked
                            ></CustomAlphatextPoNumber>
                        </div>
                    </div>

                    <div className="max-w-full flex justify-between items-center gap-4 mt-2 w-[1000px]">
                        <div className="w-[40%]">
                            <div className="py-1 ">
                                <div className="flex justify-between">
                                    <label htmlFor="id" className="flex items-center text-xs font-400 ">
                                        Payment Terms<span className="text-red-500 gap-3">*</span>
                                    </label>
                                    <Tooltip title="ADD" arrow placement="left">
                                        <span>
                                            <button type="button" onClick={() => setPaymentsTermsAdd(true)}>
                                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.0013 2.28564C5.40964 2.28564 1.66797 6.02731 1.66797 10.619C1.66797 15.2106 5.40964 18.9523 10.0013 18.9523C14.593 18.9523 18.3346 15.2106 18.3346 10.619C18.3346 6.02731 14.593 2.28564 10.0013 2.28564Z" fill="#643c94" />
                                                    <path d="M10.6263 11.244H13.3346C13.6763 11.244 13.9596 10.9606 13.9596 10.619C13.9596 10.2773 13.6763 9.99398 13.3346 9.99398H10.6263V7.28564C10.6263 6.94398 10.343 6.66064 10.0013 6.66064C9.65964 6.66064 9.3763 6.94398 9.3763 7.28564V9.99398H6.66797C6.3263 9.99398 6.04297 10.2773 6.04297 10.619C6.04297 10.9606 6.3263 11.244 6.66797 11.244H9.3763V13.9523C9.3763 14.294 9.65964 14.5773 10.0013 14.5773C10.343 14.5773 10.6263 14.294 10.6263 13.9523V11.244Z" fill="white" />
                                                </svg>
                                            </button>
                                        </span>
                                    </Tooltip>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <Select
                                        options={paymentsTermsData.map(cData => ({
                                            value: cData.id,
                                            label: cData.paymentTerms
                                        }))}
                                        className="text-black text-xs select-bar"
                                        id="countryId"
                                        isSearchable
                                        isClearable
                                        value={{ value: selectedPaymentsTermsId, label: selectedPaymentsTermsName }} // Set initial value here
                                        onChange={handlePaymentsChange}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#e5e7eb',
                                            },
                                        })}
                                    />
                                    {paymentTermError && (
                                        <span className="text-red-500 text-xs mt-1">{paymentTermError}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 w-[30%]">
                            <CustomInputNumberDecimalQty
                                isRequired={false}
                                label={"Qty"}
                                placeholder={"ENTER"}
                                value={qty}
                                onChange={setQty}
                            ></CustomInputNumberDecimalQty>
                        </div>
                        <div className="mt-2 w-[30%]">
                            {/* <CustomSelect1 label={"Payment Type"}
                                isRequired={false}
                                options={optionsPayment}
                                onChange={handlePaychangeChange}
                            /> */}
                            {/* <CustomSelect2
                                label={"Payment Type"}
                                placeholder={"Select..."}
                                options={optionsPayment}
                                isRequired={true}
                                value={{ value: selectedPayTermId, label: selectedPayTermId === 1 ? "Payment Received" : "LC Received" }} // Set initial value here
                                onChange={handlePaychangeChange}
                                isClearable // Pass isClearable prop for conditional rendering
                            /> */}
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label className="text-xs font-400 uppercase">
                                        Payment Type <span className="text-red-500 gap-3">*</span>
                                    </label>

                                    <Select
                                        options={optionsPayment}
                                        className="text-black text-xs select-bar uppercase"
                                        id="id"
                                        value={
                                            selectedPayTermId
                                                ? { value: selectedPayTermId, label: selectedPayTermId === 1 ? "Payment Received" : "LC Received" }
                                                : null // This will clear the selection
                                        }
                                        placeholder={"Select..."}
                                        isSearchable
                                        isClearable
                                        menuPosition="fixed"
                                        onChange={(selectedOption) => handlePaychangeChange(selectedOption)}
                                        closeMenuOnSelect={true}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#e5e7eb',
                                            },
                                        })}
                                    />
                                    {paymentTypeError && (
                                        <span className="text-red-500 text-xs mt-1">{paymentTypeError}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 mt-3 w-[1000px]">
                        <div className="">
                            {/* <CustomSelect2
                                label={"Currency Type"}
                                placeholder={"Select..."}
                                options={currencyData.map(cData => ({
                                    value: cData.id,
                                    label: cData.currencyType
                                }))}
                                value={{ value: selectedCurrencyId, label: selectedCurrencyName }} // Set initial value here
                                onChange={handleCurrencyChange}
                                isClearable // Pass isClearable prop for conditional rendering
                            /> */}
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label className="text-xs font-400 uppercase">
                                        Currency Type<span className="text-red-500 gap-3">*</span>
                                    </label>

                                    <Select
                                        options={currencyData.map(cData => ({
                                            value: cData.id,
                                            label: cData.currencyType
                                        }))}
                                        className="text-black text-xs select-bar uppercase"
                                        id="id"
                                        value={{ value: selectedCurrencyId, label: selectedCurrencyName }} // Set initial value here
                                        placeholder={"Select..."}
                                        isSearchable
                                        isClearable
                                        menuPosition="fixed"
                                        onChange={handleCurrencyChange}
                                        closeMenuOnSelect={true}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#e5e7eb',
                                            },
                                        })}
                                    />
                                    {curTypeError && (
                                        <span className="text-red-500 text-xs mt-1">{curTypeError}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* <CustomInputNumber
                                label={"PO Amount"}
                                placeholder={"ENTER"}
                                value={poAmt}
                                onChange={setPoAmt}
                                isRequired={true}
                            ></CustomInputNumber> */}
                            <div className="flex flex-col gap-1">
                                <label htmlFor="id" className="text-xs font-[400] uppercase">
                                    PO Amount<span className="text-red-500 gap-3">*</span>
                                </label>
                                <input
                                    type="tel"
                                    // required
                                    className={`p-2 uppercase border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${poAmtError ? 'border-red-500' : ''}`}
                                    id="poAmt"
                                    value={poAmt}
                                    placeholder={"Enter PO Amount"}
                                    onChange={handleChange}
                                />
                                {poAmtError && <span className="text-red-500 text-xs">{poAmtError}</span>}
                            </div>
                        </div>
                        {/* <div className="">
                            <CustomTextField2
                                label={"Exchange Rate"}
                                isRequired={false}
                                placeholder={"ENTER"}
                                value={curVal}
                                onChange={setCurVal}
                            ></CustomTextField2>
                        </div> */}
                        <div className="">
                            <CustomSelect4
                                label={"Paper Type"}
                                placeholder={"Select..."}
                                options={paperTData.map(cData => ({
                                    value: cData.id,
                                    label: cData.paperType
                                }))}
                                value={Array.isArray(selectedPaperTId) ? selectedPaperTId.map((id, index) => ({ value: id, label: selectedPaperTName[index] || '' })) : []}
                                onChange={handlePaperTChange}
                                isRequired={false}
                                isClearable // Pass isClearable prop for conditional rendering
                            />
                        </div>
                        <div className="">
                            <CustomSelect4
                                label={"Brand"}
                                placeholder={"Select..."}
                                options={brandData.map(cData => ({
                                    value: cData.id,
                                    label: cData.brand
                                }))}
                                value={Array.isArray(selectedBrandId) ? selectedBrandId.map((id, index) => ({ value: id, label: selectedBrandName[index] || '' })) : []}
                                onChange={handleBrandChange}
                                isRequired={false}
                                isClearable // Pass isClearable prop for conditional rendering
                            />
                        </div>
                        <div className="">
                            <CustomSelect4
                                label={"Type of Packaging"}
                                placeholder={"Select..."}
                                options={typeOfPackageData.map(cData => ({
                                    value: cData.id,
                                    label: cData.typeOfPackaging
                                }))}
                                value={Array.isArray(selectedTypeOfPackageId) ? selectedTypeOfPackageId.map((id, index) => ({ value: id, label: selectedTypeOfPackageName[index] || '' })) : []}
                                onChange={handleTypeOfPackageChange}
                                isRequired={false}
                                isClearable // Pass isClearable prop for conditional rendering
                            />
                        </div>
                        <div className="">
                            <div className="py-1 ">
                                <div className="flex justify-between">
                                    <label htmlFor="id" className="text-xs font-400 flex items-center">
                                        Delivery Terms
                                    </label>
                                    <Tooltip title="ADD" arrow placement="left">
                                        <span>
                                            <button type="button" onClick={() => setDeliveryTermsAdd(true)}>
                                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.0013 2.28564C5.40964 2.28564 1.66797 6.02731 1.66797 10.619C1.66797 15.2106 5.40964 18.9523 10.0013 18.9523C14.593 18.9523 18.3346 15.2106 18.3346 10.619C18.3346 6.02731 14.593 2.28564 10.0013 2.28564Z" fill="#643c94" />
                                                    <path d="M10.6263 11.244H13.3346C13.6763 11.244 13.9596 10.9606 13.9596 10.619C13.9596 10.2773 13.6763 9.99398 13.3346 9.99398H10.6263V7.28564C10.6263 6.94398 10.343 6.66064 10.0013 6.66064C9.65964 6.66064 9.3763 6.94398 9.3763 7.28564V9.99398H6.66797C6.3263 9.99398 6.04297 10.2773 6.04297 10.619C6.04297 10.9606 6.3263 11.244 6.66797 11.244H9.3763V13.9523C9.3763 14.294 9.65964 14.5773 10.0013 14.5773C10.343 14.5773 10.6263 14.294 10.6263 13.9523V11.244Z" fill="white" />
                                                </svg>
                                            </button>
                                        </span>
                                    </Tooltip>
                                </div>
                                <Select
                                    options={deliveryTermsData.map(cData => ({
                                        value: cData.id,
                                        label: cData.deliveryTerms
                                    }))}
                                    className="text-black text-xs select-bar"
                                    id="countryId"
                                    isSearchable
                                    isClearable
                                    value={{ value: selectedDeliveryTermsId, label: selectedDeliveryTermsName }} // Set initial value here
                                    onChange={handleDeliveryTermsChange}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="date" className="text-xs font-[400]">
                                    Shipment Schedule
                                </label>
                                <input
                                    type="datetime-local"
                                    required
                                    className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                    value={shipDate}
                                    onChange={(e) => setShipDate(e.target.value || "")}
                                    onBlur={() => {
                                        if (!shipDate) setShipDate(setDefaultDate());
                                    }}
                                />
                            </div>
                        </div>
                        {isCpt === 2 ?
                            <div>
                                <CustomInputNumberDecimal label={"Commission per ton"} placeholder={"Enter"} value={cpt} onChange={setCpt} isRequired={false} />
                            </div>
                            : null}
                        <div>
                            <CustomFileUpload
                                id="pi-upload-add-v1"
                                label="PO Received Upload"
                                isRequired={false}
                                disabled={!poFlag}
                                value={profileImage}
                                onChange={setProfileImage}
                                initialFileName={fileOriginalName}
                                initialFileUrl={fileUrl}
                            />
                        </div>
                        <div>
                            <CustomCheckBox2
                                label={"PO Status closed"}
                                state={checkboxAdd}
                                setState={handleCheckboxChange}
                            ></CustomCheckBox2>
                        </div>
                        {checkboxAdd && (
                            <CustomViewLabel
                                label="PO Status Close Date & Time"
                                value={endFormatDate(endCloseDateTime)}
                            />
                        )}
                    </div>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
                {/* </form> */}
            </div>

            {deliveryTermsAdd && (
                <PopUp>
                    <DeliveryTermsAdd
                        onCancel={() => {
                            setDeliveryTermsAdd(false);
                            fetchDeliveryTearmsData();
                        }}
                    />
                </PopUp>
            )}

            {paymentsTermsAdd && (
                <PopUp>
                    <PaymentsTermsAdd
                        onCancel={() => {
                            setPaymentsTermsAdd(false)
                            fetchPaymentTearmsData();
                        }}
                    />
                </PopUp>
            )}

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
}
