import React, { useEffect, useState } from 'react'
import CustomButton2 from '../../general-components/CustomButton2.component'
import CustomButton1 from '../../general-components/CustomButton1.component'
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomInputNumber from '../../general-components/CustomInputNumber.component';
import CustomInputNumberDecimal from '../../general-components/CustomInputNumberDecimal.component';
import CustomDate from '../../general-components/CustomDate.component';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import CustomCheckBox2 from './../../general-components/CustomCheckBox2.component';

export default function ManageCommissionReportEdit({ onCancel, selectedValue }) {
    const [test2, setTest2] = useState("");
    const [test3, setTest3] = useState("");
    const [test6, setTest6] = useState("");
    const [test7, setTest7] = useState("");
    const [test8, setTest8] = useState("");
    const [test9, setTest9] = useState("");
    const [remark, setRemark] = useState("");
    const [checkBox, setCheckBox] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (selectedValue) {
            console.log("Selected Value:", selectedValue); // Log selectedValue
            setTest2(selectedValue.sbDate ? selectedValue.sbDate.split("T")[0] : null); // Handle null/undefined
            setTest3(selectedValue.sbNo);
            setTest6(selectedValue.utilizedAmount);
            setTest7(selectedValue.exchangeRate);
            setTest8(selectedValue.commissionMentionInSBill);
            setTest9(selectedValue.unUtilizedAmount);
            setRemark(selectedValue.commissionRemark)
            setCheckBox(selectedValue.isCommissionClosed);
        } else {
            console.log("No selected value provided"); // Log if selectedValue is null/undefined
        }
    }, [selectedValue]);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            let requestBody = {};
            requestBody = {
                id: selectedValue.id,
                sbNo: test3 || "",
                sbDate: test2 || null,
                exchangeRate: test7 || 0,
                commissionMentionInSBill: test8 || 0,
                utilizedAmount: test6 || 0,
                unUtilizedAmount: test9 || 0,
                commissionRemark: remark || "",
                isCommissionClosed: checkBox
            };
            console.log("Request Body:", requestBody); // Debugging: check request body

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageMIS/UpdateMIS_Commission`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            console.log("Response Data:", responseData); // Debugging: check response data

            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                // setUpdatePopup(false);
                setTimeout(() => {
                    onCancel()
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing operation:", error);
            toast.error("Error performing operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Edit Commission</h1>
                    <div className="flex  item-center text-sm"></div>
                </div>

                <div className="p-4 gap-4 max-h-[70vh] overflow-y-auto max-w-full w-[900px]">
                    <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                        <CustomInputNumber label="SB No" placeholder="Enter" isRequired={false}
                            value={test3}
                            onChange={setTest3}
                            readOnly
                        />

                        <CustomDate label="SB Date" placeholder="Enter" isRequired={false}
                            value={test2}
                            onChange={setTest2}
                            readOnly
                        />
                        <CustomInputNumberDecimal label="EXCHANGE RATE" placeholder="Enter" isRequired={false}
                            value={test7}
                            onChange={setTest7}
                            readOnly
                        />

                        <CustomInputNumberDecimal label="COMMISSION MENTIONED IN S.BILL" placeholder={checkBox ? "READ ONLY" : "ENTER"} isRequired={false}
                            readOnly={checkBox}
                            value={test8}
                            onChange={setTest8}
                        />

                        <CustomInputNumberDecimal label="UTILIZED AMOUNT" placeholder={checkBox ? "READ ONLY" : "ENTER"} isRequired={false}
                            readOnly={checkBox}
                            value={test6}
                            onChange={setTest6}
                        />

                        <CustomInputNumberDecimal label="UNUTILIZED AMOUNT" placeholder={checkBox ? "READ ONLY" : "ENTER"} isRequired={false}
                            readOnly={checkBox}
                            value={test9}
                            onChange={setTest9}
                        />

                        <CustomTextField2 label="Remark" placeholder="Enter" isRequired={false}
                            value={remark}
                            onChange={setRemark}
                        />

                        <CustomCheckBox2
                            label={"Commission Closed"}
                            state={checkBox}
                            setState={setCheckBox}
                        ></CustomCheckBox2>
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" type="submit" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}

