import React, { useState, useEffect } from 'react';
import CustomButton2 from '../../general-components/CustomButton2.component';
import CustomButton1 from '../../general-components/CustomButton1.component';
import { IoMdAdd } from 'react-icons/io';
import CustomTextArea from '../../general-components/CustomTextArea.component';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBin6Line } from "react-icons/ri";
import CustomButton4 from '../../general-components/CustomButton4.component';

export default function Remarks({ onCancel, selectedValue, remarksAdd, setRemarksAdd, onOk }) {
  const [remarksList, setRemarksList] = useState([]);
  const [commentsList, setCommentsList] = useState([]); // State for comments
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editText, setEditText] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (selectedValue) {
      if (selectedValue.biDraftIssuedRemarkLogList) {
        const remarksWithFlags = selectedValue.biDraftIssuedRemarkLogList.map(remark => ({
          ...remark,
          isNew: false,
        }));
        setRemarksList(remarksWithFlags);
      }
      if (selectedValue.biDraftIssuedCommentLogList) {
        setCommentsList(selectedValue.biDraftIssuedCommentLogList); // Load comments into state
      }
    }
  }, [selectedValue]);

  const handleAddRemark = () => {
    if (remarksList.some(remark => remark.isNew)) {
      setError("You can only add one remark at a time.");
      return;
    }
    if (remarksAdd.trim()) {
      const newRemark = {
        remarks: remarksAdd,
        createdDate: new Date().toISOString(),
        id: remarksList.length + 1,
        isNew: true,
      };
      setRemarksList([...remarksList, newRemark]);
      setRemarksAdd("");
      setError("");
    }
  };

  const handleUpdateRemark = () => {
    if (editText.trim()) {
      const updatedList = [...remarksList];
      updatedList[editIndex] = {
        ...updatedList[editIndex],
        remarks: editText,
        createdDate: new Date().toISOString(),
      };
      setRemarksList(updatedList);
      setIsEditing(false);
      setEditIndex(null);
      setEditText("");
    }
  };

  const handleDeleteRemark = (index) => {
    const updatedList = remarksList.filter((_, i) => i !== index);
    setRemarksList(updatedList);
    setError("");
  };

  const handleSaveData = () => {
    const newRemarks = remarksList.filter(remark => remark.isNew);
    onOk(newRemarks);
  };

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleEditRemark = (index) => {
    setEditIndex(index);
    setEditText(remarksList[index].remarks);
    setIsEditing(true);
  };

  return (
    <>
      <div className="pb-5 bg-white rounded-lg max-w-full">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className="text-l font-weight-[400]">Remarks</h1>
        </div>

        {/* Remarks Section */}
        <div className="p-2 mx-2 my-4 max-w-full w-[800px]">
          <div className="flex justify-between gap-2">
            <div className="w-[80%]">
              <CustomTextArea
                label="Diyan Remarks"
                placeholder="Enter"
                value={isEditing ? editText : remarksAdd}
                onChange={isEditing ? setEditText : setRemarksAdd}
                isRequired={false}
              />
            </div>
            <div className="w-[20%] flex items-end">
              {isEditing ? (
                <CustomButton1
                  label={"Update"}
                  icon={<BiEdit />}
                  className="bg-prp-color text-white shrink grow md:grow-0 max-w-[70%]"
                  onClick={handleUpdateRemark}
                />
              ) : (
                <CustomButton1
                  label={"Add "}
                  icon={<IoMdAdd />}
                  className="bg-prp-color text-white shrink grow md:grow-0 max-w-[50%]"
                  onClick={handleAddRemark}
                  disabled={remarksList.some(remark => remark.isNew)}
                />
              )}
            </div>
          </div>
          {error && <div className="text-red-600 mt-2">{error}</div>}
        </div>

        <div className="p-2 mx-2 my-4 max-w-full w-[800px]">
          <div className="border border-prp-color rounded grow p-2 max-h-[400px] h-[360px] overflow-auto scroll-smooth">
            <h2 className="text-prp-color ">Diyan Remarks</h2>
            {remarksList.length === 0 ? (
              <div className="text-center mt-10 text-gray-500">No Diyan remarks available.</div>
            ) : (
              remarksList.map((remark, index) => (
                <div key={index} className="flex items-center justify-between mb-2 p-2 bg-gray-300 rounded">
                  <div className="w-[80%] text-xs">
                    <span className="">{remark.remarks}</span> <br />
                    <span className="text-gray-600">{formatDate(remark.createdDate)}</span>
                    {!remark.isNew && (
                      <span className="text-gray-600 ps-3">By: {remark.creatorName}</span>
                    )}
                  </div>
                  <div className="w-[20%] flex gap-2 items-center justify-center">
                    {remark.isNew && (
                      <>
                        <CustomButton1
                          className="bg-sixt text-white max-w-[50px]"
                          icon={<BiEdit />}
                          onClick={() => handleEditRemark(index)}
                        />
                        <CustomButton4
                          className="bg-eye text-white max-w-[50px]"
                          icon={<RiDeleteBin6Line />}
                          onClick={() => handleDeleteRemark(index)}
                        />
                      </>
                    )}
                  </div>
                </div>
              ))
            )}

            <h2 className="text-prp-color  mt-4">Client Comment</h2>
            {commentsList.length === 0 ? (
              <div className="text-center mt-10 text-gray-500">No Client Comment available.</div>
            ) : (
              commentsList.map((comment, index) => (
                <div key={index} className="mb-2 p-2 bg-gray-200 rounded">
                  <div className="w-full text-xs">
                    <span className="">{comment.comments}</span> <br />
                    <span className="text-gray-600">{formatDate(comment.createdDate)}</span>
                    <span className="text-gray-600 ps-3">By: {comment.creatorName}</span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="flex justify-center gap-5">
          <div onClick={handleSaveData}>
            <CustomButton1 label={"Save Remarks"} className="text-white bg-prp-color" />
          </div>
          <div onClick={onCancel}>
            <CustomButton2
              label={"Cancel"}
              variant="outlined"
              className="txt-prp-color"
            />
          </div>
        </div>
      </div>
    </>
  );
}
