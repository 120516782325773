import React, { useRef, useEffect } from 'react';
import CustomButton2 from '../../general-components/CustomButton2.component';

export default function PopupRemarks({ onCancel, piIssuedLogList }) {
    const scrollableRef = useRef(null);

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        return date.toLocaleString('en-US', options);
    };

    // Auto-scroll to the bottom of the remarks list
    useEffect(() => {
        if (scrollableRef.current) {
            scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
        }
    }, [piIssuedLogList]);

    return (
        <>
            <div className="bg-white rounded-lg max-w-full w-[500px] shadow-lg">
                {/* Header */}
                <div className="flex items-center bg-prp-color p-3 text-white justify-between rounded-t-lg">
                    <h1 className="text-lg font-medium">Remarks</h1>
                </div>

                {/* Scrollable Remarks Section */}
                <div
                    // ref={scrollableRef}
                    className="p-4 mx-4 my-4 max-h-[300px] overflow-y-auto border border-gray-200 rounded-lg"
                >
                    {piIssuedLogList && piIssuedLogList.length > 0 ? (
                        piIssuedLogList.map((log, index) => (
                            <div
                                key={index}
                                className="flex flex-col gap-1 txt-sm border border-gray-300 p-4 mb-4 rounded-lg shadow-sm"
                            >
                                <div>
                                    <p className="font-medium">
                                        Date: <span className="txt-prp-color font-normal">{formatDateTest(log.createdDate)}</span>
                                    </p>
                                </div>
                                <div>
                                    <p className="font-medium">
                                        Status: <span className="txt-prp-color font-normal">{log.statusName}</span>
                                    </p>
                                </div>
                                <div>
                                    <p className="font-medium">
                                        Remarks: <span className="txt-prp-color font-normal">{log.remarks}</span>
                                    </p>
                                </div>
                                <div>
                                    <p className="font-medium">
                                        Created By: <span className="txt-prp-color font-normal">{log.creatorName}</span>
                                    </p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-gray-500">No Remarks available.</p>
                    )}
                </div>

                {/* Footer */}
                <div className="flex justify-center gap-5 p-4">
                    <div onClick={onCancel}>
                        <CustomButton2 label="Cancel" variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    );
}
