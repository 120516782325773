// CORE
import * as React from "react";
import { useState } from "react";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";

export default function ContactDetails({
    cName,
    setCName,
    contactPersonCon,
    setContactPersonCon,
    mobileNoCon,
    setMobileNoCon,
    emailIdCon,
    setEmailIdCon,
    statusCon,
    setStatusCon,
    next,
    back,
    onCancel
}) {
    const [mobileError, setMobileError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [status, setStatus] = useState(true);

    const handleMobileChange = (e) => {
        const value = e.target.value;

        // Allow only numeric input
        if (/^\d*$/.test(value)) {
            setMobileNoCon(value);
        }
    };

    const validateMobile = (value) => {
        if (value && !/^\d+$/.test(value)) { // Validate only if value exists
            setMobileError('Mobile number must contain only numbers');
            return false;
        } else {
            setMobileError('');
            return true;
        }
    };
    const validateEmail = (value) => {
        if (!value) {
            setEmailError('Email is required');
            return false;
        } else if (!/\S+@\S+\.\S+/.test(value)) {
            setEmailError('Email is invalid');
            return false;
        } else {
            setEmailError('');
            return true;
        }
    };

    const handleNextClick = () => {
        const isMobileValid = validateMobile(mobileNoCon);
        const isEmailValid = validateEmail(emailIdCon);

        if (isMobileValid && isEmailValid) {
            next();
        }
    };

    return (
        <>
            <div>
                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[900px]">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField2
                                        label={"Customer Name"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        value={cName}
                                        onChange={setCName}
                                        readOnly={true}
                                    ></CustomTextField2>
                                </td>
                                <td>
                                    <CustomTextField2
                                        label={"Contact Person"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        value={contactPersonCon}
                                        onChange={setContactPersonCon}
                                    ></CustomTextField2>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="mobile" className="text-xs font-[400]">
                                            Mobile Number
                                        </label>
                                        <input
                                            type="tel"
                                            required
                                            value={mobileNoCon}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${mobileError ? 'border-red-500' : ''}`}
                                            id="mobile"
                                            placeholder="Enter"
                                            onChange={handleMobileChange}
                                            onBlur={(e) => validateMobile(e.target.value)}
                                        />
                                        {mobileError && <span className="text-red-500 text-xs">{mobileError}</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="email" className="text-xs font-[400]">
                                            Email Id
                                            <span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            required
                                            value={emailIdCon}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${emailError ? 'border-red-500' : ''}`}
                                            id="email"
                                            placeholder="Enter"
                                            onChange={(e) => setEmailIdCon(e.target.value)}
                                            onBlur={(e) => validateEmail(e.target.value)}
                                        />
                                        {emailError && <span className="text-red-500 text-xs">{emailError}</span>}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomCheckBox3
                                        label={"Is Active"}
                                        state={statusCon}
                                        setState={setStatusCon}
                                    ></CustomCheckBox3>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={back}>
                        <CustomButton2 label={"Back"} variant="outlined" className="txt-prp-color" />
                    </div>
                    <div onClick={handleNextClick}>
                        <CustomButton1 label={"Save and Continue"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    );
}
