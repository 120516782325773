// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import axios from 'axios';

// COMPONENTS
import Pagination from "../../components/general-components/Pagination.component";
import CustomButton1 from "../../components/general-components/CustomButton1.component";
import CustomSelect3 from "../../components/general-components/CustomSelect3.component";
import { IoSearchSharp } from "react-icons/io5";
import { IoMdRefresh } from "react-icons/io";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ManageTrackingStatusAll() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [inputData, setInputData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [trackingData, setTrackingData] = useState([]);
    const [poIdData, setPoIdData] = useState([]);
    const [countryId, setCountryId] = useState("");
    const [customerId, setCustomerId] = useState(0);
    const [trackingId, setTrackingId] = useState("");
    const [poId, setPoId] = useState("");
    const [piNumberData, setPiNumberData] = useState([]);
    const [piNumber, setPiNumber] = useState();

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const dataPOList = { pageNo: 0, pageSize: 0, searchText: "", isActive: null, customerId: 0, countryId: 0, statusId: 0, trakingStatusId: 0, trakingNumber: "", piNumber: "", isPIConfirmation: 0, isPaymentOrLCReceived: 0, filterType: "All" };

    const fetchApiData = async (updatedDataPOList = dataPOList) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/GetPurchaseOrderList`,
                updatedDataPOList,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );
            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedByActiveStatus = response.data.data.slice().sort((a, b) => {
                    if (a.isActive === b.isActive) {
                        return new Date(b.createdDate) - new Date(a.createdDate); // Secondary sorting by date if needed
                    }
                    return a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1;
                });

                setInputData(sortedByActiveStatus);

                // Sort data in ascending order by id
                const sortedById = response.data.data.slice().sort((a, b) => a.id - b.id);
                setPoIdData(sortedById);

                // Set total pages and current page
                setTotalPages(Math.ceil(sortedByActiveStatus.length / rowsPerPage));
                setCurrentPage(1);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Purchase Order Tracking List Data");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPiNumberData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/GetPINumberForSelectList`,
                { statusId: 0, customerId }, // Empty body for POST request
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setPiNumberData(response.data.data); // Update state with data
            } else {
                console.error("Invalid response format");
                toast.error("Unexpected response format.");
            }
        } catch (error) {
            console.error("Error fetching PI Number Data:", error);
            toast.error("Error fetching PI Number Data");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Fetch PI Numbers without requiring customer selection
        fetchPiNumberData(customerId || 0); // If customerId is not set, pass 0
    }, [customerId]);
    

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                url,
                { isActive: true },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data.sort((a, b) => a.id - b.id);
                // Log sorted data to verify
                console.log("Sorted Data:", sortedData);
                setData(sortedData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(
            `${process.env.REACT_APP_BASE_URL}/AdminMaster/GetTrackingStatusList`,
            setTrackingData,
            "Error fetching Tracking Status Data"
        );
    }, []);

    const createChangeHandler = (setter) => (selectedOption) => {
        if (selectedOption) {
            setter(selectedOption.value);
        } else {
            setter(null);
        }
    };

    const handleCountryChange = createChangeHandler(setCountryId);
    const handleCustomerChange = createChangeHandler(setCustomerId);
    const handleTrackingChange = createChangeHandler(setTrackingId);
    // const handlePoIdChange = createChangeHandler(setPoId);

    const handlePoIdChange = (selectedOption) => {
        if (selectedOption) {
            setPiNumber(selectedOption.value); // Store the selected object (value + label)
        } else {
            setPiNumber(null); // Reset if no option is selected
        }
    };

    const handleSearch = () => {
        const updatedDataPOList = {
            ...dataPOList,
            customerId: customerId || 0,
            countryId: countryId || 0,
            trakingStatusId: trackingId || 0,
            trakingNumber: poId || "",
            piNumber: piNumber ? piNumberData.find(pi => pi.value === piNumber)?.text : "" // Pass the label (text) to the API
        };

        fetchApiData(updatedDataPOList);
    };

    const handleReset = () => {
        setCountryId("");
        setCustomerId(0);
        setTrackingId("");
        setPoId("");
        setPiNumber("");
        setPiNumberData([]); // Reset PI numbers
        fetchApiData(dataPOList); // Passing default dataPOList to fetch all records
    };

    const paginatedData = inputData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(() => {
        fetchApiData(dataPOList);
    }, [rowsPerPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(inputData.length / rowsPerPage));
    }, [inputData, rowsPerPage]);

    const handleParentCustomerPage = (cId, tId, id) => {
        navigate(`/admin/app/manage-tracking-status/customer-tracking-status?orderId=${id}&customerId=${cId}&trackingId=${tId}&tab=2`);
    };

    const uniqueBy = (arr, key) => {
        return Array.from(new Map(arr.map(item => [item[key], item])).values());
    };

    const getCountries = () => {
        // Filter out records with empty countryId or countryName
        const filteredData = poIdData.filter(data => data.countryId && data.countryName);

        // Apply uniqueBy function and map the data
        return uniqueBy(filteredData, 'countryId').map(data => ({
            value: data.countryId,
            label: data.countryName
        }));
    };

    const getCustomers = () => {
        const filteredByCountry = poIdData.filter(item => item.countryId === countryId);
        return uniqueBy(filteredByCountry, 'customerId').map(data => ({
            value: data.customerId,
            label: data.customerName
        }));
    };

    const getPiNumber = () => {
        return piNumberData.map(pi => ({
            value: pi.value,
            label: pi.text,
        }));
    };

    const getTrackingIds = () => {
        const filteredByCustomer = poIdData.filter(item => item.customerId === customerId);
        return uniqueBy(filteredByCustomer, 'trackingNumber').map(data => ({
            value: data.trackingNumber,
            label: data.trackingNumber
        }));
    };

    const getTrackingStatusOptions = () => {
        if (customerId) {
            // Filter `poIdData` for the selected `customerId`
            const filteredByCustomer = poIdData.filter(item => item.customerId === customerId);

            // Extract unique normalized `poCurrentStatus` values
            const normalizeStatus = (status) => status.trim().toLowerCase().replace(/-/g, ' ');
            const uniqueStatuses = [...new Set(filteredByCustomer.map(item => normalizeStatus(item.poCurrentStatus)))];

            // Match these statuses with `trackingData` options
            return trackingData
                .filter(item => uniqueStatuses.includes(normalizeStatus(item.trackingStatus)))
                .map(item => ({ value: item.id, label: item.trackingStatus }));
        }

        // Return all options if no `customerId` is selected
        return trackingData.map(item => ({ value: item.id, label: item.trackingStatus }));
    };

    return (
        <>
            <div className="flex items-center gap-2 justify-between  px-2  ml-auto text-[rgba(0, 0, 0, 0.50)]  minbox">
                <div className="w-[26%] ">
                    <CustomSelect3
                        placeholder="Country"
                        options={getCountries()}
                        onChange={handleCountryChange}
                        value={countryId}
                    />
                </div>
                <div className="w-[26%] ">
                    <CustomSelect3
                        placeholder="Customer Name"
                        options={getCustomers()}
                        onChange={handleCustomerChange}
                        value={customerId}
                    />
                </div>
                {/* <div className="w-[26%] ">
                    <CustomSelect3
                        placeholder="Tracking Status"
                        options={getTrackingStatusOptions()} // Dynamically filtered options
                        onChange={handleTrackingChange}
                        value={trackingId}
                    />
                </div> */}
                <div className="w-[26%] ">
                    <CustomSelect3
                        placeholder="PI Number"
                        options={getPiNumber()} // Filtered PI numbers
                        onChange={handlePoIdChange}
                        value={piNumber} // Selected PI number
                        isLoading={isLoading}
                    />
                </div>
                <div className="flex justify-end gap-2 w-[12%]">
                    <div onClick={handleSearch}>
                        <Tooltip title="SEARCH" arrow placement="bottom">
                            <span>
                                <CustomButton1 icon={<IoSearchSharp />}
                                    className="text-white bg-prp-color py-2"
                                />
                            </span>
                        </Tooltip>
                    </div>
                    <div onClick={handleReset}>
                        <Tooltip title="RESET" arrow placement="bottom">
                            <span>
                                <CustomButton1 icon={<IoMdRefresh />} className="bg-white py-2 " />
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <div className="mt-7 max-w-[100vw] hide-scrollbar overflow-auto table-container">
                <table className="w-full custom-table" border={1}>
                    <thead>
                        <tr className="table-heading">
                            <td className="">S.No</td>
                            <td>Tracking Id</td>
                            <td>Tracking Status</td>
<td>Pi Number</td>

                            <td>Customer Name</td>
                            <td>Parent Customer</td>
                            <td>Country</td>
                            <td>Port of Discharge</td>
                            <td>PO Received</td>
                            <td>PO Number</td>
                            <td>Payment Terms</td>
                            <td>Qty</td>
                            <td>Currency Type</td>
                            <td>Currency Value</td>
                            <td>Paper Type</td>
                            <td>Brand</td>
                            <td>Type of Packaging</td>
                            <td>Delivery Terms</td>
                            <td>PO Status</td>
                            <td>Created Date </td>
                            <td>Created By </td>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="20" className="text-start">Loading...</td>
                            </tr>
                        ) : (
                            paginatedData.map((cData, index) => (
                                <tr key={cData.id}>
                                    <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                    <td>
                                        <div
                                            onClick={() => {
                                                if (cData.trackingNumber) {
                                                    handleParentCustomerPage(cData.customerId, cData.trackingNumber, cData.id);
                                                }
                                            }}
                                            className={`w-full min-h-full underline txt-prp-color cursor-pointer`}
                                        >
                                            {cData.trackingNumber}
                                        </div>
                                    </td>
                                    <td>{cData.poCurrentStatus}</td>
<td>{cData.piNumber}</td>

                                    <td>{cData.customerName}</td>
                                    <td>{cData.parentCustomer}</td>
                                    <td>{cData.countryName}</td>
                                    <td>{cData.pO_PortDischarge}</td>
                                    <td>{cData.pO_IsPOReceived ? 'true' : 'false'}</td>
                                    <td>{cData.pO_PONumber}</td>
                                    <td>{cData.pO_PaymentTerms}</td>
                                    <td>{cData.pO_Quantity}</td>
                                    <td>{cData.pO_CurrencyType}</td>
                                    <td>{cData.pO_CurrencyValue}</td>
                                    <td>{cData.pO_PaperType}</td>
                                    <td>{cData.pO_Brand}</td>
                                    <td>{cData.pO_TypeOfPackaging}</td>
                                    <td>{cData.pO_DeliveryTerms}</td>
                                    <td>{cData.pO_IsPOStatusClosed ? 'true' : 'false'}</td>
                                    <td>{new Date(cData.createdDate).toLocaleDateString('en-GB')}</td>
                                    <td>{cData.creatorName}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            <Pagination
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                data={inputData}
            />

            <ToastContainer autoClose={2000} />

        </>
    );
}

export default ManageTrackingStatusAll;
