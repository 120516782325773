import React, { useEffect, useState } from 'react'
import CustomButton2 from '../../general-components/CustomButton2.component'
import CustomButton1 from '../../general-components/CustomButton1.component'
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomInputNumber from '../../general-components/CustomInputNumber.component';
import CustomSelect1 from '../../general-components/CustomSelect1.component';
import CustomDate from './../../general-components/CustomDate.component';
import CustomCheckBox2 from './../../general-components/CustomCheckBox2.component';
import CustomInputNumberDecimal from '../../general-components/CustomInputNumberDecimal.component';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ManageSBillRecordEdit({ onCancel, selectedValue }) {
    const [testCheck, setTestCheck] = useState(true);
    const [testCheck1, setTestCheck1] = useState(false);
    const [testCheck2, setTestCheck2] = useState(false);
    const [testCheck3, setTestCheck3] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [test1, setTest1] = useState("");
    const [test2, setTest2] = useState("");
    const [test3, setTest3] = useState("");
    const [test4, setTest4] = useState("");
    const [test6, setTest6] = useState("");
    const [test7, setTest7] = useState("");
    const [test11, setTest11] = useState("");
    const [test8, setTest8] = useState("");
    const [test9, setTest9] = useState(0);
    const [portName, setPortName] = useState("");
    const [portId, setPortId] = useState("");


    // const [fobValueRs, setFobValueRs] = useState(0);
    const [fobValueTotal, setFobValueTotal] = useState("");

    const [fobValue, setFobValue] = useState(0); // FOB Value (invoiceAmount - freight)
    const [exchangeRate, setExchangeRate] = useState(5); // Default exchange rate
    const [fobValueRs, setFobValueRs] = useState(0); // FOB Value (Rs)
    const [fobPercent, setFobPercent] = useState(2); // FOB %
    const [dbkValue, setDbkValue] = useState(0); // DBK Value

    const [freight, setFreight] = useState(0);
    const [invoiceAmount, setInvoiceAmount] = useState(0);

    useEffect(() => {
        if (selectedValue) {
            console.log("Selected Value:", selectedValue); // Log selectedValue
            setTestCheck(selectedValue.brcInBank);
            setTestCheck1(selectedValue.brcInDGFT);
            setTestCheck3(selectedValue.dbkReceived);
            setTestCheck2(selectedValue.igstReceived);
            setFobValueTotal(selectedValue.totalFOBValue)
            setTest1(selectedValue.leoDate ? selectedValue.leoDate.split("T")[0] : null); // Handle null/undefined
            setTest2(selectedValue.sbDate ? selectedValue.sbDate.split("T")[0] : null);   // Handle null/undefined
            setTest3(selectedValue.sbNo);
            setTest4(selectedValue.freight);
            setTest6(selectedValue.exchangeRate);
            setTest7(selectedValue.portCode);
            setTest11(selectedValue.igstAmount);
            setTest8(selectedValue.fobPerct);
            setTest9(selectedValue.dbkValue);
            setFobValueRs(selectedValue.totalFOBValue);
            setPortName(selectedValue.portName);
            setPortId(selectedValue.portId);

            setFreight(selectedValue.freight || 0);
            setInvoiceAmount(selectedValue.invoiceAmount || 0);
            setExchangeRate(selectedValue.exchangeRate || 0);
            setFobPercent(selectedValue.fobPerct || 0);
        } else {
            console.log("No selected value provided"); // Log if selectedValue is null/undefined
        }
    }, [selectedValue]);

    // const calculateFOBValueRs = (fobValue, exchangeRate) => fobValue * exchangeRate;
    // const calculateDBKValue = (fobValueRs, fobPercent) => (fobValueRs * fobPercent) / 100;

    // useEffect(() => {
    //     const fobRs = calculateFOBValueRs(fobValueTotal, test6);
    //     setFobValueRs(fobRs);
    // }, [fobValueTotal, test6]);

    // useEffect(() => {
    //     const dbk = calculateDBKValue(fobValueRs, test8);
    //     setTest9(dbk);
    // }, [fobValueRs, test8]);

    // Calculate FOB Value
    useEffect(() => {
        const fob = invoiceAmount - freight;
        setFobValue(fob > 0 ? fob : 0); // Ensure FOB value is non-negative
    }, [invoiceAmount, freight]);

    // Calculate FOB Value in INR
    useEffect(() => {
        const fobInRs = fobValue * exchangeRate;
        setFobValueRs(fobInRs > 0 ? fobInRs : 0); // Ensure non-negative
    }, [fobValue, exchangeRate]);

    // Calculate DBK Value
    useEffect(() => {
        const dbk = (fobValueRs * fobPercent) / 100;
        setDbkValue(dbk > 0 ? dbk : 0); // Ensure non-negative
    }, [fobValueRs, fobPercent]);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            let requestBody = {};
            requestBody = {
                id: selectedValue.id,
                sbNo: test3 || "",
                sbDate: test2 || null,
                leoDate: test1 || null,
                portId: portId || 0,
                freight: freight || 0,
                totalFOBValue: fobValueRs || 0,
                exchangeRate: exchangeRate || 0,
                brcInBank: testCheck,
                brcInDGFT: testCheck1,
                fobPerct: fobPercent || 0,
                dbkValue: dbkValue || 0,
                dbkReceived: testCheck3,
                igstAmount: test11 || 0,
                igstReceived: testCheck2
            };
            console.log("Request Body:", requestBody); // Debugging: check request body

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageMIS/UpdateMIS_SBill`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            console.log("Response Data:", responseData); // Debugging: check response data

            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                // setUpdatePopup(false);
                setTimeout(() => {
                    onCancel()
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing operation:", error);
            toast.error("Error performing operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Edit SHIPPING Bill Record</h1>
                    <div className="flex  item-center text-sm"></div>
                </div>

                <div className="p-4 gap-4 max-h-[70vh] overflow-y-auto max-w-full w-[900px]">
                    <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                        <CustomInputNumber label="SB No" placeholder="Read Only" isRequired={false}
                            value={test3}
                            onChange={setTest3}
                            readOnly
                        />
                        <CustomDate label="SB Date" placeholder="Read Only" isRequired={false}
                            value={test2}
                            onChange={setTest2}
                            readOnly
                        />

                        <CustomDate label="LEO DATE" placeholder="Enter" isRequired={false}
                            value={test1}
                            onChange={setTest1}
                        />

                        <CustomTextField2 label="Port Name" placeholder="Read Only" isRequired={false}
                            value={portName}
                            // onChange={setTest7}
                            readOnly
                        />

                        <CustomTextField2 label="Port Code" placeholder="Read Only" isRequired={false}
                            value={test7}
                            onChange={setTest7}
                            readOnly
                        />

                        {/* <CustomInputNumber label="CIF/CFR VALUE" placeholder="Enter" isRequired={false}
                            value={test2}
                            onChange={setTest2}
                        /> */}
                        <CustomInputNumberDecimal label="FREIGHT" placeholder="Read Only" isRequired={false}
                            // value={test4}
                            // onChange={setTest4}
                            value={freight}
                            onChange={setFreight}
                            readOnly
                        />


                        {/* <CustomInputNumber label="FOB VALUE(RS.)" placeholder="Enter" isRequired={false}
                            value={test8}
                            onChange={setTest8}
                        /> */}

                        <CustomInputNumberDecimal label="EXCHANGE RATE" placeholder="Enter" isRequired={false}
                            // value={test6}
                            // onChange={setTest6}
                            value={exchangeRate}
                            onChange={setExchangeRate}

                        />
                        <CustomInputNumberDecimal label="FOB VALUE(RS.)" placeholder="Read Only" readOnly isRequired={false}
                            // value={fobValueRs}
                            // onChange={setFobValueRs}
                            value={fobValueRs}
                        />
                        <CustomCheckBox2
                            label={"BRC IN BANK"}
                            state={testCheck}
                            setState={setTestCheck}
                        ></CustomCheckBox2>
                        <CustomCheckBox2
                            label={"BRC IN DGFT"}
                            state={testCheck1}
                            setState={setTestCheck1}
                        ></CustomCheckBox2>
                        <CustomInputNumberDecimal label="FOB %" placeholder="Enter" isRequired={false}
                            // value={test8}
                            // onChange={setTest8}
                            value={fobPercent}
                            onChange={setFobPercent}
                        />
                        <CustomInputNumber label="DBK VALUE" placeholder="Enter" isRequired={false} readOnly
                            // value={test9}
                            // onChange={setTest9}
                            value={dbkValue}
                        />
                        <CustomCheckBox2
                            label={"DBK RECEIVED"}
                            state={testCheck3}
                            setState={setTestCheck3}
                        ></CustomCheckBox2>

                        <CustomInputNumberDecimal label="IGST AMOUNT" placeholder="Enter" isRequired={false}
                            value={test11}
                            onChange={setTest11}
                        />
                        <CustomCheckBox2
                            label={"IGST RECEIVED"}
                            state={testCheck2}
                            setState={setTestCheck2}
                        ></CustomCheckBox2>
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" type="submit" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}


