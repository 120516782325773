import React, { useEffect, useMemo, useState } from 'react';
import { ImEnlarge } from "react-icons/im";
import { MdCancel } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import "../../assets/css/selectbar.css";

function CustomBrowsePi(props) {
    const { id, label, isRequired, readOnly, value, onChange, disabled } = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState("");
    const [fileBase64, setFileBase64] = useState("");

    useEffect(() => {
        if (error) {
            const errorTimeout = setTimeout(() => {
                setError("");
            }, 5000);

            return () => clearTimeout(errorTimeout);
        }
    }, [error]);

    const filePreviewUrl = useMemo(() => {
        return selectedFile ? URL.createObjectURL(selectedFile) : null;
    }, [selectedFile]);

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
            if (!allowedTypes.includes(file.type)) {
                setError("jpg, jpeg, png images and PDF are allowed.");
                return;
            }
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
            if (fileSizeInMB > 3) {
                setError("File size is more than 3 MB.");
                return;
            } else {
                setError("");
                setSuccessMessage("Uploading.....");
                setTimeout(() => {
                    // Clear success message after 3 seconds
                    setSuccessMessage("");
                }, 5000);
            }
            setFileName(file.name);
            setSelectedFile(file);
            setFileType(file.type);

            const base64 = await convertToBase64(file);
            setFileBase64(base64);
            onChange({ base64, name: file.name });
        } else {
            setFileName("");
            setSelectedFile(null);
            setFileType("");
            setFileBase64("");
            onChange({ base64: "", name: "" });
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result.replace(/^data:(.*,)?/, '');
                resolve(base64String);
            };
            reader.onerror = (error) => reject(error);
        });
    };

    const handleCancel = () => {
        setSelectedFile(null);
        setFileName("");
        setFileType("");
        setFileBase64("");
        onChange({ base64: "", name: "" });

        document.getElementById(`file-upload-${id}`).value = null;
    };

    const handleDownload = () => {
        const url = URL.createObjectURL(selectedFile);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleEnlarge = () => {
        const url = URL.createObjectURL(selectedFile);
        window.open(url, '_blank'); // Opens in a new tab
    };

    return (
        <div className="flex flex-col relative">
            <label htmlFor={id} className="text-xs font-[400]">
                {label.toUpperCase()}
                {isRequired && <span className="text-red-500 gap-3">*</span>}
            </label>
            <div className="flex justify-between items-center pb-5">
                <div>
                    <label
                        htmlFor={`file-upload-${id}`}
                        className={disabled ? "cursor-pointer text-xs bg-gray-600 text-white p-2 rounded-md" : "cursor-pointer text-xs bg-prp-color text-white p-2 rounded-md"}
                    >
                        Attachment
                    </label>
                    <input
                        type="file"
                        id={`file-upload-${id}`}
                        className="hidden"
                        onChange={handleFileChange}
                        disabled={disabled}
                    />
                </div>

                {fileName && (
                    <div className="flex items-center space-x-2">
                        <button onClick={handleDownload} type='button' className="bg-prp-color text-white p-2 rounded">
                            <FaDownload h-8 w-8 />
                        </button>
                        <button onClick={handleCancel} type='button' className="bg-white txt-prp-color border border-prp p-2 rounded">
                            <MdCancel h-8 w-8 />
                        </button>
                        <button onClick={handleEnlarge} type='button' className="bg-prp-color text-white p-2 rounded">
                            <ImEnlarge h-8 w-8 />
                        </button>
                    </div>
                )}

                <div>
                    {!selectedFile && (
                        <div className="flex w-10 h-10 rounded-full border border-[#3D3D3D66]">
                            <span className="text-[#3D3D3D66] flex items-center justify-center text-[10px] text-center">
                                No Preview
                            </span>
                        </div>
                    )}
                    {selectedFile && fileType.startsWith('image/') && (
                        <img
                            src={filePreviewUrl}
                            alt="Profile"
                            className="w-10 h-10 rounded-full border border-[#3D3D3D66]"
                        />
                    )}
                    {selectedFile && fileType === 'application/pdf' && (
                        <embed
                            src={filePreviewUrl}
                            type="application/pdf"
                            alt="Enlarged"
                            className="w-10 h-10 border border-[#3D3D3D66]"
                        />
                    )}
                </div>
            </div>
            {error && <p className="absolute bottom-0 text-[10px] font-normal" style={{ color: 'red' }}>{error}</p>}
            {successMessage && (
                <p className="absolute bottom-0 text-[10px] font-normal" style={{ color: 'green' }}>{successMessage}</p>
            )}
        </div>
    );
}

CustomBrowsePi.defaultProps = {
    isRequired: true,
};

export default CustomBrowsePi;
