import { AiOutlineSearch } from "react-icons/ai";
import { Checkbox, Collapse, List, ListItem } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { onlyIcon } from "../../Admin.page";
import { TabArray } from "./TabArray";
import { useAuth } from "../../context";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
// import { Collapse } from '@material-ui/core';

const RenderSubSubTabs = ({ list, pathWitoutAdmin }) => {
    const navigate = useNavigate();
    return (
        <ul className="ms-4">
            {list.map((subTab, index) => {
                if (!subTab.show) {
                    return "";
                }
                return (
                    <li
                        key={index}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(subTab.path);
                        }}
                    >
                        <div
                            className={`cursor-pointer main-tab flex items-center px-2 ps-0 transition-all duration-500  overflow-hidden`}
                            style={{ borderRadius: "10px", marginInline: "5px" }}
                        >
                            <Checkbox
                                checked={subTab.path === pathWitoutAdmin || pathWitoutAdmin?.includes(subTab.path)}
                                style={{ color: "#643c94" }}
                            />
                            <div to={subTab.path} className="">
                                {subTab.title}
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

const RenderSubTabs = ({ subTabs, selectedTab, lastPath, pathWitoutAdmin, path, shouldSubTabsBeOpen, tab }) => {
    const navigate = useNavigate();
    return (
        <div>
            <Collapse
                orientation="vertical"
                in={!onlyIcon && shouldSubTabsBeOpen(tab) && Array.isArray(tab.subTabs)}
                timeout={500}
            >
                {!onlyIcon && shouldSubTabsBeOpen(tab) && Array.isArray(tab.subTabs) && (
                    <>
                        {subTabs &&
                            subTabs.length &&
                            subTabs?.map((subTab) => {
                                if (!subTab.show) {
                                    return "";
                                }
                                const checked =
                                    selectedTab &&
                                    Array.isArray(selectedTab?.subTabs) &&
                                    selectedTab?.subTabs?.some(
                                        (ele) =>
                                            (ele.path === subTab.path && ele.path === lastPath) ||
                                            selectedTab?.subTabs?.some(
                                                (ele) =>
                                                    (ele.to === pathWitoutAdmin &&
                                                        pathWitoutAdmin?.includes(subTab.path)) ||
                                                    subTab?.subTabs?.some((i) => pathWitoutAdmin?.includes(i.path))
                                            ) ||
                                            pathWitoutAdmin?.includes(subTab.path)
                                    );
                                return (
                                    <li
                                        key={subTab.to || subTab.path}
                                        style={{ borderRadius: "10px", marginInline: "10px" }}
                                        className={`cursor-pointer main-tab ms-4 py-1 transition-all duration-500`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate((path || "") + (path ? "/" : "") + subTab.path);
                                        }}
                                    >
                                        <div to={(path || "") + (path ? "/" : "") + subTab.path}>
                                            <Checkbox checked={checked} style={{ color: "#643c94" }} />
                                            <span>{subTab.title}</span>
                                            <Collapse in={subTab.subTabs?.length} timeout="auto">
                                                {subTab.subTabs && subTab.subTabs?.length && (
                                                    <RenderSubSubTabs
                                                        list={subTab.subTabs}
                                                        pathWitoutAdmin={pathWitoutAdmin}
                                                    />
                                                )}
                                            </Collapse>
                                        </div>
                                    </li>
                                );
                            })}
                    </>
                )}
            </Collapse>
        </div>
    );
};

const RenderMainTabs = ({
    list,
    selectedTab,
    setLastTab,
    handleTabClick,
    shouldSubTabsBeOpen,
    lastPath,
    pathWitoutAdmin,
    userRoleList,
    searchQuery,
    setSelectedTab,
}) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    useEffect(() => {
        if (!open && searchQuery) {
            setOpen(selectedTab ? shouldSubTabsBeOpen(selectedTab) : true);
            // setSelectedTab(null);
        }
    }, [selectedTab, searchQuery]);
    return (
        <Collapse timeout={1000} in>
            {list.map((tab) => {
                if (!tab.show) {
                    return "";
                }
                const selected = selectedTab && tab?.title === selectedTab?.title;
                return (
                    <li
                        key={tab.title}
                        onClick={() => {
                            setOpen(!open);
                            handleTabClick(tab);
                            navigate(tab.to);
                        }}
                    >
                        <div>
                            <div
                                className={`${selected ? "bg-prp-color text-white" : "text-[#3D3D3D]"
                                    } cursor-pointer main-tab flex items-center gap-3 py-3 my-1  px-2 transition-all duration-500  overflow-hidden`}
                                style={{ borderRadius: "10px", marginInline: "10px" }}
                            >
                              <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center">
                                        <span className={`min-w-[30px] text-xl`}>
                                            {selected ? tab.activeIcon : tab.inActiveIcon}
                                        </span>
                                        {!onlyIcon && <div className="ml-2">{tab.title}</div>}
                                    </div>
                                    {/* Add arrow icon if tab has sub-tabs */}
                                    {tab.subTabs && tab.subTabs.length > 0 && (
                                        <span className="text-lg">
                                            {open ? <FaCaretDown /> : <FaCaretRight />}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <Collapse timeout="auto" in={open}>
                                <RenderSubTabs
                                    subTabs={tab.subTabs}
                                    path={tab.path}
                                    shouldSubTabsBeOpen={shouldSubTabsBeOpen}
                                    tab={tab}
                                    selectedTab={selectedTab}
                                    lastPath={lastPath}
                                    pathWitoutAdmin={pathWitoutAdmin}
                                />
                            </Collapse>
                        </div>
                    </li>
                );
            })}
        </Collapse>
    );
};
function NavTabs() {
    const [searchQuery, setSearchQuery] = useState(""); // State to store the search query
    const [selectedTab, setSelectedTab] = useState(null);
    console.log("🚀 ~ file: NavTabs.components.jsx:200 ~ NavTabs ~ selectedTab:", selectedTab);
    const location = useLocation();
    const lastPath = useMemo(() => location?.pathname?.split("admin")[1].split("/").pop(), [location]);
    const pathWitoutAdmin = useMemo(() => location?.pathname?.split("/admin/")[1], [location]);
    const [lastTab, setLastTab] = useState(null);

    // useEffect(() => {
    //     const array = location?.pathname?.split("admin")
    //     if (array[1].includes("app")) {
    //         const last = array[1].split("/").pop()
    //         setSelectedTab(TabArray.find(ele => {
    //             return (array[1].includes(ele.to) ||
    //                 ele?.subTabs?.path?.includes(last) ||
    //                 (ele?.subTabs?.length && ele?.subTabs?.some(ele => ele.path === last)) ||
    //                 (ele?.subTabs?.length && ele?.subTabs?.some(ele => location?.pathname?.split("admin")[1].includes(ele.path)))
    //             )
    //         }))
    //     } else {
    //         setSelectedTab(TabArray.find(ele => ele?.admin))
    //     }
    // }, [location])

    useEffect(() => {
        const array = location?.pathname?.split("admin");
        if (array[1].includes("app")) {
            const last = array[1].split("/").pop();
            const tab = TabArray.find((ele) => {
                return (
                    array[1].includes(ele.to) ||
                    ele?.subTabs?.path?.includes(last) ||
                    (ele?.subTabs?.length && ele?.subTabs?.some((ele) => ele.path === last)) ||
                    (ele?.subTabs?.length &&
                        ele?.subTabs?.some((ele) => location?.pathname?.split("admin")[1].includes(ele.path)))
                );
            });
            setSelectedTab(tab);
            setLastTab(tab);
        } else {
            setSelectedTab(TabArray.find((ele) => ele?.admin));
            setLastTab(TabArray.find((ele) => ele?.admin));
        }
    }, [location]);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        setLastTab(tab);
    };

    const shouldSubTabsBeOpen = (tab) => {
        return (selectedTab?.title === tab?.title &&
            tab?.subTabs &&
            tab?.subTabs?.length &&
            (Array.isArray(tab.subTabs) ? tab.subTabs.length > 0 : true)) ||
            (searchQuery && Array.isArray(tab?.subTabs) && tab?.subTabs?.length)
            ? tab?.subTabs?.some((ele) => ele.title.toLowerCase().includes(searchQuery.toLowerCase()))
            : false;
    };
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    useEffect(() => {
        if (!searchQuery && lastTab) {
            setSelectedTab(lastTab);
        }
    }, [searchQuery, lastTab]);

    const { user } = useAuth();
    // const { user } = useAuth();
    console.log("🚀 ~ file: NavTabs.components.jsx:279 ~ NavTabs ~ user:", user);
    const userRoleList = user?.userRoleList;
    console.log("🚀 ~ file: NavTabs.components.jsx:324 ~ NavTabs ~ userRoleList:", userRoleList);
    const checkAdminAllTab = (tabsList) => {
        const tl = tabsList.map((ele) => ({ ...ele, permission: userRoleList?.find((vl) => vl.moduleId === ele?.id) }));
        return tl.some((ele) => ele?.permission?.view || ele?.permission?.edit || ele?.permission?.add);
    };
    const getFilterList = (list) => {
        return list.filter((ele) => {
            const f = userRoleList?.find((v) => v.moduleId === ele?.id || ele?.subId?.includes(v.moduleId));
            return Boolean(f?.view || f?.edit || f?.add);
        });
    };

    // const isAdmin = () => { return [1, 2].includes(Number(user.roleId)) }
    const isAdmin = () => { };
    const tabs = useMemo(
        () =>
            TabArray.map((ele) => {
                const main = userRoleList?.find((v) => v.moduleId === ele?.id) || ele.tabIds?.length;
                if (ele?.admin || ele?.sub) {
                    return {
                        ...ele,
                        show: checkAdminAllTab(ele?.subTabs) || isAdmin(),
                        to: ele?.admin
                            ? getFilterList(ele?.subTabs)?.[0]?.path
                            : getFilterList(ele?.subTabs)?.[0]?.to || ele?.to,
                        toL: getFilterList(ele?.subTabs),
                        subTabs: ele?.subTabs?.map((valEle) => {
                            if (valEle?.subsub) {
                                return {
                                    ...valEle,
                                    show: checkAdminAllTab(valEle?.subTabs) || valEle?.id === 0 || isAdmin(),
                                    path: getFilterList(valEle?.subTabs)?.[0]?.path,
                                    subTabs: valEle?.subTabs.map((sVal) => {
                                        const nT = userRoleList?.find((n) => n.moduleId === sVal.id);
                                        return {
                                            ...sVal,
                                            show: nT?.view || nT?.edit || nT?.add || sVal.id === 0 || isAdmin(),
                                        };
                                    }),
                                };
                            }
                            const mainSub = userRoleList?.find((v) => v.moduleId === valEle?.id);
                            if (mainSub) {
                                if (mainSub?.view || mainSub?.edit || mainSub?.add) {
                                    return { ...valEle, show: true || isAdmin() };
                                } else {
                                    return { ...valEle, show: false || isAdmin() };
                                }
                            }
                            return { ...valEle, show: false || isAdmin() };
                        }),
                    };
                }
                if (main) {
                    if (main.view || main.edit || main.add) {
                        return { ...ele, show: true || isAdmin() };
                    } else {
                        return { ...ele, show: false || isAdmin() };
                    }
                }
                return { ...ele, show: false || isAdmin() };
            }).filter((tab) => {
                const searchQ = searchQuery.toLowerCase();
                const retunData =
                    tab.title.toLowerCase().includes(searchQ) ||
                    tab.subTabs?.title?.toLowerCase().includes(searchQ) ||
                    (tab.subTabs?.length && tab.subTabs?.some((ele) => ele.title?.toLowerCase().includes(searchQ)));
                if (tab.subTabs?.length && tab.subTabs?.some((ele) => ele.title?.toLowerCase().includes(searchQ))) {
                    tab.subTabs = tab.subTabs.map((st) => ({
                        ...st,
                        show: searchQ ? (st?.show ? st.title.toLowerCase().includes(searchQ) : st?.show) : st?.show,
                    }));
                }
                return retunData;
            }),
        [searchQuery, TabArray, userRoleList]
    );
    return (
        <div className="sidebar">
            <ul>
                {onlyIcon ? null : (
                    <div>
                        <div
                            className="flex items-center px-2 py-2 my-2 mx-2 gap-4 border rounded bg-[#BDC8DD] "
                            style={{ borderRadius: "10px" }}
                        >
                            <AiOutlineSearch className="icon min-w-[30px]" />
                            {onlyIcon ? null : (
                                <input
                                    type="search"
                                    className="w-full bg-transparent shrink grow placeholder:text-fourth uppercase"
                                    placeholder="search"
                                    value={searchQuery}
                                    onChange={handleSearchInputChange} // Handle search input change
                                />
                            )}
                        </div>
                    </div>
                )}
                <RenderMainTabs
                    list={tabs}
                    selectedTab={selectedTab}
                    handleTabClick={handleTabClick}
                    pathWitoutAdmin={pathWitoutAdmin}
                    shouldSubTabsBeOpen={shouldSubTabsBeOpen}
                    lastPath={lastPath}
                    userRoleList={userRoleList}
                    searchQuery={searchQuery}
                />
            </ul>
        </div>
    );
}

export default NavTabs;
