import * as React from "react";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import Select from "react-select";
import Cookies from "js-cookie"; // Import js-cookie for handling cookies

export default function ShippingDetails({
    streetNameShipping,
    setStreetNameShipping,
    allTerDataTemp,
    setSelectedCountryTemp,
    selectedCountryTemp,

    back,
    onCancel,
    handleCustomerDataSubmit,
}) {

    // Load shipping details from cookies
    React.useEffect(() => {
        const savedShippingData = Cookies.get("shippingDetails");
        const savedBillingData = Cookies.get("billingDetails");

        if (savedBillingData) {
            const parsedBillingData = JSON.parse(savedBillingData);

            if (parsedBillingData.isSameAsShipping) {
                // If checkbox is checked, use billing details for shipping
                const matchedBillingCountry = allTerDataTemp.find(
                    (data) => data.id === parsedBillingData.countryId
                );
                if (matchedBillingCountry) {
                    setSelectedCountryTemp(matchedBillingCountry.id);
                    setStreetNameShipping(parsedBillingData.streetName || "");
                    return; // Skip loading separate shipping details
                }
            }
        }

        // Otherwise, load actual shipping details
        if (savedShippingData) {
            const parsedShippingData = JSON.parse(savedShippingData);
            const matchedShippingCountry = allTerDataTemp.find(
                (data) => data.id === parsedShippingData.countryId
            );
            if (matchedShippingCountry) {
                setSelectedCountryTemp(matchedShippingCountry.id);
                setStreetNameShipping(parsedShippingData.streetName || "");
            }
        }
    }, [allTerDataTemp, setStreetNameShipping, setSelectedCountryTemp]);

    const handleCountryChangeTemp = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountryTemp(selectedOption.value);

            // Save only shipping details when checkbox is false
            const billingData = Cookies.get("billingDetails");
            const isSameAsShipping = billingData ? JSON.parse(billingData).isSameAsShipping : false;

            if (!isSameAsShipping) {
                Cookies.set("shippingDetails", JSON.stringify({
                    countryId: selectedOption.value,
                    streetName: streetNameShipping,
                }));
            }
        } else {
            setSelectedCountryTemp(null);
            Cookies.remove("shippingDetails");
        }
    };


    const handleStreetChange = (event) => {
        const newStreetName = event.target.value;
        setStreetNameShipping(newStreetName);

        // Save only shipping details when checkbox is false
        const billingData = Cookies.get("billingDetails");
        const isSameAsShipping = billingData ? JSON.parse(billingData).isSameAsShipping : false;

        if (!isSameAsShipping) {
            Cookies.set("shippingDetails", JSON.stringify({
                countryId: selectedCountryTemp,
                streetName: newStreetName,
            }));
        }
    };


    return (
        <>
            <div>
                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[900px] ">
                        <tbody>
                            <tr className="w-full">
                                <td className="w-1/2">
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="id" className="text-xs font-[400]">
                                            Street
                                        </label>
                                        <textarea
                                            className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                            id="id"
                                            placeholder={"ENTER"}
                                            value={streetNameShipping}
                                            onChange={handleStreetChange}
                                        />
                                    </div>
                                </td>
                                <td className="w-1/2">
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="id" className="text-xs font-400 ">
                                            Country
                                        </label>

                                        <Select
                                            options={allTerDataTemp.map((data) => ({
                                                value: data.id,
                                                label: data.countryName,
                                            }))}
                                            onChange={handleCountryChangeTemp}
                                            className="text-black text-xs select-bar uppercase"
                                            id="id"
                                            isSearchable
                                            isClearable
                                            value={allTerDataTemp.find(
                                                (option) => option.id === selectedCountryTemp
                                            )
                                                ? {
                                                    value: selectedCountryTemp,
                                                    label: allTerDataTemp.find(
                                                        (option) => option.id === selectedCountryTemp
                                                    )?.countryName,
                                                }
                                                : null} // Pre-select based on selectedCountryTemp
                                            closeMenuOnSelect={true}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: "#e5e7eb",
                                                },
                                            })}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={back}>
                        <CustomButton2 label={"Back"} variant="outlined" className="txt-prp-color" />
                    </div>
                    <div onClick={handleCustomerDataSubmit}>
                        <CustomButton1 label={"Submit"} className="text-white bg-prp-color" type="submit" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    );
}
