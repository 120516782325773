// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";

// COMPONENTS
import Title from "../components/general-components/Title.component";
import CustomButton1 from "../components/general-components/CustomButton1.component";
import PopUp from "../components/general-components/PopUp.componenet";
import CustomSelect3 from "../components/general-components/CustomSelect3.component";
import CustomButton4 from "../components/general-components/CustomButton4.component";
import Pagination from "../components/general-components/Pagination.component";

// ICONSbg-white
import { IoMdAdd, IoMdCloseCircle, IoMdRefresh } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import { IoSearchSharp } from "react-icons/io5";

import ManageCustomersAdd from "../components/forms-components/ManageCustomers/ManageCustomersAdd";
import ManageCustomersEdit from "../components/forms-components/ManageCustomers/ManageCustomersEdit";
import ManageCustomersView from "../components/forms-components/ManageCustomers/ManageCustomersView";

import { useAuth } from "../context";
import { getSinglePermission } from "../../Helpers/Constants";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ManageCustomers() {
    const { user } = useAuth()
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "MANAGE CUSTOMER"))
        }
    }, [user])
    const navigate = useNavigate();

    let [popupAdd, setPopupAdd] = useState(false);
    let [popupEdit, setPopupEdit] = useState(false);
    let [popupView, setPopupView] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [custType, setCustType] = useState([]);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const dataActive = { searchText: "", isActive: true };

    const [cName, setCName] = useState("");
    const [mobile, setMobile] = useState("");
    const [parentCId, setParentCId] = useState("");
    const [parentCName, setParentCName] = useState("");
    const [landLineNumber, setLandLineNumber] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [mainCountryData, setMainCountryData] = useState([]);
    const [mainCountryId, setMainCountryId] = useState("");
    const [mainCountryName, setMainCountryName] = useState("");
    const [contactPersonCon, setContactPersonCon] = useState("");
    const [emailIdCon, setEmailIdCon] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [currentCustomerId, setCurrentCustomerId] = useState(null);
    const [searchText, setSearchText] = useState("");
    // const [customerId, setCustomerId] = useState("");
    const [poIdData, setPoIdData] = useState([]);
    const [countryId, setCountryId] = useState("");

    const fetchCustomerTypeApiData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Territory/GetCountryList`, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data;
                setCustType(sortedData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Customer Type Data");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomerTypeApiData();
    }, [])

    const dataList = { searchText: "", countryId: 0, customerId: 0, leadStatusId: 2, parentCustomerId: 0 };

    const fetchListData = async (updatedDataCustList = dataList) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Customer/GetCustomerList`,
                updatedDataCustList,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data.sort((a, b) => {
                    if (a.isActive === b.isActive) {
                        return new Date(b.createdDate) - new Date(a.createdDate);
                    }
                    return a.isActive === true ? -1 : 1;
                });
                setCustomerData(sortedData);
                setTotalPages(Math.ceil(sortedData.length / rowsPerPage));
                setCurrentPage(1);

                // Sort data in ascending order by id
                const sortedById = response.data.data.slice().sort((a, b) => a.id - b.id);
                setPoIdData(sortedById);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Customer list");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = () => {
        // Filter the customer data based on selected criteria
        const updatedDataCustList = {
            ...dataList,
            customerId: customerId || 0,
            countryId: countryId || 0,
        };

        fetchListData(updatedDataCustList);
    };

    const handleReset = () => {
        // Reset all dropdowns and fetch the original data
        setSelectedCountry("");
        setSelectedCustomer("");
        setCountryId("");
        setCustomerId(0);
        fetchListData(dataList);
    };

    const handleEditButtonClick = (genId) => {
        fetchCustomerDataById(genId);
        setPopupEdit(true);
    };

    const handleViewButtonClick = (genId) => {
        fetchCustomerDataById(genId);
        setPopupView(true);
    };

    const fetchCustomerDataById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Customer/GetCustomerById?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && response.data.data) {
                const data = response.data.data;
                setCurrentCustomerId(data.id)
                setSelectedCustomer(response.data.data);
                setCName(data.customerName);
                setMobile(data.mobileNo);
                setParentCId(data.parentCustomerId);
                setParentCName(data.parentCustomer);
                setLandLineNumber(data.landlineNumber);
                setCustomerId(data.customerTypeId);
                setCustomerName(data.customerType);
                setMainCountryId(data.countryId);
                setMainCountryName(data.countryName);
                setContactPersonCon(data.contactPerson);
                setEmailIdCon(data.emailId);

            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Manage Gender details");
        } finally {
            setIsLoading(false);
        }
    };

    const filteredData = customerData.filter(item =>
        (item.countryName && String(item.countryName).toLowerCase().includes(searchText.toLowerCase())) ||
        (item.customerName && String(item.customerName).toLowerCase().includes(searchText.toLowerCase())) ||
        (item.parentCustomer && String(item.parentCustomer).toLowerCase().includes(searchText.toLowerCase())) ||
        (item.contactName && String(item.contactName).toLowerCase().includes(searchText.toLowerCase())) ||
        (item.mobileNo && String(item.mobileNo).toLowerCase().includes(searchText.toLowerCase()))
    );

    const paginatedCustData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(() => {
        fetchListData(dataList);
    }, [rowsPerPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(customerData.length / rowsPerPage));
    }, [customerData, rowsPerPage]);

    const handleParentCustomerPage = (parentCustomer, pId) => {
        navigate(`/admin/app/customers/parent-customer?parentCustomer=${parentCustomer}&pId=${pId}`);
    };

    const handleTracking = (cId) => {
        navigate(`/admin/app/customers/customer-tracking-status?customerId=${cId}`);
    };

    const clearSearch = () => {
        setSearchText(""); // Clear the search input
    };

    const handleCountry = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountry(selectedOption.value);
        } else {
            setSelectedCountry(null); // Reset to null when cleared
        }
    }

    const handleCustomer = (selectedOption) => {
        if (selectedOption) {
            setSelectedCustomer(selectedOption.value);
        } else {
            setSelectedCustomer(null); // Reset to null when cleared
        }
    }

    const createChangeHandler = (setter) => (selectedOption) => {
        if (selectedOption) {
            setter(selectedOption.value);
        } else {
            setter(null);
        }
    };

    const handleCountryChange = createChangeHandler(setCountryId);
    const handleCustomerChangeFilter = createChangeHandler(setCustomerId);

    const excelDownload = async () => {
        const token = localStorage.getItem('DIYAN_IE_authToken');
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Customer/ExportCustomer`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess) {
                const base64Data = response.data.data;
                const binaryString = atob(base64Data);
                const binaryLen = binaryString.length;
                const bytes = new Uint8Array(binaryLen);

                for (let i = 0; i < binaryLen; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }

                const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'customers.xlsx'); // Set the file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                toast.success(response.data.message);
            } else {
                toast.error("Failed to export territories data.");
            }
        } catch (error) {
            toast.error("Error fetching Territories Data details");
        } finally {
            setIsLoading(false);
        }
    };

    const uniqueBy = (arr, key) => {
        return Array.from(new Map(arr.map(item => [item[key], item])).values());
    };

    const getCountries = () => {
        // Filter out records with empty countryId or countryName
        const filteredData = poIdData.filter(data => data.countryId && data.countryName);

        // Apply uniqueBy function and map the data
        return uniqueBy(filteredData, 'countryId').map(data => ({
            value: data.countryId,
            label: data.countryName
        }));
    };

    // const getCustomers = () => {
    //     const filteredByCountry = poIdData.filter(item => item.countryId === countryId);
    //     return uniqueBy(filteredByCountry, 'id').map(data => ({
    //         value: data.id,
    //         label: data.customerName
    //     }));
    // };

    const getCustomers = () => {
        // If no country is selected, show the entire customer list
        const filteredByCountry = countryId
            ? poIdData.filter(item => item.countryId === countryId)
            : poIdData;
    
        // Return a unique list of customers
        return uniqueBy(filteredByCountry, 'id').map(data => ({
            value: data.id,
            label: data.customerName
        }));
    };
    

    return (
        <>
            <div className="flex flex-col h-full p-2 uppercase">
                <div className="p-4 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                    <Title title1={"Manage Customers"} title2={"Manage Customers"} />

                    <div className="  flex md:justify-between items-center flex-row flex-wrap justify-start gap-1 mt-5 md:flex-nowrap p-2">
                        <Button
                            variant={"contained"}
                            size={"small"}
                            className={"flex gap-2 justify-center items-center relative uppercase"}
                            type={"button"}
                            style={{ backgroundColor: "#643c94" }}
                            onClick={excelDownload}
                        >
                            Excel
                        </Button>

                        <div className="flex flex-wrap items-center justify-between gap-1 text-xs ml-auto">
                        </div>
                    </div>

                    <div className=" bg-white rounded grow">
                        <div className="px-2 lg:px-4">
                            {/* 4 BUTTONS */}
                            <div>
                                <div className="flex flex-row flex-wrap justify-start gap-2 mt-5 md:flex-nowrap minbox">
                                    {Permission?.add && <CustomButton1
                                        label={"Add"}
                                        icon={<IoMdAdd />}
                                        className="custmbtn text-fourth shrink grow md:grow-0 max-w-[50%]"
                                        onClick={() => setPopupAdd(true)}
                                    />}

                                    <div className="flex items-center justify-between gap-1 px-2  ml-auto text-[rgba(0, 0, 0, 0.50)] boreder rounded bg-white shrink grow md:grow-0">
                                        <input
                                            type="text"
                                            className="w-[250px] py-1  grow text-sm uppercase bg-transparent placeholder:text-sm"
                                            placeholder="SEARCH"
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                        {searchText ? (
                                            <IoMdCloseCircle
                                                className="min-w-[20px] cursor-pointer txt-prp-color"
                                                onClick={clearSearch}
                                            />
                                        ) : (
                                            <AiOutlineSearch className="min-w-[20px]" />
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center gap-2 justify-between  px-2  ml-auto text-[rgba(0, 0, 0, 0.50)]  minbox">
                                    <div className="w-[40%] ">
                                        {/* <CustomSelect3 placeholder="Country"
                                            options={custType.map(cData => ({
                                                value: cData.id,
                                                label: cData.countryName
                                            }))}
                                            value={selectedCountry}
                                            onChange={handleCountry}
                                        /> */}
                                        <CustomSelect3
                                            placeholder="Country"
                                            options={getCountries()}
                                            onChange={handleCountryChange}
                                            value={countryId}
                                        />
                                    </div>
                                    <div className="w-[40%] ">
                                        {/* <CustomSelect3 placeholder="Customer"
                                            options={customerData.map(depData => ({
                                                value: depData.id,
                                                label: depData.customerName
                                            }))}
                                            value={selectedCustomer}
                                            onChange={handleCustomer}
                                        /> */}
                                        <CustomSelect3
                                            placeholder="Customer Name"
                                            options={getCustomers()}
                                            onChange={handleCustomerChangeFilter}
                                            value={customerId}
                                        />
                                    </div>
                                    <div class="flex justify-center gap-2 w-[20%]">
                                        <div onClick={handleSearch}>
                                            <Tooltip title="SEARCH" arrow placement="bottom">
                                                <span>
                                                    <CustomButton1 icon={<IoSearchSharp />} className="text-white bg-prp-color py-2 px-8" />
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div onClick={handleReset}>
                                            <Tooltip title="RESET" arrow placement="bottom">
                                                <span>
                                                    <CustomButton1 icon={<IoMdRefresh />} className="bg-white py-2 px-8" />
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {Permission?.view &&
                                <>
                                    {/* TABLE */}
                                    <div className="mt-7 max-w-[100vw] hide-scrollbar overflow-auto table-container">
                                        <table className="w-full custom-table" border={1}>
                                            <thead>
                                                <tr className="table-heading">
                                                    <td className="">S.No</td>
                                                    {(Permission?.edit || Permission?.view) && <td>Action</td>}
                                                    <td>Status</td>
                                                    <td>Country</td>
                                                    <td>Customer Name</td>
                                                    <td>Parent Customer</td>
                                                    <td>Contact Name </td>
                                                    <td>Contact Mobile# </td>
                                                    <td>Created Date </td>
                                                    <td>Created By </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? (
                                                    <tr>
                                                        <td colSpan="10" className="text-center">Loading...</td>
                                                    </tr>
                                                ) : (
                                                    paginatedCustData.length === 0 ? (
                                                        <tr>
                                                            <td
                                                                className="py-3 text-center"
                                                                colSpan={10}
                                                            >
                                                                No Data Available
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        paginatedCustData.map((custData, index) => (
                                                            <tr key={custData.id}>
                                                                <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                                                {(Permission?.edit || Permission?.view) && <td>
                                                                    <div className="gap-2">
                                                                        {Permission?.edit &&
                                                                            <Tooltip title="EDIT" arrow placement="left">
                                                                                <span>
                                                                                    <CustomButton1
                                                                                        className="bg-sixt text-white grow max-w-[50px]"
                                                                                        icon={<BiEdit />}
                                                                                        onClick={() => handleEditButtonClick(custData.id)}
                                                                                    />
                                                                                </span>
                                                                            </Tooltip>
                                                                        }
                                                                        {Permission?.view &&
                                                                            <Tooltip title="VIEW" arrow placement="right">
                                                                                <span>
                                                                                    <CustomButton4
                                                                                        className="text-white grow max-w-[50px]"
                                                                                        icon={<BsEyeFill />}
                                                                                        onClick={() => handleViewButtonClick(custData.id)}
                                                                                    />
                                                                                </span>
                                                                            </Tooltip>
                                                                        }
                                                                    </div>
                                                                </td>}
                                                                <td>
                                                                    <div>
                                                                        <h2 style={{ color: custData.isActive ? 'green' : 'red' }}>{custData.isActive ? 'Active' : 'Inactive'}</h2>
                                                                    </div>
                                                                </td>
                                                                <td>{custData.countryName}</td>
                                                                {/* <td>{custData.customerName}</td> */}
                                                                <td>
                                                                    <div
                                                                        onClick={() => {
                                                                            if (custData.customerName) {
                                                                                handleTracking(custData.id);
                                                                            }
                                                                        }}
                                                                        className={`w-full min-h-full underline txt-prp-color ${custData.customerName ? 'cursor-pointer' : ''}`}
                                                                        style={{ cursor: custData.customerName ? 'pointer' : 'default' }}
                                                                    >
                                                                        {custData.customerName}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div
                                                                        onClick={() => {
                                                                            if (custData.parentCustomer) {
                                                                                handleParentCustomerPage(custData.parentCustomer, custData.parentCustomerId);
                                                                            }
                                                                        }}
                                                                        className={`w-full min-h-full underline txt-prp-color ${custData.parentCustomer ? 'cursor-pointer' : ''}`}
                                                                        style={{ cursor: custData.parentCustomer ? 'pointer' : 'default' }}
                                                                    >
                                                                        {custData.parentCustomer}
                                                                    </div>
                                                                </td>
                                                                <td>{custData.contactName}</td>
                                                                <td>{custData.mobileNo}</td>
                                                                <td>{new Date(custData.createdDate).toLocaleDateString('en-GB')}</td>
                                                                <td>{custData.creatorName}</td>
                                                            </tr>
                                                        ))
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <Pagination
                                        currentPage={currentPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        totalPages={totalPages}
                                        setCurrentPage={setCurrentPage}
                                        data={customerData}
                                    />
                                </>
                            }
                        </div>

                        {/* POPUP 1 : ADD */}
                        {popupAdd && (
                            <PopUp>
                                <ManageCustomersAdd
                                    onCancel={() => {
                                        fetchListData();
                                        setPopupAdd(false);
                                    }}
                                />
                            </PopUp>
                        )}

                        {/* POPUP 2 : EDIT */}
                        {popupEdit && (
                            <PopUp>
                                <ManageCustomersEdit
                                    currentCustomerId={currentCustomerId}
                                    cName={cName}
                                    setCName={setCName}
                                    mobile={mobile}
                                    setMobile={setMobile}
                                    parentCId={parentCId}
                                    setParentCId={setParentCId}
                                    landLineNumber={landLineNumber}
                                    setLandLineNumber={setLandLineNumber}
                                    customerId={customerId}
                                    setCustomerId={setCustomerId}

                                    parentCName={parentCName}
                                    setParentCName={setParentCName}
                                    customerName={customerName}
                                    setCustomerName={setCustomerName}

                                    mainCountryData={mainCountryData}
                                    setMainCountryData={setMainCountryData}
                                    mainCountryId={mainCountryId}
                                    setMainCountryId={setMainCountryId}
                                    mainCountryName={mainCountryName}
                                    setMainCountryName={setMainCountryName}

                                    contactPersonCon={contactPersonCon}
                                    emailIdCon={emailIdCon}

                                    selectedCustomer={selectedCustomer}
                                    onCancel={() => {
                                        fetchListData();
                                        setPopupEdit(false);
                                    }}
                                />
                            </PopUp>
                        )}

                        {/* POPUP 3 : VIEW */}
                        {popupView && (
                            <PopUp>
                                <ManageCustomersView
                                    selectedCustomer={selectedCustomer}
                                    onCancel={() => setPopupView(false)}
                                />
                            </PopUp>
                        )}
                    </div>
                </div>
            </div >

            <ToastContainer autoClose={2000} />
        </>
    );
}
