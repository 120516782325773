import * as React from "react";
import Cookies from "js-cookie"; // Import js-cookie for handling cookies
import Select from "react-select";

import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import { BsCheck } from "react-icons/bs";
import { useState, useEffect } from "react";

export default function BillingDetails({
    allTerData,
    setSelectedCountry,
    streetNameBilling,
    setStreetNameBilling,
    checkboxSameAddress,
    setCheckboxSameAddress,

    next,
    back,
    onCancel,
}) {
    const [selectedBillingCountry, setSelectedBillingCountry] = useState(null);

    useEffect(() => {
        // Load billing details from cookies
        const savedData = Cookies.get("billingDetailsParent");
        if (savedData) {
            const parsedData = JSON.parse(savedData);

            // Set country and street from saved data
            const matchedCountry = allTerData.find((data) => data.id === parsedData.countryId);
            if (matchedCountry) {
                setSelectedBillingCountry({
                    value: matchedCountry.id,
                    label: matchedCountry.countryName,
                });
                setSelectedCountry(matchedCountry.id);
            }
            setStreetNameBilling(parsedData.streetName || "");

            // Automatically set the checkbox state
            setCheckboxSameAddress(parsedData.isSameAsShipping || false);
        }
    }, [allTerData, setSelectedCountry, setStreetNameBilling, setCheckboxSameAddress]);

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedBillingCountry(selectedOption);
            setSelectedCountry(selectedOption.value);

            // Save updated billing country to cookies
            Cookies.set(
                "billingDetailsParent",
                JSON.stringify({
                    countryId: selectedOption.value,
                    streetName: streetNameBilling,
                    isSameAsShipping: checkboxSameAddress,
                })
            );
        } else {
            setSelectedBillingCountry(null);
            setSelectedCountry(null);
            Cookies.remove("billingDetailsParent");
        }
    };

    const handleStreetChange = (event) => {
        const newStreetName = event.target.value;
        setStreetNameBilling(newStreetName);

        // Update cookies when street name changes
        Cookies.set(
            "billingDetailsParent",
            JSON.stringify({
                countryId: selectedBillingCountry ? selectedBillingCountry.value : null,
                streetName: newStreetName,
                isSameAsShipping: checkboxSameAddress,
            })
        );
    };

    return (
        <>
            <div>
                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[900px]">
                        <tbody>
                            <tr className="w-full">
                                <td className="w-1/2">
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="id" className="text-xs font-[400]">
                                            Street
                                        </label>
                                        <textarea
                                            className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                            id="id"
                                            placeholder={"ENTER"}
                                            value={streetNameBilling}
                                            onChange={handleStreetChange}
                                        />
                                    </div>
                                </td>
                                <td className="w-1/2">
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="id" className="text-xs font-400">
                                            Country
                                        </label>

                                        <Select
                                            options={allTerData.map((data) => ({
                                                value: data.id,
                                                label: data.countryName,
                                            }))}
                                            onChange={handleCountryChange}
                                            value={selectedBillingCountry}
                                            className="text-black text-xs select-bar uppercase"
                                            id="id"
                                            isSearchable
                                            isClearable
                                            menuPosition={"fixed"}
                                            closeMenuOnSelect={true}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: "#e5e7eb",
                                                },
                                            })}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="flex items-center gap-4 mt-3 ps-5">
                    <label htmlFor="id" className="text-sm font-400">
                        Is Shipping and Billing address the same?
                    </label>
                    <div
                        className={`${
                            checkboxSameAddress ? "bg-prp-color" : "bg-white"
                        } border border-gray-300 flex justify-center items-center rounded h-[25px] w-[25px]`}
                        onClick={() => {
                            const newCheckboxState = !checkboxSameAddress;
                            setCheckboxSameAddress(newCheckboxState);

                            // Save checkbox state and billing details into cookies
                            Cookies.set("billingDetailsParent", JSON.stringify({
                                countryId: selectedBillingCountry ? selectedBillingCountry.value : null,
                                streetName: streetNameBilling || "",
                                isSameAsShipping: newCheckboxState,
                            }));

                            if (newCheckboxState) {
                                // Copy billing details to shipping when checkbox is checked
                                Cookies.set("shippingDetails", JSON.stringify({
                                    countryId: selectedBillingCountry ? selectedBillingCountry.value : null,
                                    streetName: streetNameBilling || "",
                                }));
                            }
                        }}
                    >
                        <BsCheck className="text-2xl text-white" />
                    </div>
                </div>

                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={back}>
                        <CustomButton2 label={"Back"} variant="outlined" className="txt-prp-color" />
                    </div>
                    <div onClick={next}>
                        <CustomButton1 label={"Save and Continue"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    );
}

